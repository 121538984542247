/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  CompteBancaireDto,
  EmailDto,
  EmailReplaceDto,
  FilterCriteriaInfo,
  PersonneEditDto,
  PersonneGridDtoPaginatedResults,
  PersonneHistoryGridDtoPaginatedResults,
  PersonneHistorySearch,
  PersonneSearchDto,
  ReferentialItemDto,
  TelephoneDto,
  TelephoneReplaceDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    CompteBancaireDtoFromJSON,
    CompteBancaireDtoToJSON,
    EmailDtoFromJSON,
    EmailDtoToJSON,
    EmailReplaceDtoFromJSON,
    EmailReplaceDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    PersonneEditDtoFromJSON,
    PersonneEditDtoToJSON,
    PersonneGridDtoPaginatedResultsFromJSON,
    PersonneGridDtoPaginatedResultsToJSON,
    PersonneHistoryGridDtoPaginatedResultsFromJSON,
    PersonneHistoryGridDtoPaginatedResultsToJSON,
    PersonneHistorySearchFromJSON,
    PersonneHistorySearchToJSON,
    PersonneSearchDtoFromJSON,
    PersonneSearchDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    TelephoneDtoFromJSON,
    TelephoneDtoToJSON,
    TelephoneReplaceDtoFromJSON,
    TelephoneReplaceDtoToJSON,
} from '../models/index';

export interface PersonneBaseSearchRequest {
    PersonneSearchDto?: PersonneSearchDto;
}

export interface PersonneDeleteCompteBancaireFromPersonneRequest {
    idcompteBancaire?: number;
}

export interface PersonneDeleteEmailRequest {
    idemail?: number;
}

export interface PersonneDeleteEmailFromPersonneRequest {
    idemail?: number;
}

export interface PersonneDeleteTelRequest {
    idtelephone?: number;
}

export interface PersonneDeleteTelFromPersonneRequest {
    idtelephone?: number;
}

export interface PersonneGetCompteBancairesRequest {
    idpersonne?: number;
}

export interface PersonneGetDisplayNameRequest {
    id?: number;
}

export interface PersonneGetEmailsRequest {
    idpersonne?: number;
    request_body?: Array<string>;
}

export interface PersonneGetPersonneRequest {
    id?: number;
}

export interface PersonneGetPhotoRequest {
    id?: number;
}

export interface PersonneGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface PersonneGetSelectItemsRequest {
    searchField: string;
}

export interface PersonneGetTelsRequest {
    idpersonne?: number;
    request_body?: Array<string>;
}

export interface PersonneIsEmailUsedRequest {
    idemail?: number;
}

export interface PersonneIsPhoneUsedRequest {
    idtelephone?: number;
}

export interface PersonneReplaceEmailRequest {
    EmailReplaceDto?: EmailReplaceDto;
}

export interface PersonneReplacePhoneRequest {
    TelephoneReplaceDto?: TelephoneReplaceDto;
}

export interface PersonneSaveCompteBancaireRequest {
    CompteBancaireDto?: CompteBancaireDto;
}

export interface PersonneSaveEmailRequest {
    EmailDto?: EmailDto;
}

export interface PersonneSavePersonneRequest {
    PersonneEditDto?: PersonneEditDto;
}

export interface PersonneSaveTelephoneRequest {
    TelephoneDto?: TelephoneDto;
}

export interface PersonneSearchHistoryRequest {
    PersonneHistorySearch?: PersonneHistorySearch;
}

/**
 * 
 */
export class PersonneApi extends runtime.BaseAPI {

    /**
     */
    async personneBaseSearchRaw(requestParameters: PersonneBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonneGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonneSearchDtoToJSON(requestParameters.PersonneSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonneGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async personneBaseSearch(requestParameters: PersonneBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonneGridDtoPaginatedResults> {
        const response = await this.personneBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneDeleteCompteBancaireFromPersonneRaw(requestParameters: PersonneDeleteCompteBancaireFromPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idcompteBancaire !== undefined) {
            queryParameters['idcompteBancaire'] = requestParameters.idcompteBancaire;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/DeleteCompteBancaireFromPersonne`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async personneDeleteCompteBancaireFromPersonne(requestParameters: PersonneDeleteCompteBancaireFromPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.personneDeleteCompteBancaireFromPersonneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async personneDeleteEmailRaw(requestParameters: PersonneDeleteEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idemail !== undefined) {
            queryParameters['idemail'] = requestParameters.idemail;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/DeleteEmail`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async personneDeleteEmail(requestParameters: PersonneDeleteEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.personneDeleteEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async personneDeleteEmailFromPersonneRaw(requestParameters: PersonneDeleteEmailFromPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idemail !== undefined) {
            queryParameters['idemail'] = requestParameters.idemail;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/DeleteEmailFromPersonne`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async personneDeleteEmailFromPersonne(requestParameters: PersonneDeleteEmailFromPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.personneDeleteEmailFromPersonneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async personneDeleteTelRaw(requestParameters: PersonneDeleteTelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idtelephone !== undefined) {
            queryParameters['idtelephone'] = requestParameters.idtelephone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/DeleteTel`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async personneDeleteTel(requestParameters: PersonneDeleteTelRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.personneDeleteTelRaw(requestParameters, initOverrides);
    }

    /**
     */
    async personneDeleteTelFromPersonneRaw(requestParameters: PersonneDeleteTelFromPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idtelephone !== undefined) {
            queryParameters['idtelephone'] = requestParameters.idtelephone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/DeleteTelFromPersonne`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async personneDeleteTelFromPersonne(requestParameters: PersonneDeleteTelFromPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.personneDeleteTelFromPersonneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async personneGetCompteBancairesRaw(requestParameters: PersonneGetCompteBancairesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompteBancaireDto>>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne !== undefined) {
            queryParameters['idpersonne'] = requestParameters.idpersonne;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/CompteBancaires`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompteBancaireDtoFromJSON));
    }

    /**
     */
    async personneGetCompteBancaires(requestParameters: PersonneGetCompteBancairesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompteBancaireDto>> {
        const response = await this.personneGetCompteBancairesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneGetDisplayNameRaw(requestParameters: PersonneGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async personneGetDisplayName(requestParameters: PersonneGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.personneGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneGetEmailsRaw(requestParameters: PersonneGetEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmailDto>>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne !== undefined) {
            queryParameters['idpersonne'] = requestParameters.idpersonne;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/Emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailDtoFromJSON));
    }

    /**
     */
    async personneGetEmails(requestParameters: PersonneGetEmailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmailDto>> {
        const response = await this.personneGetEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneGetPersonneRaw(requestParameters: PersonneGetPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonneEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonneEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async personneGetPersonne(requestParameters: PersonneGetPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonneEditDto> {
        const response = await this.personneGetPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneGetPhotoRaw(requestParameters: PersonneGetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/photo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async personneGetPhoto(requestParameters: PersonneGetPhotoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.personneGetPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneGetSearchCriteriasRaw(requestParameters: PersonneGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async personneGetSearchCriterias(requestParameters: PersonneGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.personneGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneGetSelectItemsRaw(requestParameters: PersonneGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling personneGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async personneGetSelectItems(requestParameters: PersonneGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.personneGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneGetTelsRaw(requestParameters: PersonneGetTelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TelephoneDto>>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne !== undefined) {
            queryParameters['idpersonne'] = requestParameters.idpersonne;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/Tels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TelephoneDtoFromJSON));
    }

    /**
     */
    async personneGetTels(requestParameters: PersonneGetTelsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TelephoneDto>> {
        const response = await this.personneGetTelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneIsEmailUsedRaw(requestParameters: PersonneIsEmailUsedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idemail !== undefined) {
            queryParameters['idemail'] = requestParameters.idemail;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/IsEmailUsed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async personneIsEmailUsed(requestParameters: PersonneIsEmailUsedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.personneIsEmailUsedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneIsPhoneUsedRaw(requestParameters: PersonneIsPhoneUsedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idtelephone !== undefined) {
            queryParameters['idtelephone'] = requestParameters.idtelephone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/IsPhoneUsed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async personneIsPhoneUsed(requestParameters: PersonneIsPhoneUsedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.personneIsPhoneUsedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneReplaceEmailRaw(requestParameters: PersonneReplaceEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailReplaceDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/ReplaceEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailReplaceDtoToJSON(requestParameters.EmailReplaceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailReplaceDtoFromJSON(jsonValue));
    }

    /**
     */
    async personneReplaceEmail(requestParameters: PersonneReplaceEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailReplaceDto> {
        const response = await this.personneReplaceEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneReplacePhoneRaw(requestParameters: PersonneReplacePhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TelephoneReplaceDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/ReplacePhone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelephoneReplaceDtoToJSON(requestParameters.TelephoneReplaceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TelephoneReplaceDtoFromJSON(jsonValue));
    }

    /**
     */
    async personneReplacePhone(requestParameters: PersonneReplacePhoneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TelephoneReplaceDto> {
        const response = await this.personneReplacePhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneSaveCompteBancaireRaw(requestParameters: PersonneSaveCompteBancaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompteBancaireDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/SaveCompteBancaire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompteBancaireDtoToJSON(requestParameters.CompteBancaireDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompteBancaireDtoFromJSON(jsonValue));
    }

    /**
     */
    async personneSaveCompteBancaire(requestParameters: PersonneSaveCompteBancaireRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompteBancaireDto> {
        const response = await this.personneSaveCompteBancaireRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneSaveEmailRaw(requestParameters: PersonneSaveEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/SaveEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailDtoToJSON(requestParameters.EmailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async personneSaveEmail(requestParameters: PersonneSaveEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDto> {
        const response = await this.personneSaveEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneSavePersonneRaw(requestParameters: PersonneSavePersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonneEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonneEditDtoToJSON(requestParameters.PersonneEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonneEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async personneSavePersonne(requestParameters: PersonneSavePersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonneEditDto> {
        const response = await this.personneSavePersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneSaveTelephoneRaw(requestParameters: PersonneSaveTelephoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TelephoneDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/SaveTel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelephoneDtoToJSON(requestParameters.TelephoneDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TelephoneDtoFromJSON(jsonValue));
    }

    /**
     */
    async personneSaveTelephone(requestParameters: PersonneSaveTelephoneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TelephoneDto> {
        const response = await this.personneSaveTelephoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personneSearchHistoryRaw(requestParameters: PersonneSearchHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonneHistoryGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Personne/SearchHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonneHistorySearchToJSON(requestParameters.PersonneHistorySearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonneHistoryGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async personneSearchHistory(requestParameters: PersonneSearchHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonneHistoryGridDtoPaginatedResults> {
        const response = await this.personneSearchHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
