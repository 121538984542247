import { FGTextInput, IFGSelectInputProps } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";

import { FGEditableSelectInput, FGWalterSelectInput } from ".";
import { EmailDto, EmailDtoFromJSON, PersonneApi } from "../../api";
import { useApiService, useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { nameof } from "../../utils";
import * as Yup from "yup";
import { ETLCodes } from "../../locales";

export interface IFGEditableEmailSelectInputProps
  extends Omit<IFGSelectInputProps<EmailDto>, "valueField" | "displayField" | "items" | "loading"> {
  autoSelectIfOne?: boolean;
  idpersonne: number;
  types?: string[];
}

export const FGEditableEmailSelectInput: React.FunctionComponent<IFGEditableEmailSelectInputProps> = ({
  idpersonne,
  types = ["PERSO", "PRO"],
  ...props
}) => {
  const api = useApiService(PersonneApi);
  const { t } = useTl();

  const fetchEmails = React.useCallback(async () => await api.personneGetEmails({ idpersonne, request_body: types }), [
    api,
    idpersonne,
    types
  ]);
  const { data: items, isFetching, refetch } = useQuery(["emailsdto-personne", idpersonne, types], fetchEmails);

  const [typeTels, ttLoading] = useReferential(a => a.referentialGetTypesEmailByCodes({ request_body: types }));

  const onSave = React.useCallback(
    async (data: EmailDto) => {
      return await api.personneSaveEmail({ EmailDto: data });
    },
    [api]
  );

  const onDelete = React.useCallback(
    async (data: EmailDto) => {
      await api.personneDeleteEmailFromPersonne({ idemail: data.idemail ?? 0 });
    },
    [api]
  );

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      idtypeEmail: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      adresseEmail: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <FGEditableSelectInput
      displayField={nameof<EmailDto>("adresseEmail")}
      valueField={nameof<EmailDto>("idemail")}
      baseData={EmailDtoFromJSON({ idpersonne })}
      validationSchema={validationSchema}
      refresh={async () => {
        await refetch();
      }}
      editFields={
        <>
          <FGWalterSelectInput
            name={nameof<EmailDto>("idtypeEmail")}
            noLabel
            items={typeTels}
            loading={ttLoading}
            requiredMark
          />
          <FGTextInput name={nameof<EmailDto>("adresseEmail")} noLabel maxLength={100} requiredMark />
        </>
      }
      onSave={onSave}
      items={items}
      loading={isFetching}
      {...props}
    />
  );
};

export default FGEditableEmailSelectInput;
