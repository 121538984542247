import { FormikProps } from "formik";
import { FGCustomPanel, FGMultiSuggestInput, FGNumberInput, FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import {
  ContratApi,
  ContratAvenantEditDto,
  ContratAvenantEditDtoFromJSON,
  ELevelName,
  ETypeContrat,
  FichierApi
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IContratAvenantsItemProps {}

export const ContratAvenantsItem: React.FunctionComponent<IContratAvenantsItemProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ContratApi);
  const [typesAvenant, taLoading, , rawTypesAvenant] = useReferential(a => a.referentialGetTypesAvenant(), true);
  const formikRef = React.useRef<FormikProps<ContratAvenantEditDto>>();
  const { id, state, type, subId: idavenant } = useParams<{ id: string; state: string; type: string; subId: string }>();
  const [codesPostaux, cpLoading] = useReferential(a => a.referentialGetCodePostauxByPays({ codePays: "BE" }));

  const fetchTypeContrat = React.useCallback(() => {
    return api.contratGetType({ idcontrat: +id });
  }, [api, id]);
  const { data: typeContrat } = useQuery(["type-contrat", id], fetchTypeContrat);

  const [users, usersLoading] = useReferential(a =>
    a.referentialGetUsersByRoles({ ELevelName: [ELevelName.RI, ELevelName.CALC], currentIduser: 0 })
  );

  const saveFn = React.useCallback(
    async (d: ContratAvenantEditDto) => {
      return api.contratSaveAvenants({ ContratAvenantEditDto: d });
    },
    [api]
  );

  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          const lfCp = await api.contratGetMainLieuFormationCP({ idcontrat: +id });
          return +idavenant <= 0
            ? ContratAvenantEditDtoFromJSON({
                idavenant: 0,
                idcontrat: +id,
                idcpSignature: lfCp
              })
            : api.contratGetContratAvenant({ idavenant: +idavenant });
        },
        saveApiFn: saveFn,
        deleteApiFn: (d: ContratAvenantEditDto) => api.contratDeleteAvenant({ idavenant: d.idavenant }),
        onDeletedRoute: () => `${ERoutes.contrat}/${+id}/avenants/${type}/${state}`,
        onSavedRoute: d => `${ERoutes.contrat}/${+id}/avenants/${type}/${state}`
      }),
      [api, id, idavenant, saveFn, state, type]
    )
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idcontrat: Yup.number().required(t(ETLCodes.Required)),
      idstypeAvenant: Yup.array()
        .of(Yup.number())
        .required(t(ETLCodes.Required)),
      datePriseEnCours: Yup.date(),
      dateSignature: Yup.date(),
      dateCommissionTutelle: Yup.date(),
      idresponsableValidation: Yup.date()
    });
  }, [t]);

  const isCommTut = React.useCallback(
    (idstype: number[]) => {
      const commTutId = (rawTypesAvenant ?? []).find(ta => ta.keyValue === "COMM_TUT")?.idValue;
      return (idstype ?? []).includes(+commTutId);
    },
    [rawTypesAvenant]
  );

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async idfichier => await fapi.fichierDownload({ id: idfichier }), [fapi]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.contrat}/${+id}/avenants/${type}/${state}`)}
      saving={saving}
      validationErrors={validationErrors}
      onDelete={deleteItem}
      deleting={deleting}
      customDeleteButtonProps={
        +idavenant > 0 && !data?.deletionDate ? { loading: deleting, onDelete: deleteItem } : null
      }
      showDeleteButton={false}
      formikInnerRef={i => (formikRef.current = i)}
      forceEnableSave
      minLabelWidth={190}
      watchChanges={{
        fichierR: (value, formik) => {
          if (formik.dirty) {
            formik.setFieldValue("idfichier", null);
          }
        }
      }}
    >
      <FieldGroup columns={2}>
        <FieldGroup fieldsetProps={{ title: t(ETLCodes.GeneralInformation) }}>
          <FGMultiSuggestInput
            name="idstypeAvenant"
            label={t(ETLCodes.TypesAvenant)}
            items={typesAvenant}
            loading={taLoading}
            readonly={+idavenant > 0}
          />
          <FGNumberInput name="numero" label={t(ETLCodes.NumeroAvenant)} readonly />
          <FGWalterDateMaskInput
            name="datePriseEnCours"
            label={t(ETLCodes.DatePriseEnCours)}
            readonly={+idavenant > 0}
          />
          <FGWalterDateMaskInput name="dateSignature" label={t(ETLCodes.DateSignature)} readonly={+idavenant > 0} />
          <FGWalterSelectInput
            name="idcpSignature"
            label={t(ETLCodes.LocaliteSignature)}
            items={codesPostaux}
            loading={cpLoading}
            readonly={+idavenant > 0}
          />
          <FGCustomPanel>
            {ctx =>
              isCommTut(ctx?.formik?.values?.idstypeAvenant) && (
                <FGWalterDateMaskInput
                  name="dateCommissionTutelle"
                  label={t(ETLCodes.DateCommissionTutelle)}
                  readonly={+idavenant > 0}
                />
              )
            }
          </FGCustomPanel>
          {typeContrat === ETypeContrat.CS && (
            <>
              <FGWalterCheckboxInput
                name="decisionInstitut"
                label={t(ETLCodes.DecisionInstitut)}
                readonly={+idavenant > 0}
              />
              <FGWalterDateMaskInput name="dateDecision" label={t(ETLCodes.DateDecision)} readonly={+idavenant > 0} />
              <FGWalterSelectInput
                name="idresponsableValidation"
                label={t(ETLCodes.ResponsableValidation)}
                items={users}
                loading={usersLoading}
                readonly={+idavenant > 0}
              />
            </>
          )}
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} readonly={+idavenant > 0} />
          <FGCustomPanel>
            {ctx => (
              <FGWalterFileInput
                name="fichier"
                label={t(ETLCodes.Document)}
                downloadFn={ctx.formik.values?.fichier && (() => downloadFn(ctx.formik.values?.idfichier))}
                readonly={state !== "edit"}
              />
            )}
          </FGCustomPanel>
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
