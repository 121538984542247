import { Button, Classes, ControlGroup, Icon, Intent, IOptionProps, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { FormikProps } from "formik";
import IBAN from "iban";
import moment from "moment";
import {
  FGCustomInput,
  FGCustomPanel,
  FGListen,
  FGMaskInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IFGContext
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { EmailReplaceDialog, PersonneBankAccountRow, TelephoneReplaceDialog } from ".";
import {
  AdresseDoublonDto,
  AdresseDtoFromJSON,
  CompteBancaireDtoFromJSON,
  EmailDtoFromJSON,
  EmailReplaceDto,
  PersonneApi,
  PersonneEditDto,
  ReferentialApi,
  TelephoneDtoFromJSON,
  TelephoneReplaceDto
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  AddButton,
  AdresseDoublonDialog,
  CommuneSelect,
  DeleteButton,
  ErrorText,
  FGPersonneCodePostal,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  LinkButton,
  LocaliteSelect,
  NationaliteSelect,
  SmallFormGenerator
} from "../../../../../../components";
import FGCopyTextInput from "../../../../../../components/formGenerator/FGCopyTextInput";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTabMessage, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

const DiagonalBarHider = styled.div`
  overflow: hidden;
  position: relative;
`;

const DiagonalBar = styled.div`
  width: 140px;
  background: black;
  height: 15px;
  transform: rotate(-45deg);
  position: absolute;
  top: 25px;
  left: -40px;

  &.hide {
    visibility: hidden;
  }
`;

const StyledIcon = styled(Icon)`
  &.invert {
    filter: invert(100%);
  }
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

const AdresseDoublonErrorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export interface IPersonneSignaletiqueProps {}

export const PersonneSignaletique: React.FunctionComponent<IPersonneSignaletiqueProps> = props => {
  const { t } = useTl();
  const api = useApiService(PersonneApi);
  const refApi = useApiService(ReferentialApi);
  const history = useHistory();
  const formikInnerRef = React.useRef<FormikProps<PersonneEditDto>>();
  const { id, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { sendMessage } = useTabMessage(cfs);
  const { showDialogPromise } = useDialog();

  const [sexe, sLoading] = useReferential(a => a.referentialGetSexe());
  const [pays, pLoading] = useReferential(a => a.referentialGetPays());
  const [codePaysNaissance, setCodePaysNaissance] = React.useState();
  const [localites, lLoading] = useReferential(
    a => a.referentialGetLocalitesSuggest({ pays: codePaysNaissance }),
    false,
    [codePaysNaissance]
  );
  const [typeTels, ttLoading, , typeTelsRaw] = useReferential(a => a.referentialGetTypesTelephone());
  const [typeEmails, teLoading, , typeEmailsRaw] = useReferential(a => a.referentialGetTypesEmail());

  const fetchTypesAdresse = React.useCallback(() => {
    return refApi.referentialGetTypesAdresse();
  }, [refApi]);
  const { data: typesAdresses } = useQuery("type-adresses", fetchTypesAdresse);
  const typeAdresseDomicile = React.useMemo(() => typesAdresses?.list?.find(ta => ta.keyValue === "DOMICILE"), [
    typesAdresses
  ]);
  const typeAdresseCourrier = React.useMemo(() => typesAdresses?.list?.find(ta => ta.keyValue === "COURRIER"), [
    typesAdresses
  ]);
  const typeAdresseAutre = React.useMemo(() => typesAdresses?.list?.find(ta => ta.keyValue === "AUTRE"), [
    typesAdresses
  ]);

  const typeTel = React.useMemo(() => new URLSearchParams(search).get("typeTel"), [search]);
  const typeEmail = React.useMemo(() => new URLSearchParams(search).get("typeEmail"), [search]);
  const typeCb = React.useMemo(() => new URLSearchParams(search).get("typeCb"), [search]);

  const typeTelsList = React.useMemo(() => {
    if (typeTelsRaw) {
      if (!!typeTel) {
        return typeTelsRaw.map(tt => {
          return {
            value: tt.idValue,
            label: tt.displayValue,
            className: tt.keyValue,
            disabled: tt.keyValue !== typeTel
          } as IOptionProps;
        });
      } else {
        return typeTels;
      }
    } else {
      return [];
    }
  }, [typeTel, typeTels, typeTelsRaw]);

  const typeEmailsList = React.useMemo(() => {
    if (typeEmailsRaw) {
      if (!!typeEmail) {
        return typeEmailsRaw.map(te => {
          return {
            value: te.idValue,
            label: te.displayValue,
            className: te.keyValue,
            disabled: te.keyValue !== typeEmail
          } as IOptionProps;
        });
      } else {
        return typeEmails;
      }
    } else {
      return [];
    }
  }, [typeEmail, typeEmails, typeEmailsRaw]);

  const { data, loading, saveItem, saving, validationErrors, refresh } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => api.personneGetPersonne({ id: +id }),
        saveApiFn: async (d: PersonneEditDto) => {
          let result = "yes";
          if (typeTel && d.telephones.every(t => t.idtelephone > 0)) {
            result = await showDialogPromise({
              message: t(ETLCodes.NoTelephoneAddedMessage),
              title: t(ETLCodes.NoTelephoneAdded)
            });
          }
          if (typeEmail && d.emails.every(t => t.idemail > 0)) {
            result = await showDialogPromise({
              message: t(ETLCodes.NoEmailAddedMessage),
              title: t(ETLCodes.NoEmailAdded)
            });
          }
          if (typeCb && d.compteBancaires.every(t => t.idcompteBancaire > 0)) {
            result = await showDialogPromise({
              message: t(ETLCodes.NoCompteBancaireAddedMessage),
              title: t(ETLCodes.NoCompteBancaireAdded)
            });
          }

          if (result === "yes") {
            let newPersonne = { ...d };
            if (!!newPersonne.adresseDomicile?.adresse && newPersonne.adresseDomicile?.adresse !== "") {
              newPersonne.adresseDomicile = AdresseDtoFromJSON({
                ...newPersonne.adresseDomicile,
                idpersonne: newPersonne.idpersonne,
                idtypeAdresse: +typeAdresseDomicile.idValue
              });
            } else if (Object.keys(newPersonne.adresseDomicile ?? {}) === ["adress"]) {
              newPersonne.adresseDomicile = null;
            }
            if (!!newPersonne.adresseCourrier?.adresse && newPersonne.adresseCourrier?.adresse !== "") {
              newPersonne.adresseCourrier = AdresseDtoFromJSON({
                ...newPersonne.adresseCourrier,
                idpersonne: newPersonne.idpersonne,
                idtypeAdresse: +typeAdresseCourrier.idValue
              });
            } else if (Object.keys(newPersonne.adresseCourrier ?? {}) === ["adress"]) {
              newPersonne.adresseCourrier = null;
            }
            if (!!newPersonne.adresseAutre?.adresse && newPersonne.adresseAutre?.adresse !== "") {
              newPersonne.adresseAutre = AdresseDtoFromJSON({
                ...newPersonne.adresseAutre,
                idpersonne: newPersonne.idpersonne,
                idtypeAdresse: +typeAdresseAutre.idValue
              });
            } else if (Object.keys(newPersonne.adresseAutre ?? {}) === ["adress"]) {
              newPersonne.adresseAutre = null;
            }
            return api.personneSavePersonne({ PersonneEditDto: newPersonne });
          }
        },
        onSavedRoute: d => `${ERoutes.personne}/${d.idpersonne}/signaletique/edit`,
        onSaved: (d: PersonneEditDto) => {
          let valueToSend;
          if (typeTel) {
            valueToSend = Math.max(...d.telephones.map(t => t.idtelephone));
          }
          if (typeEmail) {
            valueToSend = Math.max(...d.emails.map(t => t.idemail));
          }
          if (typeCb) {
            valueToSend = Math.max(...d.compteBancaires.map(t => t.idcompteBancaire));
          }
          sendMessage(valueToSend, true);
        },
        serverValidationRootKey: "Dto"
      }),
      [
        api,
        id,
        sendMessage,
        showDialogPromise,
        t,
        typeAdresseAutre?.idValue,
        typeAdresseCourrier?.idValue,
        typeAdresseDomicile?.idValue,
        typeCb,
        typeEmail,
        typeTel
      ]
    )
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required)),
      prenom: Yup.string().required(t(ETLCodes.Required)),
      dateNaissance: Yup.date().nullable(),
      compteBancaires: Yup.array().of(
        Yup.object()
          .nullable()
          .shape({
            iban: Yup.string()
              .nullable()
              .test("iban-valid", t(ETLCodes.InvalidIban), async function(value) {
                if (!value || value.trim() === "") return true;
                return IBAN.isValid(value);
              })
          })
      )
    });
  }, [t]);

  const addCb = React.useCallback(
    (formik: FormikProps<PersonneEditDto>) => {
      let cbs = [...formik.values.compteBancaires];
      cbs.push(CompteBancaireDtoFromJSON({ idpersonne: +id }));
      formik.setFieldValue("compteBancaires", cbs);
    },
    [id]
  );

  const addTel = React.useCallback(
    (formik: FormikProps<PersonneEditDto>) => {
      let tels = [...formik.values.telephones];
      tels.push(TelephoneDtoFromJSON({ idpersonne: +id }));
      formik.setFieldValue("telephones", tels);
    },
    [id]
  );

  const [telToReplace, setTelToReplace] = React.useState(null);
  const removeTel = React.useCallback(
    async (index: number, formik: FormikProps<PersonneEditDto>) => {
      let tels = [...formik.values.telephones];
      const tel = tels[index];

      const used = await api.personneIsPhoneUsed({ idtelephone: tel.idtelephone });
      if (used.value) {
        setTelToReplace(tel);
      } else {
        tels.splice(index, 1);
        formik.setFieldValue("telephones", tels);
      }
    },
    [api]
  );

  const onTelephoneReplaced = React.useCallback(
    (telReplace?: TelephoneReplaceDto) => {
      if (!!telReplace) {
        let tels = [...formikInnerRef.current.values.telephones];
        const index = tels.findIndex(t => +t.idtelephone === +telToReplace.idtelephone);
        tels.splice(index, 1);

        if (telReplace.isNew) {
          tels.push(
            TelephoneDtoFromJSON({
              idpersonne: telReplace.idpersonne,
              numero: telReplace.numero,
              idtypeTelephone: telReplace.idtypeTelephone,
              idtelephne: telReplace.newIdtelephone
            })
          );
        }
        formikInnerRef.current.setFieldValue("telephones", tels);
        saveItem(formikInnerRef.current.values);
      }
      setTelToReplace(null);
    },
    [saveItem, telToReplace?.idtelephone]
  );

  const addEmail = React.useCallback(
    (formik: FormikProps<PersonneEditDto>) => {
      let emails = [...formik.values.emails];
      emails.push(EmailDtoFromJSON({ idpersonne: +id }));
      formik.setFieldValue("emails", emails);
    },
    [id]
  );

  const [emailToReplace, setEmailToReplace] = React.useState(null);
  const removeEmail = React.useCallback(
    async (index: number, formik: FormikProps<PersonneEditDto>) => {
      let emails = [...formik.values.emails];
      const email = emails[index];

      const used = await api.personneIsEmailUsed({ idemail: email.idemail });
      if (used.value) {
        setEmailToReplace(email);
      } else {
        emails.splice(index, 1);
        formik.setFieldValue("emails", emails);
      }
    },
    [api]
  );

  const onEmailReplaced = React.useCallback(
    (emailReplace?: EmailReplaceDto) => {
      if (!!emailReplace) {
        let emails = [...formikInnerRef.current.values.emails];
        const index = emails.findIndex(t => +t.idemail === +emailToReplace.idemail);
        emails.splice(index, 1);

        if (emailReplace.isNew) {
          emails.push(
            EmailDtoFromJSON({
              idpersonne: emailReplace.idpersonne,
              adresseEmail: emailReplace.adresseEmail,
              idtypeEmail: emailReplace.idtypeEmail,
              idemail: emailReplace.newIdemail
            })
          );
        }
        formikInnerRef.current.setFieldValue("emails", emails);
        saveItem(formikInnerRef.current.values);
      }
      setEmailToReplace(null);
    },
    [emailToReplace?.idemail, saveItem]
  );

  const removeAdresse = React.useCallback((adresseToRemove: string, formik: FormikProps<PersonneEditDto>) => {
    formik.setFieldValue(adresseToRemove, null);
  }, []);

  const anyForme = React.useMemo(
    () =>
      !!data?.idapprenant ||
      !!data?.idformateur ||
      !!data?.idrepresentant ||
      !!data?.idtuteur ||
      !!data?.idchefEntreprise ||
      !!data?.iduser ||
      !!data?.idconseillerPedagogique,
    [
      data?.idapprenant,
      data?.idchefEntreprise,
      data?.idconseillerPedagogique,
      data?.idformateur,
      data?.idrepresentant,
      data?.idtuteur,
      data?.iduser
    ]
  );

  const formDisabled = React.useMemo(
    () =>
      data?.idaddressDoublons?.length > 1 ||
      data?.idaddressCourrierDoublons?.length > 1 ||
      data?.idaddressAutreDoublons?.length > 1,
    [data?.idaddressAutreDoublons?.length, data?.idaddressCourrierDoublons?.length, data?.idaddressDoublons?.length]
  );

  const [fixAdresseData, setFixAdresseData] = React.useState<AdresseDoublonDto>(null);
  const fixAdresse = React.useCallback((idpersonne: number, idtypeAdresse: number) => {
    setFixAdresseData({ idpersonne, idtypeAdresse });
  }, []);

  return (
    <>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={state === "edit"}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.personne)}
        showDeleteButton={false}
        saving={saving}
        validationErrors={validationErrors}
        formikInnerRef={formikInnerRef}
        disabled={formDisabled}
        minLabelWidth={190}
      >
        <FGListen field="codePaysNaissance" onChanged={value => setCodePaysNaissance(value)} />
        <FieldGroup
          fieldsetProps={{
            title: t(ETLCodes.DonneesDeBase)
          }}
          columns={2}
          rightElement={
            anyForme && (
              <Popover
                position="bottom"
                content={
                  <PopoverContent>
                    <Menu>
                      {!!data?.idapprenant && (
                        <MenuItem
                          intent="primary"
                          icon={IconNames.EYE_OPEN}
                          text={t(ETLCodes.VoirApprenant)}
                          onClick={() => window.open(`#${ERoutes.apprenant}/${data.idapprenant}/signaletique/view`)}
                        />
                      )}
                      {!!data?.idformateur && (
                        <MenuItem
                          intent="primary"
                          icon={IconNames.EYE_OPEN}
                          text={t(ETLCodes.VoirFormateur)}
                          onClick={() => window.open(`#${ERoutes.formateur}/${data.idformateur}/signaletique/view`)}
                        />
                      )}
                      {!!data?.idrepresentant && (
                        <MenuItem
                          intent="primary"
                          icon={IconNames.EYE_OPEN}
                          text={t(ETLCodes.VoirRepresentant)}
                          onClick={() => window.open(`#${ERoutes.representant}/${data.idrepresentant}/detail/view`)}
                        />
                      )}
                      {!!data?.iduser && (
                        <MenuItem
                          intent="primary"
                          icon={IconNames.EYE_OPEN}
                          text={t(ETLCodes.VoirCollaborateur)}
                          onClick={() => window.open(`#${ERoutes.users}/${data.iduser}/detail/view`)}
                        />
                      )}
                      {!!data?.idchefEntreprise && (
                        <MenuItem
                          intent="primary"
                          icon={IconNames.EYE_OPEN}
                          text={t(ETLCodes.VoirChefEntreprise)}
                          onClick={() => window.open(`#${ERoutes.chefEntreprise}/${data.idchefEntreprise}/detail/view`)}
                        />
                      )}
                      {!!data?.idtuteur && (
                        <MenuItem
                          intent="primary"
                          icon={IconNames.EYE_OPEN}
                          text={t(ETLCodes.VoirTuteur)}
                          onClick={() => window.open(`#${ERoutes.tuteur}/${data.idtuteur}/detail/view`)}
                        />
                      )}
                      {!!data?.idconseillerPedagogique && (
                        <MenuItem
                          intent="primary"
                          icon={IconNames.EYE_OPEN}
                          text={t(ETLCodes.VoirConseeillerPedagogique)}
                          onClick={() =>
                            window.open(`#${ERoutes.conseillerPedagogique}/${data.idconseillerPedagogique}/detail/view`)
                          }
                        />
                      )}
                    </Menu>
                  </PopoverContent>
                }
              >
                <Button
                  icon={IconNames.CHEVRON_DOWN}
                  text={t(ETLCodes.VoirFormes)}
                  minimal={true}
                  intent={Intent.PRIMARY}
                ></Button>
              </Popover>
            )
          }
        >
          <FieldGroup>
            <FGTextInput name="nom" label={t(ETLCodes.Nom)} forceCase="upper" readonly />
            <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} readonly />
            <FGWalterSelectInput name="codesexe" label={t(ETLCodes.Genre)} items={sexe} loading={sLoading} readonly />
            <NationaliteSelect name="nationalite" codeSexeFieldName="codesexe" readonly />
            <FGMaskInput
              name="registreNational"
              label={t(ETLCodes.NumeroNational)}
              cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
              readonly
              visible={ctx => !ctx.formik?.values?.nationalite || ctx.formik?.values?.nationalite === "BE"}
            />
            <FGTextInput
              name="numeroIdentification"
              label={t(ETLCodes.NumeroIdentification)}
              readonly
              visible={ctx => !!ctx.formik?.values?.nationalite && ctx.formik?.values?.nationalite !== "BE"}
            />
            <FieldGroup columns={2}>
              <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} readonly />
              <FGCustomInput label={t(ETLCodes.Age)}>
                {(ctx: IFGContext<PersonneEditDto>) => (
                  <div style={{ marginTop: 6 }}>
                    {t(ETLCodes.XYear, { ageNumber: moment().diff(ctx?.formik?.values?.dateNaissance, "years") })}
                  </div>
                )}
              </FGCustomInput>
            </FieldGroup>
            <FGWalterDateMaskInput name="dateDeces" label={t(ETLCodes.DateDeces)} readonly />
            <FGWalterSelectInput
              name="codePaysNaissance"
              label={t(ETLCodes.PaysNaissance)}
              items={pays}
              loading={pLoading}
              readonly
            />
            <FGWalterSelectInput
              name="localiteNaissance"
              label={t(ETLCodes.LocaliteNaissance)}
              loading={lLoading}
              items={localites}
              readonly
            />
            <FGTextInput name="communeNaissance" label={t(ETLCodes.CommuneNaissance)} readonly />
            <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} readonly />
            <FieldGroup
              fieldsetProps={{
                title: t(ETLCodes.ComptesBancaires)
              }}
            >
              <FGCustomPanel>
                {(ctx: IFGContext<PersonneEditDto>) => (
                  <>
                    {ctx?.formik?.values?.compteBancaires?.map((_, idx: number) => (
                      <PersonneBankAccountRow
                        idpersonne={+id}
                        index={idx}
                        formik={ctx.formik}
                        editMode={ctx.editMode}
                        disabled={ctx.disabled}
                      />
                    ))}
                    {ctx?.editMode && !typeTel && !typeEmail && (
                      <AddButton
                        text={t(ETLCodes.General_Add)}
                        onClick={() => addCb(ctx?.formik)}
                        fill
                        disabled={ctx.disabled}
                      />
                    )}
                  </>
                )}
              </FGCustomPanel>
            </FieldGroup>
          </FieldGroup>
          <FGCustomPanel>
            {ctx => (
              <div className={loading ? Classes.SKELETON : ""}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {ctx.formik.values.photo ? (
                    <>
                      <DiagonalBarHider>
                        <DiagonalBar className={classNames({ hide: !ctx?.formik?.values?.dateDeces })} />
                        <img
                          src={`data:image/png;base64,${ctx.formik.values.photo}`}
                          alt="Profil"
                          style={{ maxHeight: "9.5rem" }}
                        />
                      </DiagonalBarHider>
                    </>
                  ) : (
                    <StyledIcon
                      iconSize={136.52}
                      icon={IconNames.USER}
                      style={{ marginBottom: "10px" }}
                      className={classNames({ invert: !!ctx?.formik?.values?.dateDeces })}
                    />
                  )}
                </div>
              </div>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FieldGroup columns={2}>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.NumerosTelephone)
            }}
          >
            <FGCustomPanel>
              {(ctx: IFGContext<PersonneEditDto>) => (
                <>
                  {ctx?.formik?.values?.telephones?.map((_, idx: number) => (
                    <ControlGroup>
                      <FGWalterSelectInput
                        name={`telephones[${idx}].idtypeTelephone`}
                        noLabel
                        items={typeTelsList}
                        loading={ttLoading}
                        requiredMark
                        readonly={ctx?.formik?.values?.telephones[idx].idtelephone >= 0}
                      />
                      <FGCopyTextInput
                        copyOnlyDigits={true}
                        name={`telephones[${idx}].numero`}
                        noLabel
                        maxLength={20}
                        requiredMark
                      />
                      {
                        <DeleteButton
                          onDelete={() => removeTel(idx, ctx?.formik)}
                          disabled={
                            ctx.disabled ||
                            !(
                              ctx?.editMode &&
                              !typeEmail &&
                              (!typeTel || !ctx?.formik?.values?.telephones[idx].idtelephone)
                            )
                          }
                        />
                      }
                    </ControlGroup>
                  ))}
                  {ctx?.editMode && !typeEmail && (
                    <AddButton
                      text={t(ETLCodes.General_Add)}
                      onClick={() => addTel(ctx?.formik)}
                      fill
                      disabled={ctx.disabled}
                    />
                  )}
                </>
              )}
            </FGCustomPanel>
          </FieldGroup>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.Emails)
            }}
          >
            <FGCustomPanel>
              {(ctx: IFGContext<PersonneEditDto>) => (
                <>
                  {ctx?.formik?.values?.emails?.map((_, idx: number) => (
                    <ControlGroup>
                      <FGWalterSelectInput
                        name={`emails[${idx}].idtypeEmail`}
                        noLabel
                        items={typeEmailsList}
                        loading={teLoading}
                        requiredMark
                        readonly={ctx?.formik?.values?.emails[idx].idemail >= 0}
                      />
                      <FGTextInput name={`emails[${idx}].adresseEmail`} noLabel maxLength={100} requiredMark />
                      {
                        <DeleteButton
                          onDelete={() => removeEmail(idx, ctx?.formik)}
                          disabled={
                            ctx.disabled ||
                            !(ctx?.editMode && !typeTel && (!typeEmail || !ctx?.formik?.values?.emails[idx].idemail))
                          }
                        />
                      }
                    </ControlGroup>
                  ))}
                  {ctx?.editMode && !typeTel && (
                    <AddButton
                      text={t(ETLCodes.General_Add)}
                      onClick={() => addEmail(ctx?.formik)}
                      fill
                      disabled={ctx.disabled}
                    />
                  )}
                </>
              )}
            </FGCustomPanel>
          </FieldGroup>
        </FieldGroup>
        <FGCustomPanel>
          {ctx => (
            <FieldGroup
              fieldsetProps={{
                title: t(ETLCodes.Adresses)
              }}
              columns={3}
            >
              <FieldGroup
                fieldsetProps={{
                  title: (
                    <>
                      {t(ETLCodes.Domicile)}{" "}
                      {ctx.formik.values?.idaddressDoublons?.length > 1 && (
                        <AdresseDoublonErrorContainer>
                          <ErrorText text={t(ETLCodes.DoublonAdresseACorriger)} fontSize={11} />
                          {"\u00A0"}
                          <LinkButton
                            text={t(ETLCodes.CorrigerDoublon)}
                            onClick={() => fixAdresse(ctx.formik.values.idpersonne, +typeAdresseDomicile?.idValue)}
                          />
                        </AdresseDoublonErrorContainer>
                      )}
                    </>
                  )
                }}
                rightElement={
                  ctx?.editMode && (
                    <DeleteButton
                      minimal
                      onDelete={() => removeAdresse("adresseDomicile", ctx?.formik)}
                      disabled={ctx.disabled}
                      showWarning={true}
                    />
                  )
                }
              >
                <FGWalterSelectInput
                  labelStyles={{ minWidth: 100 }}
                  name="adresseDomicile.pays"
                  label={t(ETLCodes.Pays)}
                  items={pays}
                  loading={pLoading}
                />
                <FGTextAreaInput
                  labelStyles={{ minWidth: 100 }}
                  name="adresseDomicile.adresse"
                  label={t(ETLCodes.Adresse)}
                />
                <FGPersonneCodePostal
                  idName="adresseDomicile.idcodePostal"
                  textName="adresseDomicile.codePostalText"
                  localiteName="adresseDomicile.localite"
                />
                <FGCustomPanel>
                  {ctx => (
                    <>
                      <LocaliteSelect
                        labelStyles={{ minWidth: 100 }}
                        codePostal={ctx?.formik?.values?.adresseDomicile?.codePostalText}
                        codePays={ctx?.formik?.values?.adresseDomicile?.pays}
                        name="adresseDomicile.localite"
                        codePostalTextName="adresseDomicile.codePostalText"
                        disableIfNoCp={false}
                      />
                      <CommuneSelect
                        labelStyles={{ minWidth: 100 }}
                        codePostal={ctx?.formik?.values?.adresseDomicile?.codePostalText}
                        codePays={ctx?.formik?.values?.adresseDomicile?.pays}
                        name="adresseDomicile.commune"
                      />
                    </>
                  )}
                </FGCustomPanel>
              </FieldGroup>
              <FieldGroup
                fieldsetProps={{
                  title: (
                    <>
                      {t(ETLCodes.Courrier)}{" "}
                      {ctx.formik.values?.idaddressCourrierDoublons?.length > 1 && (
                        <AdresseDoublonErrorContainer>
                          <ErrorText text={t(ETLCodes.DoublonAdresseACorriger)} fontSize={11} />
                          {"\u00A0"}
                          <LinkButton
                            text={t(ETLCodes.CorrigerDoublon)}
                            onClick={() => fixAdresse(ctx.formik.values.idpersonne, +typeAdresseCourrier?.idValue)}
                          />
                        </AdresseDoublonErrorContainer>
                      )}
                    </>
                  )
                }}
                rightElement={
                  ctx?.editMode && (
                    <DeleteButton
                      minimal
                      onDelete={() => removeAdresse("adresseCourrier", ctx?.formik)}
                      disabled={ctx.disabled}
                      showWarning={true}
                    />
                  )
                }
              >
                <FGWalterSelectInput
                  labelStyles={{ minWidth: 100 }}
                  name="adresseCourrier.pays"
                  label={t(ETLCodes.Pays)}
                  items={pays}
                  loading={pLoading}
                />
                <FGTextAreaInput
                  labelStyles={{ minWidth: 100 }}
                  name="adresseCourrier.adresse"
                  label={t(ETLCodes.Adresse)}
                />
                <FGPersonneCodePostal
                  idName="adresseCourrier.idcodePostal"
                  textName="adresseCourrier.codePostalText"
                  localiteName="adresseCourrier.localite"
                />
                <FGCustomPanel>
                  {ctx => (
                    <>
                      <LocaliteSelect
                        labelStyles={{ minWidth: 100 }}
                        codePostal={ctx?.formik?.values?.adresseCourrier?.codePostalText}
                        codePays={ctx?.formik?.values?.adresseCourrier?.pays}
                        name="adresseCourrier.localite"
                        codePostalTextName="adresseCourrier.codePostalText"
                        disableIfNoCp={false}
                      />
                      <CommuneSelect
                        labelStyles={{ minWidth: 100 }}
                        codePostal={ctx?.formik?.values?.adresseCourrier?.codePostalText}
                        codePays={ctx?.formik?.values?.adresseCourrier?.pays}
                        name="adresseCourrier.commune"
                      />
                    </>
                  )}
                </FGCustomPanel>
              </FieldGroup>
              <FieldGroup
                fieldsetProps={{
                  title: (
                    <>
                      {t(ETLCodes.Autre)}{" "}
                      {ctx.formik.values?.idaddressAutreDoublons?.length > 1 && (
                        <AdresseDoublonErrorContainer>
                          <ErrorText text={t(ETLCodes.DoublonAdresseACorriger)} fontSize={11} />
                          {"\u00A0"}
                          <LinkButton
                            text={t(ETLCodes.CorrigerDoublon)}
                            onClick={() => fixAdresse(ctx.formik.values.idpersonne, +typeAdresseAutre?.idValue)}
                          />
                        </AdresseDoublonErrorContainer>
                      )}
                    </>
                  )
                }}
                rightElement={
                  ctx?.editMode && (
                    <DeleteButton
                      minimal
                      onDelete={() => removeAdresse("adresseAutre", ctx?.formik)}
                      disabled={ctx.disabled}
                      showWarning={true}
                    />
                  )
                }
              >
                <FGWalterSelectInput
                  labelStyles={{ minWidth: 100 }}
                  name="adresseAutre.pays"
                  label={t(ETLCodes.Pays)}
                  items={pays}
                  loading={pLoading}
                />
                <FGTextAreaInput
                  labelStyles={{ minWidth: 100 }}
                  name="adresseAutre.adresse"
                  label={t(ETLCodes.Adresse)}
                />
                <FGPersonneCodePostal
                  idName="adresseAutre.idcodePostal"
                  textName="adresseAutre.codePostalText"
                  localiteName="adresseAutre.localite"
                />
                <FGCustomPanel>
                  {ctx => (
                    <>
                      <LocaliteSelect
                        labelStyles={{ minWidth: 100 }}
                        codePostal={ctx?.formik?.values?.adresseAutre?.codePostalText}
                        codePays={ctx?.formik?.values?.adresseAutre?.pays}
                        name="adresseAutre.localite"
                        codePostalTextName="adresseAutre.codePostalText"
                        disableIfNoCp={false}
                      />
                      <CommuneSelect
                        labelStyles={{ minWidth: 100 }}
                        codePostal={ctx?.formik?.values?.adresseAutre?.codePostalText}
                        codePays={ctx?.formik?.values?.adresseAutre?.pays}
                        name="adresseAutre.commune"
                      />
                    </>
                  )}
                </FGCustomPanel>
              </FieldGroup>
            </FieldGroup>
          )}
        </FGCustomPanel>
      </SmallFormGenerator>
      {!!telToReplace && (
        <TelephoneReplaceDialog
          idpersonne={+id}
          dialogOpen={!!telToReplace}
          onClose={onTelephoneReplaced}
          currentTel={telToReplace}
        />
      )}
      {!!emailToReplace && (
        <EmailReplaceDialog
          idpersonne={+id}
          dialogOpen={!!emailToReplace}
          onClose={onEmailReplaced}
          currentEmail={emailToReplace}
        />
      )}
      {!!fixAdresseData && (
        <AdresseDoublonDialog
          dialogOpen={!!fixAdresseData}
          initialData={fixAdresseData}
          onClose={refreshData => {
            if (refreshData) {
              refresh();
            }
            setFixAdresseData(null);
          }}
        />
      )}
    </>
  );
};
