import { Intent } from "@blueprintjs/core";
import { FGTextAreaInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbFormateurDetailDtoFromJSON, FormateurApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AddButton,
  FGEditableEmailSelectInput,
  FGEditableTelSelectInput,
  FGWalterSuggestTextInput,
  SmallFormGenerator
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import {
  ActiviteProfessionelleDiplomeListPage,
  ActiviteProfessionnelleProfessionListPage,
  ActiviteProfessionnelleTitreListPage
} from "./list";

export interface IActiviteProfessionnellesListPageProps {}

export const ActiviteProfessionnellesListPage: React.FunctionComponent<IActiviteProfessionnellesListPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTl();
  const forApi = useApiService(FormateurApi);
  const history = useHistory();
  const path = React.useMemo(() => `${ERoutes.formateur}/${id}/activitesProfessionnelles`, [id]);
  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (+id <= 0 ? FcbFormateurDetailDtoFromJSON({ idFormateur: +id }) : forApi.formateurGet({ id: +id })),
    saveApiFn: d => forApi.formateurSave({ FcbFormateurDetailDto: d }),
    onSavedRoute: d => `${ERoutes.formateur}/${d.idformateur}/activitesProfessionnelles`,
    deleteApiFn: () => {}
  });

  const [entreprise, eLoading] = useReferential(a => a.referentialGetEntreprises(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      emailProf: Yup.string().email(t(ETLCodes.InvalidEmail))
    });
  }, [t]);
  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);

  return (
    <>
      <FieldSet title={t(ETLCodes.GeneralInformation)}>
        {data && (
          <SmallFormGenerator
            initialValues={data}
            onSubmit={saveItem}
            editMode={true}
            validationSchema={FormSchema}
            loading={loading}
            onCancel={() => history.push(`${ERoutes.formateur}/${id}/signaletique`)}
            onDelete={deleteItem}
            saving={saving}
            deleting={deleting}
          >
            <FieldGroup columns={2}>
              <FGEditableTelSelectInput
                name="telephoneProfFormateur.idtelephone"
                label={t(ETLCodes.TelPro)}
                autoSelectIfOne
                idpersonne={idpersonne}
                types={["PRO"]}
              />
              <FGEditableTelSelectInput
                name="telephoneFormateur.idtelephone"
                label={t(ETLCodes.Tel)}
                autoSelectIfOne
                idpersonne={idpersonne}
                types={["PERSO"]}
              />
              <FGEditableTelSelectInput
                name="gsmProfFormateur.idtelephone"
                label={t(ETLCodes.GsmPro)}
                autoSelectIfOne
                idpersonne={idpersonne}
                types={["GSM_PRO"]}
              />
              <FGEditableTelSelectInput
                name="gsmFormateur.idtelephone"
                label={t(ETLCodes.Gsm)}
                autoSelectIfOne
                idpersonne={idpersonne}
                types={["GSM_PERSO"]}
              />
              <FGEditableEmailSelectInput
                name="emailProfFormateur.idemail"
                label={t(ETLCodes.EmailPro)}
                autoSelectIfOne
                idpersonne={idpersonne}
                types={["PRO"]}
              />
              <FGWalterSuggestTextInput
                displayNoResult={false}
                name="entreprise"
                label={t(ETLCodes.Entreprise)}
                items={!eLoading && entreprise.map(e => e.label)}
                loading={eLoading}
              />
              <FGTextAreaInput name="activite" label={t(ETLCodes.Activite)} />
            </FieldGroup>
          </SmallFormGenerator>
        )}
      </FieldSet>
      <FieldSet
        collapsable
        title={t(ETLCodes.Profession)}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/Profession/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <ActiviteProfessionnelleProfessionListPage idFormateur={+id} />
      </FieldSet>
      <FieldSet
        collapsable
        title={t(ETLCodes.Titres)}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/Titre/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <ActiviteProfessionnelleTitreListPage idFormateur={+id} />
      </FieldSet>
      <FieldSet
        collapsable
        title={t(ETLCodes.Diplomes)}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/Diplome/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <ActiviteProfessionelleDiplomeListPage idFormateur={+id} />
      </FieldSet>
    </>
  );
};
