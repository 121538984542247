import { Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { add, addMinutes, endOfDay, format, isAfter, parseJSON } from "date-fns";
import {
  ButtonContainer,
  DataTable,
  FGCustomInput,
  FGCustomPanel,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IDataTableColumn,
  useFGFormik,
  useGridState,
  ValidationErrorsType
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { SuiviDocumentEditDialog, SuiviDocumentUploadDialog } from "..";
import {
  ContratApi,
  EContentHidingMode,
  EIfapmeSide,
  ELevelName,
  EStatutContrat,
  ETypeContrat,
  LieuFormationApi,
  MetierApi,
  SuiviFichierGridDto,
  SuiviHopeEditDto
} from "../../../../../api";
import {
  AddButton,
  DeleteButton,
  DownloadButton,
  EditButton,
  FGReadonlyHtmlViewer,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  LinkButton,
  SmallFormGenerator
} from "../../../../../components";
import { useAuth } from "../../../../../contexts";
import { useApiService, useTabMessage, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { SuiviActionDialog } from "./suiviAction/SuiviActionDialog";

const StatutContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  > span {
    font-weight: bold;
    color: ${props => props.color};
    font-size: 14pt;
  }
`;

const RadioReadonlyContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
`;

export interface ISuiviApprenantFormProps {
  data: SuiviHopeEditDto;
  suiviId: number;
  saveItem: (values: SuiviHopeEditDto) => void | Promise<void>;
  loading: boolean;
  saving: boolean;
  deleteItem?: () => void | Promise<void>;
  deleting?: boolean;
  editMode: boolean;
  onCancel?: () => void;
  validationErrors?: ValidationErrorsType;
  fieldsetTitle: string;
  deleteFichier?: (idsuiviFichier) => void;
  downloadFichier?: (idsuiviFichier) => void;
  rowLoading?: number;
  refreshData?: () => void;
}

export const SuiviApprenantForm: React.FunctionComponent<ISuiviApprenantFormProps> = ({
  data,
  suiviId,
  deleteItem,
  editMode,
  loading,
  saveItem,
  saving,
  onCancel,
  deleting,
  validationErrors,
  fieldsetTitle,
  deleteFichier,
  downloadFichier,
  rowLoading,
  refreshData
}) => {
  const { t } = useTl();
  const { user } = useAuth();
  const { theme, isHope } = useTheme();
  const metierApi = useApiService(MetierApi);
  const lieuFormationApi = useApiService(LieuFormationApi);
  const contratApi = useApiService(ContratApi);

  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { messageValue, clearMessageValue } = useTabMessage(cfs);

  const isCop = React.useMemo(() => user?.hopeMainLevelName === ELevelName.COP, [user?.hopeMainLevelName]);
  const isCalc = React.useMemo(() => user?.hopeMainLevelName === ELevelName.CALC, [user?.hopeMainLevelName]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      dateSuivi: Yup.date()
        .typeError(t(ETLCodes.DateInvalide))
        .required(t(ETLCodes.Required))
        .min(new Date(1900, 1, 1), t(ETLCodes.DateMinimale))
        .max(endOfDay(add(new Date(), { years: 1 })), t(ETLCodes.DateCannotBeGreaterThan1YearInFurture)),
      idservice: Yup.number().required(t(ETLCodes.Required)),
      iduserService: Yup.number().required(t(ETLCodes.Required)),
      idtypeSuivi: Yup.number().required(t(ETLCodes.Required)),
      idsousTypeSuivi: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const formik = useFGFormik<SuiviHopeEditDto>({
    initialValues: data,
    onSubmit: saveItem,
    validationSchema: FormSchema
  });

  const [lieurencontres, lrLoading] = useReferential(a => a.referentialGetLieuRencontres(), true, []);
  const [modes, mLoading] = useReferential(a => a.referentialGetModes(), true, []);
  const [metiers, meLoading] = useReferential(a => a.referentialGetMetier(), true, []);
  const [sousTypeSuivis, stsLoading, , rawSousTypeSuivis] = useReferential(
    a =>
      a.referentialGetSousTypeSuivis({
        idtypeSuivi: formik?.values?.idtypeSuivi,
        idsousTypeSuivi: data?.idsousTypeSuivi
      }),
    true,
    [formik?.values?.idtypeSuivi, data?.idsousTypeSuivi]
  );
  const [typeSuivis, tsLoading] = useReferential(
    a => a.referentialGetTypeSuivis({ side: EIfapmeSide.Hope, idtypeSuivi: data?.idtypeSuivi }),
    true,
    [data?.idtypeSuivi]
  );
  const [apprenantContrats, acLoading, , rawContrats] = useReferential(
    a =>
      a.referentialGetApprenantContrats({
        idApprenant: formik?.values?.idapprenant,
        ETypeContrat: [
          ETypeContrat.CC,
          ETypeContrat.CA,
          ETypeContrat.CS,
          ETypeContrat.CF,
          ETypeContrat.DM,
          ETypeContrat.JI,
          ETypeContrat.PP
        ]
      }),
    true,
    [formik?.values?.idapprenant]
  );

  const [canSetIdContrat, setCanSetIdContrat] = React.useState(true);

  React.useEffect(() => {
    if (rawContrats?.length > 0 && !!formik?.values && !formik?.values?.idcontrat && suiviId <= 0 && canSetIdContrat) {
      let c = rawContrats.find(c => c.keyValue === EStatutContrat.EnCours);
      if (!c) {
        const maxId = Math.max.apply(rawContrats.map(c => +c.idValue));
        c = rawContrats.find(c => +c.idValue === maxId);
      }
      if (c) formik.setFieldValue("idcontrat", c.idValue);

      setCanSetIdContrat(false);
    }
  }, [canSetIdContrat, formik, rawContrats, suiviId]);

  const fetchContratInfos = React.useCallback(async () => {
    if (!formik?.values?.idcontrat) return null;
    return await contratApi.contratGetContratInfosForSuivi({ idcontrat: formik.values.idcontrat });
  }, [contratApi, formik?.values?.idcontrat]);
  const { data: contratInfos } = useQuery(["suivi-contrat-info", formik?.values?.idcontrat], fetchContratInfos);

  const fetchMetierDisplayName = React.useCallback(async () => {
    if (!contratInfos?.idmetier) return "";
    return await metierApi.metierGetDisplayName({ id: contratInfos?.idmetier });
  }, [contratInfos?.idmetier, metierApi]);
  const { data: metierName, isFetching: mnLoading } = useQuery(
    ["metier-display-name", contratInfos?.idmetier],
    fetchMetierDisplayName
  );

  const fetchLfDisplayName = React.useCallback(async () => {
    if (!contratInfos?.idlieuFormation) return "";
    return await lieuFormationApi.lieuFormationGetDisplayName({ id: contratInfos?.idlieuFormation });
  }, [contratInfos?.idlieuFormation, lieuFormationApi]);
  const { data: lfName, isFetching: lfLoading } = useQuery(
    ["lieu-formation-display-name", contratInfos?.idlieuFormation],
    fetchLfDisplayName
  );
  const [decisionCommissionTutelle, dctLoading] = useReferential(
    a => a.referentialGetDecisionCommissionTutelles({ id: data?.iddecisionCommissionTutelle }),
    true,
    [data?.iddecisionCommissionTutelle]
  );

  const [services, sLoading] = useReferential(
    a => a.referentialGetServiceTutelle({ userFilter: data?.creationUser === user.iduser || +suiviId <= 0 }),
    true,
    [data?.creationUser, user?.iduser, suiviId]
  );
  const [serviceUsers, suLoading] = useReferential(
    a =>
      a.referentialGetServiceUsers({
        request_body: !!formik?.values?.idservice ? [formik?.values?.idservice] : [],
        currentId: formik?.values?.iduserService
      }),
    true,
    [formik?.values?.idservice, formik?.values?.iduserService]
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  React.useEffect(() => {
    if (!!messageValue && messageValue === "FromDashboard" && !!data && !loading) {
      setDialogOpen(true);
      clearMessageValue();
    }
  }, [clearMessageValue, data, loading, messageValue]);

  React.useEffect(() => {
    if (formik?.values?.action && !formik?.initialValues?.action) {
      setDialogOpen(true);
    }
  }, [formik?.initialValues?.action, formik?.values?.action]);

  React.useEffect(() => {
    if (!formik?.values?.action && !!formik?.values?.suiviAction) {
      formik.setFieldValue("suiviAction", null);
    }
  }, [formik]);

  const statutColor = React.useMemo(
    () =>
      !!formik?.values?.suiviAction?.dateRealisation
        ? theme.sucessColor
        : isAfter(formik?.values?.suiviAction?.dateEcheance, new Date())
        ? theme.warningColor
        : theme.dangerColor,
    [
      formik?.values?.suiviAction?.dateEcheance,
      formik?.values?.suiviAction?.dateRealisation,
      theme.dangerColor,
      theme.sucessColor,
      theme.warningColor
    ]
  );

  const statutText = React.useMemo(
    () =>
      t(
        !!formik?.values?.suiviAction?.dateRealisation
          ? ETLCodes.ActionRealisee
          : isAfter(formik?.values?.suiviAction?.dateEcheance, new Date())
          ? ETLCodes.ActionNonRealisee
          : ETLCodes.DateEcheanceDepassee
      ),
    [formik?.values?.suiviAction?.dateEcheance, formik?.values?.suiviAction?.dateRealisation, t]
  );

  const actionIsRealisee = React.useMemo(() => !!formik?.values?.suiviAction?.dateRealisation, [
    formik?.values?.suiviAction?.dateRealisation
  ]);

  const getROHidingMode = React.useCallback(
    (contentHidingMode: EContentHidingMode) => {
      switch (contentHidingMode) {
        case EContentHidingMode.NONE:
          return t(ETLCodes.Personne);
        case EContentHidingMode.OTHERS:
          return t(ETLCodes.Centres);
        case EContentHidingMode.ALL:
          return t(ETLCodes.TousSaufCopCalc);
      }
    },
    [t]
  );

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (data?.fichiers) {
      setData(data.fichiers);
    }
  }, [data?.fichiers, setData]);

  const [currentSuiviFichierId, setCurrentSuiviFichierId] = React.useState(null);
  const [uploadDialoOpen, setUploadDialoOpen] = React.useState(false);

  const isCreationUser = React.useMemo(
    () => data?.creationUser === user.iduser || data?.iduserService === user.iduser,
    [data?.creationUser, data?.iduserService, user.iduser]
  );

  const contenuVisible = React.useCallback(
    values =>
      values?.contentHidingMode === EContentHidingMode.NONE ||
      values?.creationUser === user.iduser ||
      (user.ifapmeSide.includes("Hope") && values?.contentHidingMode === EContentHidingMode.OTHERS) ||
      isCop ||
      isCalc,
    [isCalc, isCop, user.iduser, user.ifapmeSide]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SuiviFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentSuiviFichierId(row.idsuiviFichier)}
              loading={rowLoading === row.idsuiviFichier}
              disabled={(!!rowLoading && rowLoading !== row.idsuiviFichier) || !(isHope && isCreationUser)}
            />
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idsuiviFichier)}
              loading={rowLoading === row.idsuiviFichier}
              disabled={(!!rowLoading && rowLoading !== row.idsuiviFichier) || !contenuVisible(data)}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idsuiviFichier)}
              loading={rowLoading === row.idsuiviFichier}
              disabled={(!!rowLoading && rowLoading !== row.idsuiviFichier) || !(isHope && isCreationUser)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeSuiviFichier"
      }
    ],
    [contenuVisible, data, deleteFichier, downloadFichier, isCreationUser, isHope, rowLoading, t]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setCurrentSuiviFichierId(null);
      setUploadDialoOpen(false);
      if (refresh) {
        refreshData();
      }
    },
    [refreshData]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialoOpen(true);
  }, []);

  return (
    <SmallFormGenerator
      formik={formik}
      showColons
      editMode={editMode}
      minLabelWidth={150}
      labelAlignment="right"
      formatDate="dd/MM/yyyy"
      loading={loading}
      onCancel={onCancel}
      saving={saving}
      showDeleteButton={+suiviId > 0 && !actionIsRealisee}
      onDelete={deleteItem}
      deleting={deleting}
      editable={(data?.creationUser === user.iduser || data?.iduserService === user.iduser) && !data?.fromSmile}
      validationErrors={validationErrors}
    >
      {!!formik?.values?.suiviAction && (
        <StatutContainer color={statutColor}>
          <span>{statutText}</span>
        </StatutContainer>
      )}
      <FieldGroup
        columns={2}
        fieldsetProps={{
          title: fieldsetTitle
        }}
      >
        <FieldGroup>
          <FGWalterSelectInput
            name="idservice"
            label={t(ETLCodes.Service)}
            items={services}
            loading={sLoading}
            readonly={actionIsRealisee}
            autoSelectIfOne
          />
          <FGWalterSelectInput
            name="iduserService"
            label={t(ETLCodes.RealisateurSuivi)}
            items={serviceUsers}
            loading={suLoading}
            readonly={actionIsRealisee}
          />
          <FGTextInput readonly name="encodeur" label={t(ETLCodes.Encodeur)} />
          <FGWalterDateMaskInput name="dateSuivi" label={t(ETLCodes.DateSuivi)} readonly={actionIsRealisee} />
          {!!formik?.values?.idapprenant && (
            <>
              <FGWalterSelectInput
                name="idcontrat"
                label={t(ETLCodes.Contrat)}
                items={apprenantContrats}
                loading={acLoading}
                readonly={actionIsRealisee}
              />
              {!!formik?.values?.idmetier ? (
                <FGWalterSelectInput
                  name="idmetier"
                  label={t(ETLCodes.Metier)}
                  items={metiers}
                  loading={meLoading}
                  readonly
                />
              ) : (
                <FGTextInput
                  name="metier"
                  label={t(ETLCodes.Metier)}
                  readonly
                  formatReadOnlyValue={() => metierName}
                  loading={mnLoading}
                />
              )}
              <FGTextInput
                name="lieuFormation"
                label={t(ETLCodes.LieuFormation)}
                readonly
                formatReadOnlyValue={() => lfName}
                loading={lfLoading}
              />
            </>
          )}
          <FGWalterSelectInput
            name="idtypeSuivi"
            label={t(ETLCodes.TypeSuivi)}
            items={typeSuivis}
            loading={tsLoading}
            readonly={actionIsRealisee}
          />
          <FGCustomPanel>
            {ctx => (
              <FGWalterSelectInput
                name="idsousTypeSuivi"
                label={t(ETLCodes.SousTypeSuivi)}
                items={sousTypeSuivis}
                loading={stsLoading}
                disabled={!ctx?.formik?.values?.idtypeSuivi}
                readonly={actionIsRealisee}
              />
            )}
          </FGCustomPanel>
          <FGWalterSelectInput
            name="iddecisionCommissionTutelle"
            label={t(ETLCodes.DecisionCommissionTutelle)}
            items={decisionCommissionTutelle}
            loading={dctLoading}
            readonly={actionIsRealisee}
            visible={
              ctx =>
                !!ctx?.formik?.values?.idsousTypeSuivi &&
                !!rawSousTypeSuivis &&
                +ctx?.formik?.values?.idsousTypeSuivi ===
                  +rawSousTypeSuivis.find(s => s.keyValue === "TUTELLE_25")?.idValue // Commission de tutelle
            }
          />
          <FGWalterSelectInput
            name="idmode"
            label={t(ETLCodes.Mode)}
            items={modes}
            loading={mLoading}
            readonly={actionIsRealisee}
          />
          <FGWalterSelectInput
            name="idlieuRencontre"
            label={t(ETLCodes.LieuRencontre)}
            items={lieurencontres}
            loading={lrLoading}
            readonly={actionIsRealisee}
          />

          <FGCustomPanel>
            {ctx => (
              <>
                <FGCustomInput label={t(ETLCodes.ContenuCachePour)}>
                  {ctx =>
                    ctx?.editMode ? (
                      <RadioGroup
                        disabled={data?.creationUser !== user.iduser && data?.iduserService !== user.iduser}
                        selectedValue={ctx?.formik?.values?.contentHidingMode}
                        inline
                        onChange={e => ctx.formik.setFieldValue("contentHidingMode", e.currentTarget.value)}
                      >
                        <Radio label={t(ETLCodes.Personne)} value={EContentHidingMode.NONE}></Radio>
                        <Radio label={t(ETLCodes.Centres)} value={EContentHidingMode.OTHERS}></Radio>
                        <Radio
                          label={t(ETLCodes.TousSaufCopCalc)}
                          value={EContentHidingMode.ALL}
                          disabled={!isCop}
                        ></Radio>
                      </RadioGroup>
                    ) : (
                      <RadioReadonlyContainer>
                        {getROHidingMode(ctx?.formik?.values?.contentHidingMode)}
                      </RadioReadonlyContainer>
                    )
                  }
                </FGCustomInput>
                <FGWalterCheckboxInput
                  label={t(ETLCodes.Action)}
                  name="action"
                  readonly={actionIsRealisee}
                  helperText={
                    ctx?.formik?.values?.action &&
                    contenuVisible(ctx?.formik?.values) && (
                      <LinkButton
                        text={ctx?.editMode && !actionIsRealisee ? t(ETLCodes.EditAction) : t(ETLCodes.ViewAction)}
                        onClick={() => {
                          setDialogOpen(true);
                        }}
                      />
                    )
                  }
                />

                <SuiviActionDialog
                  dto={ctx?.formik?.values?.suiviAction}
                  isOpen={dialogOpen}
                  onClose={toSave => {
                    setDialogOpen(false);
                    if (toSave) {
                      ctx?.formik.setFieldValue("suiviAction", toSave);
                    } else if (!ctx?.formik?.values?.suiviAction) {
                      ctx.formik.setFieldValue("action", false);
                    }
                  }}
                  enveloppeId={0}
                  editMode={ctx?.editMode && !actionIsRealisee && !data?.fromSmile}
                  editable={!actionIsRealisee && !data?.fromSmile}
                />
              </>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx =>
            ctx.formik?.values?.fromSmile ? (
              <FGReadonlyHtmlViewer name="contenu" label={t(ETLCodes.Contenu)} />
            ) : (
              <FGTextAreaInput
                name="contenu"
                growVertically
                rows={22}
                label={t(ETLCodes.Contenu)}
                visible={contenuVisible(ctx?.formik?.values)}
              />
            )
          }
        </FGCustomPanel>
      </FieldGroup>
      <FieldGroup
        visible={suiviId > 0}
        fieldsetProps={{
          title: t(ETLCodes.Documents),
          rightElement: !actionIsRealisee && (
            <AddButton
              disabled={!isCreationUser}
              onClick={e => {
                e.stopPropagation();
                onAdd();
              }}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          )
        }}
      >
        <DataTable
          dateFormat="dd/MM/yyyy HH:mm"
          formatDate={formatDate}
          tableState={tableState}
          loading={loading}
          columns={columns}
        ></DataTable>
        {uploadDialoOpen && (
          <SuiviDocumentUploadDialog onClose={onClose} dialogOpen={uploadDialoOpen} idsuivi={suiviId} />
        )}
        {!!currentSuiviFichierId && (
          <SuiviDocumentEditDialog onClose={onClose} idsuiviFichier={currentSuiviFichierId} />
        )}
      </FieldGroup>
    </SmallFormGenerator>
  );
};
