import { Card } from "@blueprintjs/core";
import moment from "moment";
import { FGTextInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { ClasseApi, FcbClasseDetailDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export const CLASSE_HORAIRE_CALENDRIER_ITEM_SAVED_EVENT = "CLASSE_HORAIRE_CALENDRIER_ITEM_SAVED";

export interface IClasseHoraireCalendrierItemProps {
  readOnly?: boolean;
  state?: boolean;
}

export const ClasseHoraireCalendrierItem: React.FunctionComponent<IClasseHoraireCalendrierItemProps> = ({
  readOnly,
  state
}) => {
  const { t } = useTl();
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  const api = useApiService(ClasseApi);

  const { subscribeToEvent, unsubscribeEvent, dispatchEvent } = useEventsContext();

  const { data, loading, saveItem, saving, refresh } = useCrudApi({
    getApiFn: React.useCallback(async () => {
      const ret = +id <= 0 ? FcbClasseDetailDtoFromJSON({ classeId: 0 }) : await api.classeGet({ id: +id });
      if (!ret.dateFin) {
        const end = +ret.anneeAcademique.split("-")[1];
        ret.dateFin = moment(`3107${end}`, "DDMMYYYY").toDate();
      }
      return ret;
    }, [api, id]),
    saveApiFn: React.useCallback(
      async d => {
        const dataSaved = await api.classeSave({ FcbClasseDetailDto: d });
        dispatchEvent("CLASSE_HORAIRE_SAVED", dataSaved);
        return dataSaved;
      },
      [api, dispatchEvent]
    ),
    deleteApiFn: d => {},
    onDeletedRoute: () => `${ERoutes.classe}/${+id}/detail/${state}`
  });

  const [plages, pLoading] = useReferential(a => a.referentialGetPlages({ idClasse: +id }), true, [id]);
  const [calendriers, cLoading] = useReferential(a => a.referentialGetCalendriers({ idClasse: +id }), true, [id]);

  const refreshData = React.useCallback(() => {
    refresh();
  }, [refresh]);

  React.useEffect(() => {
    subscribeToEvent("CLASSE_HORAIRE_SAVED", refreshData);
    subscribeToEvent("CLASSE_HORAIRE_ACTIONS", refreshData);
    return () => {
      unsubscribeEvent("CLASSE_HORAIRE_SAVED", refreshData);
      unsubscribeEvent("CLASSE_HORAIRE_ACTIONS", refreshData);
    };
  }, [refreshData, subscribeToEvent, unsubscribeEvent]);

  const onEdit = React.useCallback(() => dispatchEvent("CLASSE_HORAIRE_EDIT", !state), [dispatchEvent, state]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      dateFin: Yup.date().min(Yup.ref("dateCours"), t(ETLCodes.DateFinTooLowError)),
      dateCours: Yup.date()
    });
  }, [t]);

  return (
    <>
      <Card>
        <FieldSet title={t(ETLCodes.Detail)}>
          <SmallFormGenerator
            initialValues={data}
            onSubmit={saveItem}
            editMode={state}
            saving={saving}
            loading={loading}
            onEdit={onEdit}
            validationSchema={FormSchema}
          >
            <FieldGroup columns={2}>
              <FieldGroup>
                <FGWalterSelectInput
                  label={t(ETLCodes.Plage)}
                  name="idplage"
                  items={plages}
                  loading={pLoading}
                  disabled={data?.hasPresences || data?.hasRemplacements || data?.hasHoraires}
                />
                <FGWalterSelectInput
                  label={t(ETLCodes.Calendrier)}
                  name="idcalendrier"
                  items={calendriers}
                  loading={cLoading}
                  disabled={data?.hasPresences || data?.hasRemplacements || data?.hasHoraires}
                />
                <FGTextInput label={t(ETLCodes.HoraireModifiePar)} name="horaireModificationUserName" readonly />
              </FieldGroup>
              <FieldGroup>
                <FGWalterCheckboxInput label={t(ETLCodes.Coherent)} name="horaireCoherent" readonly />
                <FieldGroup columns={2}>
                  <FGWalterDateMaskInput
                    label={t(ETLCodes.CoursDu)}
                    name="dateCours"
                    readonly
                    showPlaceholder={false}
                  />
                  <FGWalterDateMaskInput label={t(ETLCodes.Au)} name="dateFin" />
                </FieldGroup>
                <FGTextInput label={t(ETLCodes.HoraireModifieLe)} name="horaireModificationDate" readonly />
              </FieldGroup>
            </FieldGroup>
          </SmallFormGenerator>
        </FieldSet>
      </Card>
    </>
  );
};
