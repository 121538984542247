import { Callout, Divider } from "@blueprintjs/core";
import { isAfter } from "date-fns";
import { FGEmpty, FGListen, FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbJustificatifsAbsenceDetailsDtoFromJSON, JustificatifAbsenceApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterMultiCheckboxInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IApprenantJustificatifsDetailProps {}

export const ApprenantJustificatifsDetail: React.FunctionComponent<IApprenantJustificatifsDetailProps> = () => {
  const { t } = useTl();
  const api = useApiService(JustificatifAbsenceApi);
  const history = useHistory();

  const { id, justificatifId, state } = useParams<{ id: string; justificatifId: string; state: string }>();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +justificatifId <= 0
        ? FcbJustificatifsAbsenceDetailsDtoFromJSON({
            justificatifId: 0,
            idapprenant: +id,
            accepte: true,
            dateDebut: new Date(),
            dateFin: new Date(),
            plages: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
            jours: [1, 2, 3, 4, 5, 6, 7]
          })
        : api.justificatifAbsenceGet({ id: +justificatifId }),
    saveApiFn: d => api.justificatifAbsenceSave({ FcbJustificatifsAbsenceDetailsDto: d }),
    onSavedRoute: d => `${ERoutes.apprenant}/${+id}/justificatifs`,
    deleteApiFn: d => api.justificatifAbsenceDelete({ id: +justificatifId }),
    onDeletedRoute: () => `${ERoutes.apprenant}/${+id}/justificatifs`
  });

  const [disabledTypeRequest, setDisabledTypeRequest] = React.useState(false);
  const [disabledAccepteRequest, setDisabledAccepteRequest] = React.useState(false);

  const [idjustificatifGenre, setIdjustificatifGenre] = React.useState<string>(null);
  const [types, tLoading] = useReferential(a => a.referentialGetTypeJustificatifs(), true, []);
  const [genres, gLoading] = useReferential(
    a => a.referentialGetGenreJustificatifs({ currentId: idjustificatifGenre }),
    false,
    [idjustificatifGenre]
  );
  const [centres, cLoading] = useReferential(a => a.referentialGetCentre(), true, []);
  const [plages, pLoading] = useReferential(a => a.referentialGetJustificatifsPlages(), true, [], false);

  const jours = React.useMemo(
    () => [
      {
        label: t(ETLCodes.Lundi),
        value: 2
      },
      {
        label: t(ETLCodes.Mardi),
        value: 3
      },
      {
        label: t(ETLCodes.Mercredi),
        value: 4
      },
      {
        label: t(ETLCodes.Jeudi),
        value: 5
      },
      {
        label: t(ETLCodes.Vendredi),
        value: 6
      },
      {
        label: t(ETLCodes.Samedi),
        value: 7
      },
      {
        label: t(ETLCodes.Dimanche),
        value: 1
      }
    ],
    [t]
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idcentre: Yup.string().required(t(ETLCodes.Required)),
      dateDebut: Yup.date()
        .max(Yup.ref("dateFin"), t(ETLCodes.DateDebutGreaterThanDateFin))
        .required(t(ETLCodes.Required)),
      dateFin: Yup.date()
        .min(Yup.ref("dateDebut"), t(ETLCodes.DateFinGreaterThanDateDebut))
        .required(t(ETLCodes.Required)),
      idjustificatifGenre: Yup.string().required(t(ETLCodes.Required)),
      idjustificatifType: Yup.number().when("idjustificatifGenre", (value, schema) =>
        value === "J" ? schema.required(t(ETLCodes.Required)) : schema
      ),
      dateRemise: Yup.date()
    });
  }, [t]);

  const notStarted = React.useMemo(
    () => !data?.dateDebut || isAfter(data?.dateDebut, new Date()) || +justificatifId <= 0,
    [data?.dateDebut, justificatifId]
  );

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        showColons
        editMode={notStarted && state === "edit"}
        editable={notStarted}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(`${ERoutes.apprenant}/${id}/justificatifs`)}
        customDeleteButtonProps={
          +justificatifId > 0 && {
            onDelete: deleteItem,
            loading: deleting
          }
        }
        saving={saving}
        deleting={deleting}
        formatDate="dd/MM/yyyy"
      >
        {!notStarted && (
          <Callout
            intent="warning"
            title={t(ETLCodes.JustificatifCommenceNonModifiable)}
            style={{ marginBottom: "1rem" }}
            icon="warning-sign"
          />
        )}
        <FGListen
          field="idjustificatifGenre"
          onChanged={(value, formik) => {
            setIdjustificatifGenre(value);
            setDisabledTypeRequest(value !== "J");
            setDisabledAccepteRequest(value === "R");
            if (value !== data.idjustificatifGenre) {
              formik.setFieldValue("accepte", value !== "R" ? formik.values.accepte : false);
              formik.setFieldValue("idjustificatifType", value === "J" ? formik.values.idjustificatifType : undefined);
            }
          }}
        />
        <FGListen
          field="idjustificatifType"
          onChanged={async (value, formik) => {
            if (value !== undefined && value !== data.idjustificatifType) {
              const legit = await api.justificatifAbsenceGetLegalite({ id: value });
              formik.setFieldValue("legitime", legit.value);
              formik.setFieldValue("accepte", legit.value);
            }
          }}
        />
        <FieldGroup columns={2}>
          <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} loading={cLoading} items={centres} />
          <FGWalterSelectInput
            name="idjustificatifGenre"
            label={t(ETLCodes.Categorie)}
            loading={gLoading}
            items={genres}
          />
          <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} />
          <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
          <FGWalterDateMaskInput name="dateRemise" label={t(ETLCodes.Remise)} />
          <FGEmpty />
          <FGWalterSelectInput
            name="idjustificatifType"
            label={t(ETLCodes.Type)}
            loading={tLoading}
            items={types}
            disabled={disabledTypeRequest}
          />
          <FGWalterCheckboxInput readonly name="legitime" label={t(ETLCodes.Legitime)} />
        </FieldGroup>
        <Divider />
        <FieldGroup>
          <FGWalterMultiCheckboxInput
            items={jours}
            loading={pLoading}
            label={t(ETLCodes.Jours)}
            name="jours"
            selectAllLabel={t(ETLCodes.SelectAll)}
            columns={4}
          />
        </FieldGroup>
        <Divider />
        <FieldGroup>
          <FGWalterMultiCheckboxInput
            items={plages}
            loading={pLoading}
            label={t(ETLCodes.Plages)}
            name="plages"
            selectAllLabel={t(ETLCodes.SelectAll)}
            columns={4}
          />
        </FieldGroup>
        <Divider />
        <FieldGroup>
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
          <FGWalterCheckboxInput name="accepte" label={t(ETLCodes.Accepte)} disabled={disabledAccepteRequest} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
