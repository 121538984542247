import { FGMultiSuggestInput, FGTextAreaInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { DispenseApi, FcbDispenseDetailDtoFromJSON } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IDispenseDetailProps {}

export const DispenseDetail: React.FunctionComponent<IDispenseDetailProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, inscriptionId, dispenseId, stateDispense = "view" } = useParams<{
    id: string;
    inscriptionId: string;
    tabInscription: string;
    stateInscription: string;
    dispenseId: string;
    stateDispense: string;
  }>();

  const idApprenant = React.useMemo(() => +id, [id]);
  const idInscription = React.useMemo(() => +inscriptionId, [inscriptionId]);
  const idDispense = React.useMemo(() => +dispenseId, [dispenseId]);

  const api = useApiService(DispenseApi);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idDispense <= 0
        ? FcbDispenseDetailDtoFromJSON({ dispenseId: 0, idinscription: idInscription, classeMatiereIds: [] })
        : api.dispenseGet({ id: +idDispense }),
    saveApiFn: d => api.dispenseSave({ FcbDispenseDetailDto: d }),
    onSavedRoute: d => `${ERoutes.apprenant}/${idApprenant}/inscription/${idInscription}/dispense`,
    deleteApiFn: d => api.dispenseDelete({ id: d.dispenseId }),
    onDeletedRoute: d => `${ERoutes.apprenant}/${idApprenant}/inscription/${idInscription}/dispense`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      classeMatiereIds: Yup.array()
        .of(Yup.number())
        .min(1, t(ETLCodes.SelectAtLeastOne)),
      idtypeDispense: Yup.number().required(t(ETLCodes.Required)),
      dateDemande: Yup.date(),
      dateAcceptation: Yup.date()
    });
  }, [t]);

  const [typeDispense, tdLoading] = useReferential(a => a.referentialGetTypeDispense(), true, [], true);
  const [matiere, mLoading] = useReferential(a => a.referentialGetClasseMatiere({ idInscription }), true, [], true);

  return (
    <FieldSet title={t(ETLCodes.DispenseMatiere)}>
      <SmallFormGenerator
        editable={false}
        initialValues={data}
        onSubmit={saveItem}
        editMode={stateDispense === "edit"}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(`${ERoutes.apprenant}/${idApprenant}/inscription/${idInscription}/dispense`)}
        onDelete={deleteItem}
        showDeleteButton={+idDispense > 0}
        saving={saving}
        deleting={deleting}
        formatDate="dd-MM-yyyy"
        minLabelWidth={200}
      >
        <FieldGroup>
          <FGMultiSuggestInput name="classeMatiereIds" label={t(ETLCodes.Matiere)} items={matiere} loading={mLoading} />
        </FieldGroup>
        <FieldGroup columns={2}>
          <FGWalterDateMaskInput name="dateDemande" label={t(ETLCodes.DateDemande)} />
          <FGWalterDateMaskInput name="dateAcceptation" label={t(ETLCodes.DateAcceptation)} />
        </FieldGroup>
        <FieldGroup columns={2}>
          <FGWalterSelectInput
            name="idtypeDispense"
            label={t(ETLCodes.TypeDispense)}
            items={typeDispense}
            loading={tdLoading}
          />
          {/* {stateDispense === "edit" && <FGWalterCheckboxInput name="applyToAll" label={t(ETLCodes.AppliquerSurTous)} />} */}
        </FieldGroup>
        <FieldGroup>
          <FGTextAreaInput name="libelle" label={t(ETLCodes.Remarque)} />
        </FieldGroup>
      </SmallFormGenerator>
    </FieldSet>
  );
};
