import { Button, Callout, Intent, Spinner, SpinnerSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { addMonths, format, isAfter, isBefore, isSameDay, startOfDay } from "date-fns";
import { FormikProps } from "formik";
import {
  ButtonContainer,
  DataTable,
  FGCustomPanel,
  FGCustomReadOnly,
  FGEmpty,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IDataTableColumn,
  IFGContext,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import {
  ContratAlternanceCommunPanel,
  ContratAlternancePanel,
  ContratConventionStageExternePanel,
  ContratConventionStagePanel,
  ContratDecouverteMetierPanel,
  ContratFaltPanel,
  ContratLieuFormationTuteursGrid,
  ContratPratiqueProfessionnellePanel,
  StageObservationPanel
} from ".";
import {
  CanDisableOffreRequestDtoFromJSON,
  ContratApi,
  ContratApprenantInfosDto,
  ContratEditDto,
  ContratLieuFormationGridDto,
  ContratLieuFormationGridDtoFromJSON,
  ContratLieuFormationTuteurGridDtoFromJSON,
  EIfapmeSide,
  ELevelName,
  EStatutContrat,
  ETypeContrat,
  FichierApi,
  LieuFormationApi,
  ReferentialResultInfoDtoFromJSON,
  TuteurApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AddButton,
  ApprenantSelectorField,
  ContratMotifRuptureJeuneSelect,
  ContratMotifRupturePatronSelect,
  DeleteButton,
  ErrorText,
  FavoriteButton,
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  LieuFormationSelectorDialog,
  LinkButton,
  SmallFormGenerator,
  WarningText
} from "../../../../../components";
import { useDialog } from "../../../../../contexts";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { nameof } from "../../../../../utils";

const StatutContainer = styled.div`
  display: flex;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledCallout = styled(Callout)`
  width: auto !important;
`;

const StatutText = styled.span<{ color: string }>`
  color: ${props => props.color};
  font-weight: bold;
  font-size: 14pt;
  margin-right: 1rem;
`;

const RoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

export interface IContratDetailPageProps {}

export const ContratDetailPage: React.FunctionComponent<IContratDetailPageProps> = props => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const api = useApiService(ContratApi);
  const formikInnerRef = React.useRef<FormikProps<ContratEditDto>>();
  const lieuFormationApi = useApiService(LieuFormationApi);
  const tuteurApi = useApiService(TuteurApi);
  const { theme, ifapmeSide, isHope } = useTheme();
  const [selectorDialogOpen, setSelectorDialogOpen] = React.useState(false);
  const [firstIdLieuFormation, setFirstIdLieuFormation] = React.useState(0);
  const { showDialogPromise } = useDialog();
  const { search } = useLocation();
  const idlieuFormation = React.useMemo(() => new URLSearchParams(search).get("idlieuFormation"), [search]);
  const idtuteur = React.useMemo(() => new URLSearchParams(search).get("idtuteur"), [search]);
  const idapprenant = React.useMemo(() => new URLSearchParams(search).get("idapprenant"), [search]);

  const { id, type, state } = useParams<{ id: string; type: string; state: string }>();
  const typeContrat = React.useMemo(() => type as ETypeContrat, [type]);

  const [idserviceTutelle, setIdserviceTutelle] = React.useState(0);

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999,
    sortKeys: { nom: "ASC" }
  });

  const { setData } = tableState;

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi<ContratEditDto>(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          let lieuxFormation = [];
          if (!!idlieuFormation) {
            const lfData = await lieuFormationApi.lieuFormationGetLieuFormation({ id: +idlieuFormation });
            const lf = ContratLieuFormationGridDtoFromJSON({
              idlieuFormation,
              idsiegeSocial: lfData.idsiegeSocial,
              nom: lfData.nom,
              enseigne: lfData.enseigne,
              numeroUe: lfData.numeroUe,
              localite: lfData.localite,
              capaciteFormativeAtteinte: lfData.capaciteFormativeAtteinte,
              principal: true,
              tuteurs: [],
              actif: lfData.actif
            });
            if (!!idtuteur) {
              const tData = await tuteurApi.tuteurGetTuteurLieuByIds({
                idtuteur: +idtuteur,
                idlieuFormation: +idlieuFormation
              });
              lf.tuteurs = [
                ContratLieuFormationTuteurGridDtoFromJSON({
                  idtuteur,
                  principal: true,
                  actif: tData.actif,
                  nom: tData.nom + " " + tData.prenom,
                  capaciteFormativeAtteinte: tData.capaciteFormativeAtteinte
                })
              ];
            }
            lieuxFormation.push(lf);
          }
          let toReturn = await api.contratGetContrat({ id: +id, typeContrat, idapprenant: +idapprenant });
          if (+id <= 0) {
            toReturn = {
              ...toReturn,
              idmetier: null,
              idserviceTutelle: null,
              dateDebut: null,
              dateFin: null,
              idapprenant: !!idapprenant && +idapprenant,
              lieuxFormation,
              customHeuresHebdo: 0
            };
          }
          return toReturn;
        },
        saveApiFn: async (d: ContratEditDto) => {
          let mustDisableOffre = false;
          if (
            [
              ETypeContrat.CC,
              ETypeContrat.CA,
              ETypeContrat.CS,
              ETypeContrat.CF,
              ETypeContrat.DM,
              ETypeContrat.JI,
              ETypeContrat.PP
            ].includes(d.type)
          ) {
            const mainLf = d.lieuxFormation.find(lf => lf.principal);
            const mainTuteur = mainLf?.tuteurs?.find(t => t.principal);
            if (d.idcontrat <= 0 && !!mainLf && !!mainTuteur) {
              const canDisableOffre = await lieuFormationApi.lieuFormationCheckCanDisableOffre({
                CanDisableOffreRequestDto: CanDisableOffreRequestDtoFromJSON({
                  idlieuFormation: mainLf.idlieuFormation,
                  idmetier: d.idmetier
                })
              });
              if (canDisableOffre) {
                mustDisableOffre =
                  (await showDialogPromise({
                    title: t(ETLCodes.MaintenirOffreTitle),
                    message: t(ETLCodes.MaintenirOffreMessage)
                  })) === "no";
              }
            }
          }
          d.mustDisableOffre = mustDisableOffre;
          return api.contratSaveContrat({ ContratEditDto: d });
        },
        onSavedRoute: d => `${ERoutes.contrat}/${d.idcontrat}/detail/${type}/${state}`
      }),
      [
        api,
        id,
        idapprenant,
        idlieuFormation,
        idtuteur,
        lieuFormationApi,
        showDialogPromise,
        state,
        t,
        tuteurApi,
        type,
        typeContrat
      ]
    )
  );

  const mode = React.useMemo(
    () =>
      [
        ETypeContrat.CC,
        ETypeContrat.CA,
        ETypeContrat.CS,
        ETypeContrat.CF,
        ETypeContrat.DM,
        ETypeContrat.JI,
        ETypeContrat.PP
      ].includes(typeContrat)
        ? "hope"
        : "walter",
    [typeContrat]
  );

  const softRO = React.useMemo(() => [EStatutContrat.Rompu].includes(data?.statut), [data?.statut]);
  const fullRO = React.useMemo(
    () =>
      [EStatutContrat.SansSuite].includes(data?.statut) ||
      ifapmeSide !== mode ||
      [ETypeContrat.CA, ETypeContrat.JI].includes(typeContrat),
    [data?.statut, ifapmeSide, mode, typeContrat]
  );

  const editMode = React.useMemo(() => state === "edit" && !fullRO, [fullRO, state]);

  React.useEffect(() => {
    if (!!data) {
      setData(data.lieuxFormation);
      if (data.lieuxFormation.length > 0) {
        setFirstIdLieuFormation(data.lieuxFormation[0].idlieuFormation);
      }
    }
  }, [data, setData]);

  const fetchIdSiegeSocial = React.useCallback(() => {
    if (firstIdLieuFormation <= 0) return null;
    return lieuFormationApi.lieuFormationGetIdSiegeSocial({ id: firstIdLieuFormation });
  }, [firstIdLieuFormation, lieuFormationApi]);
  const { data: idsiegeSocial } = useQuery(
    ["idsiegesocial-lieuformation-contrat", firstIdLieuFormation],
    fetchIdSiegeSocial
  );

  const [serviceTutelle, loadingServiceTutelle] = useReferential(
    a =>
      a.referentialGetServiceTutelle({
        userFilter: mode === "hope",
        ifapmeSide: mode === "walter" ? EIfapmeSide.Walter : EIfapmeSide.Hope,
        currentId: idserviceTutelle
      }),
    true,
    [idserviceTutelle]
  );

  const [idapprenantForm, setIdapprenant] = React.useState(0);
  const [classes, cLoading] = useReferential(
    a =>
      idapprenantForm > 0
        ? a.referentialGetClasseForContrat({ idapprenant: idapprenantForm })
        : Promise.resolve(ReferentialResultInfoDtoFromJSON({ list: [] })),
    true,
    [idapprenantForm]
  );

  const [metiers, mLoading] = useReferential(
    a =>
      a.referentialMetierByStadeForContrat({
        idStade: formikInnerRef.current?.values?.idstade,
        currentId: formikInnerRef.current?.values?.idmetier
      }),
    false,
    [formikInnerRef.current?.values?.idstade, formikInnerRef.current?.values?.idmetier]
  );

  const codeRO = React.useMemo(() => mode === "hope" || typeContrat === ETypeContrat.SO || +id > 0, [
    id,
    mode,
    typeContrat
  ]);

  const hopeFormSchema = React.useMemo(() => {
    let subContratSchema = {};
    switch (typeContrat) {
      case ETypeContrat.CC:
      case ETypeContrat.CA:
        subContratSchema = {
          iddureeContrat: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required)),
          idmetier: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required)),
          idcommissionParitaire: Yup.number().nullable(),
          idheureHebdoPossibleCommissionParitaire: Yup.number()
            .nullable()
            .min(0, t(ETLCodes.CannotBeNegative))
            .when("customHeuresHebdo", {
              is: val => val === null || val === undefined,
              then: Yup.number()
                .nullable()
                .min(0, t(ETLCodes.CannotBeNegative))
                .required(t(ETLCodes.Required))
            }),
          customHeuresHebdo: Yup.number()
            .nullable()
            .min(0, t(ETLCodes.CannotBeNegative))
            .when("idheureHebdoPossibleCommissionParitaire", {
              is: val => val === null || val === undefined,
              then: Yup.number()
                .nullable()
                .min(0, t(ETLCodes.CannotBeNegative))
                .required(t(ETLCodes.Required))
            }),
          dateSignature: Yup.date(),
          contratAlternanceDto: Yup.object()
            .nullable()
            .shape({
              datePassageB: Yup.date(),
              datePassageC: Yup.date()
            })
        };
        if (!data?.idariane) {
          subContratSchema = {
            ...subContratSchema,
            baremes: Yup.array()
              .nullable()
              .of(
                Yup.object().shape({
                  remunReelle: Yup.number()
                    .nullable()
                    .min(Yup.ref("remunTheorique"), t(ETLCodes.CannotBeUnderBareme))
                })
              )
          };
        }
        break;
      case ETypeContrat.CS:
        subContratSchema = {
          iddureeContrat: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required)),
          idcommissionParitaire: Yup.number().nullable(),
          idmetier: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required)),
          contratConventionStageDto: Yup.object()
            .nullable()
            .shape({
              dateValidation: Yup.date(),
              dateRemise: Yup.date()
            })
        };
        if (!data?.idariane) {
          subContratSchema = {
            ...subContratSchema,
            baremes: Yup.array()
              .nullable()
              .of(
                Yup.object().shape({
                  remunReelle: Yup.number()
                    .nullable()
                    .min(Yup.ref("remunTheorique"), t(ETLCodes.CannotBeUnderBareme))
                })
              )
          };
        }
        break;
      case ETypeContrat.PP:
        subContratSchema = {
          idmetier: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required)),
          idcommissionParitaire: Yup.number().nullable(),
          idheureHebdoPossibleCommissionParitaire: Yup.number()
            .nullable()
            .min(0, t(ETLCodes.CannotBeNegative))
        };
        break;
      case ETypeContrat.CF:
        subContratSchema = {
          idmetier: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required)),
          idcommissionParitaire: Yup.number().nullable(),
          idheureHebdoPossibleCommissionParitaire: Yup.number()
            .nullable()
            .min(0, t(ETLCodes.CannotBeNegative)),
          contratFaltDto: Yup.object()
            .nullable()
            .shape({
              pcFormationEmployeur: Yup.number()
                .nullable()
                .min(0, t(ETLCodes.CannotBeNegative))
                .max(100, t(ETLCodes.CannotBeGreaterThan, { amount: 100 })),
              pcFormationOperateur: Yup.number()
                .nullable()
                .min(0, t(ETLCodes.CannotBeNegative))
                .max(100, t(ETLCodes.CannotBeGreaterThan, { amount: 100 })),
              interventionFinanciere: Yup.number()
                .nullable()
                .min(0, t(ETLCodes.CannotBeNegative))
            })
        };
        break;
      case ETypeContrat.DM:
      case ETypeContrat.JI:
        subContratSchema = {
          idmetier: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required)),
          decouverteMetierDto: Yup.object()
            .nullable()
            .shape({
              dateTransmission: Yup.date()
            })
        };
        break;
      case ETypeContrat.CCE:
      case ETypeContrat.CSE:
        subContratSchema = {
          code: Yup.string()
            .nullable()
            .required(t(ETLCodes.Required))
        };
        break;
    }
    return Yup.object().shape(
      {
        idserviceTutelle: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idpri: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idari: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        dateDebut: Yup.date()
          .nullable()
          .when("dateFin", (value, schema) =>
            !!value ? schema.max(Yup.ref("dateFin"), t(ETLCodes.DateDebutGreaterThanDateFin)) : schema
          )
          .required(t(ETLCodes.Required)),
        dateFin: Yup.date()
          .nullable()
          .when("dateDebut", (value, schema) =>
            !!value ? schema.min(Yup.ref("dateDebut"), t(ETLCodes.DateFinGreaterThanDateDebut)) : schema
          )
          .required(t(ETLCodes.Required)),
        rompuLe: Yup.date()
          .nullable()
          .min(Yup.ref("dateDebut"), t(ETLCodes.MustBeWithinContractPeriod))
          .max(Yup.ref("dateFin"), t(ETLCodes.MustBeWithinContractPeriod)),
        datePriseActe: Yup.date()
          .nullable()
          .min(Yup.ref("rompuLe"), t(ETLCodes.MustBeAfterRupture)),
        ...subContratSchema
      },
      [
        ["dateDebut", "dateFin"],
        ["idheureHebdoPossibleCommissionParitaire", "customHeuresHebdo"]
      ]
    );
  }, [data?.idariane, t, typeContrat]);

  const walterFormSchema = React.useMemo(() => {
    let subContratSchema = {};
    switch (typeContrat) {
      case ETypeContrat.SO:
        subContratSchema = {
          idclasse: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required)),
          contratStageObservationDto: Yup.object()
            .nullable()
            .shape({
              siegeSocial: Yup.object()
                .nullable()
                .shape({
                  numeroBce: Yup.number().required(t(ETLCodes.Required))
                  // .test("bce-validity", t(ETLCodes.InvalidBCE), async function(value) {
                  //   return !value || bceNumberValidate(value.toString());
                  // })
                })
            })
        };
        break;
      case ETypeContrat.CCE:
        subContratSchema = {
          code: Yup.string()
            .nullable()
            .required(t(ETLCodes.Required)),
          idclasse: Yup.number().nullable(),
          rompuLe: Yup.date()
            .nullable()
            .min(Yup.ref("dateDebut"), t(ETLCodes.MustBeWithinContractPeriod))
            .max(Yup.ref("dateFin"), t(ETLCodes.MustBeWithinContractPeriod)),
          contratAlternanceCommunDto: Yup.object()
            .nullable()
            .shape({
              email: Yup.string()
                .nullable()
                .email(t(ETLCodes.InvalidEmail)),
              idcontratStatutWalter: Yup.string()
                .nullable()
                .required(t(ETLCodes.Required))
            })
        };
        break;
      case ETypeContrat.CSE:
        subContratSchema = {
          code: Yup.string()
            .nullable()
            .required(t(ETLCodes.Required)),
          idclasse: Yup.number().nullable(),
          rompuLe: Yup.date()
            .nullable()
            .min(Yup.ref("dateDebut"), t(ETLCodes.MustBeWithinContractPeriod))
            .max(Yup.ref("dateFin"), t(ETLCodes.MustBeWithinContractPeriod)),
          contratConventionStageExterneDto: Yup.object()
            .nullable()
            .shape({
              email: Yup.string()
                .nullable()
                .email(t(ETLCodes.InvalidEmail)),
              idcontratStatutWalter: Yup.string()
                .nullable()
                .required(t(ETLCodes.Required))
            })
        };
        break;
    }
    return Yup.object().shape(
      {
        idapprenant: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        dateDebut: Yup.date()
          .nullable()
          .when("dateFin", (value, schema) =>
            !!value ? schema.max(Yup.ref("dateFin"), t(ETLCodes.DateDebutGreaterThanDateFin)) : schema
          )
          .required(t(ETLCodes.Required)),
        dateFin: Yup.date()
          .nullable()
          .when("dateFin", (value, schema) =>
            !!value ? schema.min(Yup.ref("dateDebut"), t(ETLCodes.DateFinGreaterThanDateDebut)) : schema
          )
          .required(t(ETLCodes.Required)),
        ...subContratSchema
      },
      [["dateFin", "dateFin"]]
    );
  }, [t, typeContrat]);

  const statutColor = React.useMemo(() => {
    if (!data) return "white";
    switch (data.statut) {
      case EStatutContrat.EnCours:
      case EStatutContrat.Termine:
        return theme.sucessColor;
      case EStatutContrat.NonDemarre:
      case EStatutContrat.Suspendu:
        return theme.warningColor;
      case EStatutContrat.Rompu:
      case EStatutContrat.SansSuite:
        return theme.dangerColor;
    }
  }, [data, theme.dangerColor, theme.sucessColor, theme.warningColor]);

  const marquerSansSuite = React.useCallback(
    async (ctx: IFGContext) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.MarquerSansSuiteConfirmMessage),
        title: t(ETLCodes.MarquerSansSuiteConfirm)
      });

      if (result === "yes") {
        ctx.formik.setFieldValue("marqueSansSuiteLe", new Date());
        ctx.formik.submitForm();
      }
    },
    [showDialogPromise, t]
  );

  const removeLieuFormation = React.useCallback(
    (row: ContratLieuFormationGridDto) => {
      let nextValues = [...formikInnerRef.current?.values?.lieuxFormation];
      nextValues.splice(nextValues.indexOf(row), 1);
      formikInnerRef.current.setFieldValue(`lieuxFormation`, nextValues);
      formikInnerRef.current.setFieldTouched(`lieuxFormation`, true);
      setData(nextValues);

      if (nextValues.length === 0) {
        setFirstIdLieuFormation(null);
      }
    },
    [setData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        autoFitContent: true,
        fieldName: "_toggle",
        render: (item: ContratLieuFormationGridDto, _, { subPanelOpened, toggleSubPanel }) => {
          return <Button icon={subPanelOpened ? "minus" : "plus"} onClick={() => toggleSubPanel()} />;
        }
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratLieuFormationGridDto) => (
          <ButtonContainer>
            <FavoriteButton
              disabled={softRO || fullRO}
              onClick={() => {
                let nextValues = [...formikInnerRef.current?.values?.lieuxFormation];
                const principalValue = !row.principal;
                nextValues.forEach(v => {
                  if (v.idlieuFormation === row.idlieuFormation) {
                    v.principal = !v.principal;
                    if (!v.principal) {
                      v.tuteurs.forEach(t => (t.principal = false));
                    }
                  } else if (principalValue) {
                    v.principal = false;
                    v.tuteurs.forEach(t => (t.principal = false));
                  }
                });
                formikInnerRef.current.setFieldValue("lieuxFormation", nextValues);
                formikInnerRef.current.setFieldTouched("lieuxFormation", true);
                setData(nextValues);
              }}
              selected={row.principal}
            />
            <DeleteButton minimal onDelete={() => removeLieuFormation(row)} disabled={softRO || fullRO} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.LieuFormation),
        fieldName: "nom",
        render: (row: ContratLieuFormationGridDto, index: number) => {
          return (
            <>
              <LinkButton
                text={row.nom}
                onClick={() =>
                  window.open(
                    `#${ERoutes.lieuFormation}/${row.idlieuFormation}/detail/view?idsiegeSocial=${row.idsiegeSocial}`
                  )
                }
              />
              {row.tuteurs.some(t => !t.actif || t.capaciteFormativeAtteinte) && (
                <WarningText text={t(ETLCodes.VeuillezVerifierLesTuteursDeCeContrat)} />
              )}
              {row.capaciteFormativeAtteinte && (
                <WarningText text={t(ETLCodes.LieuFormationCapaciteFormativeAtteinte)} />
              )}
              {!row.actif && <WarningText text={t(ETLCodes.LieuFormationInactif)} />}
              {+id > 0 && data?.capaciteFormativeAgrementAtteinte && (
                <WarningText text={t(ETLCodes.LieuFormationAgrementCapaciteFormativeAtteinte)} />
              )}
              {validationErrors?.[`lieuxFormation`]?.length > 0 && (
                <ErrorText text={validationErrors?.[`lieuxFormation`][0]} />
              )}
              {validationErrors?.[`lieuxFormation[${index}]`]?.length > 0 && (
                <ErrorText text={validationErrors?.[`lieuxFormation[${index}]`][0]} />
              )}
              {validationErrors?.[`lieuxFormation[${index}].tuteurs`]?.length > 0 && (
                <ErrorText text={t(ETLCodes.VeuillezVerifierLesTuteursDeCeContrat)} />
              )}
            </>
          );
        }
      },
      {
        header: () => t(ETLCodes.Enseigne),
        fieldName: "enseigne"
      },
      {
        header: () => t(ETLCodes.NumeroUe),
        fieldName: "numeroUe"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      }
    ],
    [data?.capaciteFormativeAgrementAtteinte, fullRO, id, removeLieuFormation, setData, softRO, t, validationErrors]
  );

  const [idpri, setIdpri] = React.useState(0);
  const [users, usersLoading, , rawUsers] = useReferential(
    a =>
      a.referentialGetUsersByRoles({
        ELevelName: [ELevelName.RI, ELevelName.ARI, ELevelName.CALC],
        currentIduser: idpri
      }),
    false,
    [idpri]
  );

  const [idari, setIdari] = React.useState(0);
  const [usersAri, usersAriLoading] = useReferential(
    a => a.referentialGetUsersByRoles({ ELevelName: [ELevelName.ARI], currentIduser: idari }),
    false,
    [idari]
  );

  const [dispensesChomage, dcLoading] = useReferential(a => a.referentialGetDispensesChomage());
  const [operateursFormation, ofLoading] = useReferential(a => a.referentialGetOperateursFormation());

  const [initiateurs, iLoading] = useReferential(a => a.referentialGetContratInitiateurs());

  const [usersRupture, uLoading] = useReferential(a =>
    a.referentialGetUsersByRolesAndApprenant({ ELevelName: [ELevelName.RI, ELevelName.CALC] })
  );

  const onClosed = React.useCallback(
    async (selectedLieuFormationIds?: number[]) => {
      if (!!selectedLieuFormationIds && selectedLieuFormationIds.length > 0) {
        const selectedLieuFormationId = selectedLieuFormationIds[0];
        let nextValues = [...formikInnerRef.current?.values?.lieuxFormation];
        if (nextValues.every(v => v.idlieuFormation !== selectedLieuFormationId)) {
          const lf = await lieuFormationApi.lieuFormationGetLieuFormation({ id: selectedLieuFormationId });
          nextValues.push(
            ContratLieuFormationGridDtoFromJSON({
              nom: lf.nom,
              idsiegeSocial: lf.idsiegeSocial,
              enseigne: lf.enseigne,
              numeroUe: lf.numeroUe,
              localite: lf.localite,
              capaciteFormativeAtteinte: lf.capaciteFormativeAtteinte,
              idlieuFormation: selectedLieuFormationId,
              principal: nextValues.length === 0,
              tuteurs: []
            })
          );
          formikInnerRef.current.setFieldValue("lieuxFormation", nextValues);
          formikInnerRef.current.setFieldTouched("lieuxFormation", true);
          setData(nextValues);
        }
        if (!firstIdLieuFormation) setFirstIdLieuFormation(selectedLieuFormationId);
      }
      setSelectorDialogOpen(false);
    },
    [firstIdLieuFormation, lieuFormationApi, setData]
  );

  const openLieuFormationSelector = React.useCallback(e => {
    e.stopPropagation();
    setSelectorDialogOpen(true);
  }, []);

  const isDispenseChomageVisible = React.useMemo(
    () => [ETypeContrat.CC, ETypeContrat.PP, ETypeContrat.CS].includes(typeContrat),
    [typeContrat]
  );

  const isAriApprenant = React.useCallback(
    (idpri: string) => {
      if (!idpri || !rawUsers) return false;

      const found = rawUsers.find(u => +u.idValue === +idpri);
      if (!found) return false;

      return found.keyValue === ELevelName.ARI;
    },
    [rawUsers]
  );

  const lieuxFormationTitle = React.useMemo(
    () => (
      <TitleContainer>
        <div>{t(ETLCodes.LieuxFormations)}</div>
        {validationErrors?.lieuxFormation?.length > 0 && (
          <StyledCallout intent="danger" title={t(ETLCodes.VeuillezVerifierLesLieuxFormationDeCeContrat)} />
        )}
      </TitleContainer>
    ),
    [t, validationErrors.lieuxFormation]
  );

  const typeContratDtoKey = React.useMemo(() => {
    switch (typeContrat) {
      case ETypeContrat.CC:
      case ETypeContrat.CA:
        return nameof<ContratEditDto>("contratAlternanceDto");
      case ETypeContrat.PP:
        return nameof<ContratEditDto>("pratiqueProfessionnelleDto");
      case ETypeContrat.CS:
        return nameof<ContratEditDto>("contratConventionStageDto");
      case ETypeContrat.CF:
        return nameof<ContratEditDto>("contratFaltDto");
      case ETypeContrat.DM:
      case ETypeContrat.JI:
        return nameof<ContratEditDto>("decouverteMetierDto");
      case ETypeContrat.SO:
        return nameof<ContratEditDto>("contratStageObservationDto");
      case ETypeContrat.CCE:
        return nameof<ContratEditDto>("contratAlternanceCommunDto");
      case ETypeContrat.CSE:
        return nameof<ContratEditDto>("contratConventionStageExterneDto");
      default:
        throw new Error("DTO key for this typeContrat is not implemented");
    }
  }, [typeContrat]);

  const displayOperateurFormation = React.useMemo(() => typeContrat === ETypeContrat.CF, [typeContrat]);

  const cancelPath = React.useMemo(
    () =>
      !!idtuteur
        ? `${ERoutes.tuteur}/${idtuteur}/contrat/${state}`
        : !!idlieuFormation
        ? `${ERoutes.lieuFormation}/${idlieuFormation}/contrat/${state}?idsiegeSocial=${idsiegeSocial}`
        : !!idapprenant
        ? `${ERoutes.apprenant}/${idapprenant}/contrats/${state}`
        : ERoutes.contrat,
    [idapprenant, idlieuFormation, idsiegeSocial, idtuteur, state]
  );

  const [apprenantLoading, setApprenantLoading] = React.useState(false);
  const [apprenantInfos, setApprenantInfos] = React.useState<ContratApprenantInfosDto>(null);
  const idapprenantChanged = React.useCallback(
    async (value: number, formik: FormikProps<ContratEditDto>) => {
      setIdapprenant(value);
      if (!value) {
        setApprenantInfos(null);
        return;
      }
      setClasseInfosRequest({ idclasse: formik.values.idclasse, idapprenant: +value });
      setApprenantLoading(true);
      const apprenant = await api.contratGetApprenantInfos({ idapprenant: +value });
      setApprenantInfos(apprenant);
      setApprenantLoading(false);

      if (!formik.dirty) return;

      if (mode === "hope") {
        if (formik.values.idpri !== apprenant.idpri) {
          formik.setFieldValue("idpri", apprenant.idpri);
        }
        if (formik.values.idari !== apprenant.idari) {
          formik.setFieldValue("idari", apprenant.idari);
        }
        if (formik.values.apprenantStatut !== apprenant.apprenantStatut) {
          formik.setFieldValue("apprenantStatut", apprenant.apprenantStatut);
        }
      }
    },
    [api, mode]
  );

  const [classeInfosRequest, setClasseInfosRequest] = React.useState<{ idclasse: number; idapprenant: number }>(null);
  const fetchClasseInfos = React.useCallback(() => {
    if (!classeInfosRequest?.idclasse || !classeInfosRequest?.idapprenant) return null;

    return api.contratGetClasseInfos(classeInfosRequest);
  }, [api, classeInfosRequest]);
  const { data: classeInfos, isFetching: classeInfosLoading } = useQuery(
    ["contrat-classe-infos", classeInfosRequest?.idclasse, classeInfosRequest?.idapprenant],
    fetchClasseInfos
  );

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async idfichier => await fapi.fichierDownload({ id: idfichier }), [fapi]);

  const marquerSansSuiteButton = React.useCallback(
    (ctx: IFGContext) => {
      const displayButton =
        ctx?.formik?.values?.statut === EStatutContrat.NonDemarre ||
        (ctx?.formik?.values?.statut === EStatutContrat.EnCours &&
          [ETypeContrat.CC, ETypeContrat.CS, ETypeContrat.DM, ETypeContrat.PP, ETypeContrat.CF].includes(typeContrat) &&
          (isSameDay(ctx?.formik?.values?.dateDebut, addMonths(startOfDay(new Date()), -1)) ||
            isAfter(ctx?.formik?.values?.dateDebut, addMonths(startOfDay(new Date()), -1))) &&
          (isSameDay(ctx?.formik?.values?.dateDebut, startOfDay(new Date())) ||
            isBefore(ctx?.formik?.values?.dateDebut, startOfDay(new Date()))));

      return (
        displayButton && (
          <Button
            icon={IconNames.BUILD}
            onClick={() => marquerSansSuite(ctx)}
            text={t(ETLCodes.MarquerSansSuite)}
            intent={Intent.DANGER}
            loading={loading}
          />
        )
      );
    },

    [loading, marquerSansSuite, t, typeContrat]
  );
  return (
    <>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        editable={!fullRO}
        validationSchema={mode === "hope" ? hopeFormSchema : walterFormSchema}
        loading={loading}
        onCancel={() => history.push(cancelPath)}
        saving={saving}
        validationErrors={validationErrors}
        showDeleteButton={false}
        formikInnerRef={formikInnerRef}
        forceEnableSave
        minLabelWidth={265}
        watchChanges={{
          idapprenant: idapprenantChanged,
          idserviceTutelle: setIdserviceTutelle,
          idpri: setIdpri,
          idari: setIdari,
          idclasse: (value, formik) =>
            !!value && setClasseInfosRequest({ idclasse: +value, idapprenant: formik.values.idapprenant }),
          fichierRupture: (value, formik) => {
            if (formik.dirty) {
              formik.setFieldValue("idfichierRupture", null);
            }
          }
        }}
      >
        <FGCustomPanel>
          {ctx =>
            ctx.formik?.values?.lieuxFormation?.some(lf => lf.tuteurs.some(t => !t.actif)) && (
              <Callout
                intent="warning"
                title={t(ETLCodes.ContratTuteurInactifARemplacer)}
                style={{ marginBottom: "1rem" }}
                icon="warning-sign"
              />
            )
          }
        </FGCustomPanel>

        <FieldGroup
          columns={2}
          fieldsetProps={{
            title: t(ETLCodes.Generalites),
            rightElement: +id > 0 && (
              <FGCustomPanel>
                {ctx => (
                  <StatutContainer>
                    <StatutText color={statutColor}>
                      {tUnsafe(`StatutContrat_${ctx?.formik?.values?.statut}`)}
                    </StatutText>
                    {marquerSansSuiteButton(ctx)}
                  </StatutContainer>
                )}
              </FGCustomPanel>
            )
          }}
        >
          <FieldGroup>
            <FGTextInput name="type" label={t(ETLCodes.Type)} readonly />
            <FGTextInput
              name="code"
              label={t(ETLCodes.Code)}
              readonly={codeRO}
              formatReadOnlyValue={value => (+id <= 0 ? t(ETLCodes.CalculeApresCreation) : value)}
              requiredMark={!codeRO}
            />
            <FGCustomPanel>
              {ctx => (
                <ApprenantSelectorField
                  label={t(ETLCodes.Apprenant)}
                  name="idapprenant"
                  parentApprenantIds={ctx.formik.values.idapprenant ? [ctx.formik.values.idapprenant] : []}
                  readonly={softRO}
                  initialSearch={false}
                />
              )}
            </FGCustomPanel>
            <FGTextInput
              name="apprenantStatut"
              label={t(ETLCodes.StatutApprenant)}
              loading={apprenantLoading}
              readonly
              visible={mode === "hope"}
            />
            <FGWalterSelectInput
              name="contratFaltDto.idoperateurFormation"
              label={t(ETLCodes.OperateurFormation)}
              items={operateursFormation}
              loading={ofLoading}
              readonly={softRO}
              visible={mode === "hope" && displayOperateurFormation}
            />
            {mode === "walter" && (
              <>
                <FGCustomReadOnly label={t(ETLCodes.DateNaissance)}>
                  {() =>
                    apprenantLoading ? (
                      <Spinner size={SpinnerSize.SMALL} />
                    ) : (
                      <RoContainer>
                        {apprenantInfos?.dateNaissance && format(apprenantInfos?.dateNaissance, "dd/MM/yyyy")}
                      </RoContainer>
                    )
                  }
                </FGCustomReadOnly>
                <FGCustomReadOnly label={t(ETLCodes.Adresse)}>
                  {() =>
                    apprenantLoading ? (
                      <Spinner size={SpinnerSize.SMALL} />
                    ) : (
                      <RoContainer>{apprenantInfos?.adresse?.adresse}</RoContainer>
                    )
                  }
                </FGCustomReadOnly>
                <FGCustomReadOnly label={t(ETLCodes.CodePostal)}>
                  {() =>
                    apprenantLoading ? (
                      <Spinner size={SpinnerSize.SMALL} />
                    ) : (
                      <RoContainer>{apprenantInfos?.adresse?.codePostal}</RoContainer>
                    )
                  }
                </FGCustomReadOnly>
                <FGCustomReadOnly label={t(ETLCodes.Localite)}>
                  {() =>
                    apprenantLoading ? (
                      <Spinner size={SpinnerSize.SMALL} />
                    ) : (
                      <RoContainer>{apprenantInfos?.adresse?.localite}</RoContainer>
                    )
                  }
                </FGCustomReadOnly>
                <FGCustomReadOnly label={t(ETLCodes.Commune)}>
                  {() =>
                    apprenantLoading ? (
                      <Spinner size={SpinnerSize.SMALL} />
                    ) : (
                      <RoContainer>{apprenantInfos?.adresse?.commune}</RoContainer>
                    )
                  }
                </FGCustomReadOnly>
              </>
            )}
          </FieldGroup>
          {isDispenseChomageVisible && (
            <FieldGroup>
              <FGEmpty />
              <FGEmpty />
              <FGEmpty />
              <FGWalterSelectInput
                name={`${typeContratDtoKey}.iddispenseChomage`}
                label={t(ETLCodes.DispenseChomage)}
                items={dispensesChomage}
                loading={dcLoading}
                readonly={softRO}
              />
            </FieldGroup>
          )}
        </FieldGroup>
        <FieldGroup columns={2} visible={mode === "hope"}>
          <FieldGroup>
            <FGWalterSelectInput
              label={t(ETLCodes.Service)}
              name="idserviceTutelle"
              items={serviceTutelle}
              loading={loadingServiceTutelle}
              readonly={+id > 0 || softRO || serviceTutelle?.length === 1}
              autoSelectIfOne
            />
            <FGCustomPanel>
              {ctx => (
                <>
                  <FGWalterSelectInput
                    name="idpri"
                    label={t(ETLCodes.PersonneReferenceIfapme)}
                    items={users}
                    loading={usersLoading || apprenantLoading}
                    helperText={ctx =>
                      !!ctx?.formik?.values?.idpri &&
                      isAriApprenant(ctx?.formik?.values?.idpri) &&
                      t(ETLCodes.UserIsNotRiOrCalc)
                    }
                    readonly={softRO || !ctx?.formik?.values?.idapprenant}
                  />
                  <FGWalterSelectInput
                    name="idari"
                    label={t(ETLCodes.ResponsableAdministratif)}
                    items={usersAri}
                    loading={usersAriLoading || apprenantLoading}
                    readonly={softRO || !ctx?.formik?.values?.idapprenant}
                  />
                </>
              )}
            </FGCustomPanel>
          </FieldGroup>
        </FieldGroup>

        <FieldGroup columns={2} visible={mode === "walter"}>
          <FieldGroup>
            <FGWalterSelectInput
              name="idclasse"
              label={t(ETLCodes.Classe)}
              items={classes}
              loading={cLoading}
              disabled={!idapprenantForm}
            />
            <FGCustomReadOnly label={t(ETLCodes.Centre)}>
              {() =>
                classeInfosLoading ? (
                  <Spinner size={SpinnerSize.SMALL} />
                ) : (
                  <RoContainer>{classeInfos?.centre}</RoContainer>
                )
              }
            </FGCustomReadOnly>
            <FGCustomReadOnly label={t(ETLCodes.Statut)}>
              {() =>
                classeInfosLoading ? (
                  <Spinner size={SpinnerSize.SMALL} />
                ) : (
                  <RoContainer>{classeInfos?.statutInscription}</RoContainer>
                )
              }
            </FGCustomReadOnly>
            <FGWalterSelectInput
              name="idmetier"
              label={t(ETLCodes.Metier)}
              items={metiers}
              loading={mLoading}
              readonly={softRO}
              visible={typeContrat === ETypeContrat.SO}
            />
            <FGWalterSelectInput
              label={t(ETLCodes.Service)}
              name="idserviceTutelle"
              items={serviceTutelle}
              loading={loadingServiceTutelle}
              readonly={+id > 0 || softRO || serviceTutelle?.length === 1}
              autoSelectIfOne
              visible={[ETypeContrat.CCE, ETypeContrat.CSE].includes(typeContrat)}
            />
          </FieldGroup>
        </FieldGroup>

        <FieldGroup
          visible={mode === "hope"}
          collapsable
          fieldsetProps={{
            title: lieuxFormationTitle,
            rightElement: !softRO && !fullRO && (
              <AddButton
                onClick={openLieuFormationSelector}
                text={t(ETLCodes.AddLieuFormation)}
                intent={Intent.PRIMARY}
              />
            )
          }}
        >
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            keyFieldName="idcontratLieuFormation"
            defaultExpandSubComponents={true}
            onOpenSubComponent={(item: ContratLieuFormationGridDto) => (
              <ContratLieuFormationTuteursGrid
                idsiegeSocial={item.idsiegeSocial}
                idlieuFormation={item.idlieuFormation}
                lieuPrincipal={item.principal}
                readonly={fullRO || softRO}
              ></ContratLieuFormationTuteursGrid>
            )}
          />
          <FGCustomPanel>
            {ctx => (
              <LieuFormationSelectorDialog
                onClosed={onClosed}
                onLieuFormationSelected={onClosed}
                selectorDialogOpen={selectorDialogOpen}
                idsiegeSocial={idsiegeSocial}
                excludedLieuFormationIds={ctx?.formik?.values?.lieuxFormation?.map(l => l.idlieuFormation) ?? []}
                sppMode={typeContrat === ETypeContrat.PP}
              />
            )}
          </FGCustomPanel>
        </FieldGroup>

        {mode === "hope" ? (
          <Switch>
            <Route path={`${ERoutes.contrat}/:id/detail/CC/:state?`} exact>
              <ContratAlternancePanel typeContrat={typeContrat} idsiegeSocial={idsiegeSocial} />
            </Route>
            <Route path={`${ERoutes.contrat}/:id/detail/CA/:state?`} exact>
              <ContratAlternancePanel typeContrat={typeContrat} idsiegeSocial={idsiegeSocial} readonly />
            </Route>
            <Route path={`${ERoutes.contrat}/:id/detail/CS/:state?`} exact>
              <ContratConventionStagePanel typeContrat={typeContrat} idsiegeSocial={idsiegeSocial} />
            </Route>
            <Route path={`${ERoutes.contrat}/:id/detail/PP/:state?`} exact>
              <ContratPratiqueProfessionnellePanel typeContrat={typeContrat} idsiegeSocial={idsiegeSocial} />
            </Route>
            <Route path={`${ERoutes.contrat}/:id/detail/CF/:state?`} exact>
              <ContratFaltPanel typeContrat={typeContrat} idsiegeSocial={idsiegeSocial} />
            </Route>
            <Route path={`${ERoutes.contrat}/:id/detail/DM/:state?`} exact>
              <ContratDecouverteMetierPanel typeContrat={typeContrat} />
            </Route>
            <Route path={`${ERoutes.contrat}/:id/detail/JI/:state?`} exact>
              <ContratDecouverteMetierPanel typeContrat={typeContrat} readonly />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path={`${ERoutes.contrat}/:id/detail/SO/:state?`} exact>
              <StageObservationPanel />
            </Route>
            <Route path={`${ERoutes.contrat}/:id/detail/CCE/:state?`} exact>
              <ContratAlternanceCommunPanel metiers={metiers} mLoading={mLoading} />
            </Route>
            <Route path={`${ERoutes.contrat}/:id/detail/CSE/:state?`} exact>
              <ContratConventionStageExternePanel metiers={metiers} mLoading={mLoading} />
            </Route>
          </Switch>
        )}

        <FieldGroup fieldsetProps={{ title: t(ETLCodes.Rupture) }} columns={2} collapsable visible={mode === "hope"}>
          <FieldGroup>
            <FGWalterDateMaskInput name="rompuLe" label={t(ETLCodes.DateRupture)} />
            <FGWalterSelectInput
              name="idcontratInitiateurRupture"
              label={t(ETLCodes.Initiateur)}
              items={initiateurs}
              loading={iLoading}
            />
            <ContratMotifRupturePatronSelect name="idcontratMotifRupturePatron" />
            <ContratMotifRuptureJeuneSelect name="idcontratMotifRuptureJeune" />
            <FGCustomPanel>
              {ctx => (
                <>
                  <FGWalterDateMaskInput
                    name="datePriseActe"
                    label={t(ETLCodes.DatePriseActe)}
                    readonly={softRO && !!ctx.formik?.values?.datePriseActe && !!ctx.formik?.values?.idcalcRiActant}
                  />
                  <FGWalterSelectInput
                    name="idcalcRiActant"
                    label={t(ETLCodes.CALCRIActant)}
                    items={usersRupture}
                    loading={uLoading}
                    readonly={softRO && !!ctx.formik?.values?.datePriseActe && !!ctx.formik?.values?.idcalcRiActant}
                  />
                </>
              )}
            </FGCustomPanel>
            <FGTextAreaInput name="remarqueRupture" label={t(ETLCodes.Remarque)} />
            <FGCustomPanel>
              {ctx => (
                <FGWalterFileInput
                  name="fichierRupture"
                  label={t(ETLCodes.Document)}
                  downloadFn={
                    ctx.formik.values?.fichierRupture &&
                    ((isHope && mode === "hope") || (!isHope && mode === "walter")) &&
                    (() => downloadFn(ctx.formik.values?.idfichierRupture))
                  }
                />
              )}
            </FGCustomPanel>
          </FieldGroup>
        </FieldGroup>
      </SmallFormGenerator>
    </>
  );
};
