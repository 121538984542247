import { Callout } from "@blueprintjs/core";
import { useRequest } from "ahooks";
import { FGCheckboxInput, FGNumberInput, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import {
  FcbFormateurLocalisationContratDetailDtoFromJSON,
  FormateurApi,
  FormateurLocalisationContratApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurLocalisationItemPageProps {}

export const FormateurLocalisationItemPage: React.FunctionComponent<IFormateurLocalisationItemPageProps> = () => {
  const { id, idLocalisation, state } = useParams<{ id: string; idLocalisation: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(FormateurLocalisationContratApi);
  const formateurApi = useApiService(FormateurApi);
  const history = useHistory();

  const [contrat, loadingContrat] = useReferential(a => a.referentialGetContratFormateur(), true, []);
  const [centre, loadingCentre] = useReferential(a => a.referentialGetCentre(), true, []);
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required)),
      idcontratFormateur: Yup.number().required(t(ETLCodes.Required)),
      localisation: Yup.object({
        idcentre: Yup.number().required(t(ETLCodes.Required)),
        premierContrat: Yup.date(),
        debutContrat: Yup.date()
      }),
      dateDebut: Yup.date(),
      dateFin: Yup.date()
    });
  }, [t]);

  const { data: isAzureActive, loading: aaLoading } = useRequest(
    () => formateurApi.formateurIsAzureActive({ idFormateur: +id }),
    {
      refreshDeps: [id]
    }
  );

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idLocalisation <= 0
        ? FcbFormateurLocalisationContratDetailDtoFromJSON({ localisation: { idformateur: +id } })
        : api.formateurLocalisationContratGet({ id: +idLocalisation }),
    saveApiFn: d => api.formateurLocalisationContratSave({ FcbFormateurLocalisationContratDetailDto: d }),
    onSavedRoute: d => `${ERoutes.formateur}/${id}/Localisation/${d.idformateurLocalisationContrat}/edit`,
    deleteApiFn: () => {}
  });

  return (
    data && (
      <>
        {data.linkedToMatieres && (
          <Callout
            style={{ marginBottom: "1rem" }}
            intent="warning"
            title={t(ETLCodes.LocalisationNonModifiableMatiere)}
            icon="warning-sign"
          />
        )}
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          showColons
          editMode={state === "edit"}
          minLabelWidth={180}
          labelAlignment="right"
          formatDate="dd/MM/yyyy"
          validationSchema={FormSchema}
          inline
          boldLabel
          loading={loading || aaLoading}
          onCancel={() => history.push(`${ERoutes.formateur}/${+id}/Localisation`)}
          onDelete={deleteItem}
          showDeleteButton={false}
          saving={saving}
          deleting={deleting}
        >
          <FieldGroup columns={1}>
            <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} disabled={data.linkedToMatieres} />
            <FGWalterSelectInput
              requiredMark
              name="localisation.idcentre"
              label={t(ETLCodes.Centre)}
              items={centre}
              loading={loadingCentre}
              disabled={data.linkedToMatieres}
            />
            <FGWalterSelectInput
              name="idcontratFormateur"
              label={t(ETLCodes.TypeContrat)}
              items={contrat}
              loading={loadingContrat}
              disabled={data.linkedToMatieres}
            />
            <FGNumberInput name="heureMin" label={t(ETLCodes.NbHeureMinimum)} />
            <FGNumberInput name="heureMax" label={t(ETLCodes.NbHeureMaximum)} />
            <FGWalterDateMaskInput name="localisation.premierContrat" label={t(ETLCodes.DateEntreeCentre)} readonly />
            <FGWalterDateMaskInput name="localisation.debutContrat" label={t(ETLCodes.DebutContrat)} readonly />
            <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DebutActivite)} readonly />
            <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.FinActivite)} readonly />
            <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} readonly />
            <FGNumberInput name="localisation.km" label={t(ETLCodes.Km)} />
            <FGTextAreaInput name="localisation.remarque" label={t(ETLCodes.Remarque)} />
            {isAzureActive?.value ? (
              <FGCheckboxInput name="localisation.azureActive" label={t(ETLCodes.AzureActive)} />
            ) : null}
          </FieldGroup>
        </SmallFormGenerator>
      </>
    )
  );
};
