import { Card, Divider, Intent, Label } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  FGCustomPanel,
  FGEmpty,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  FieldSet,
  showError,
  showSuccess
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import * as Yup from "yup";

import { DeliberationBulletinDeliberation2SessGrid, DeliberationBulletinDeliberationGrid, IMatiereCatCote } from ".";
import {
  BulletinApprenantTypeApi,
  ETypeDeliberation,
  FcbBulletinApprenantTypeDetailDto,
  UtilisateurApi
} from "../../../../../api";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useEventsContext, usePrintingQueueContext } from "../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IDeliberationBulletinDetailProps {
  bulletin: FcbBulletinApprenantTypeDetailDto;
}
const StyledLabel = styled(Label)`
  font-weight: bold;
`;

export const DeliberationBulletinDetail: React.FunctionComponent<IDeliberationBulletinDetailProps> = ({ bulletin }) => {
  const { t } = useTl();
  const api = useApiService(BulletinApprenantTypeApi);
  const userApi = useApiService(UtilisateurApi);
  const { dispatchEvent } = useEventsContext();
  const { refreshQueue, setIsQueueForcedOpen } = usePrintingQueueContext();

  const [hasEmptyEvaluations, setHasEmptyEvaluations] = React.useState(false);
  const [markingAsWrong, setMarkingAsWrong] = React.useState(false);

  const getData = React.useCallback(() => {
    return bulletin;
  }, [bulletin]);

  const { data, loading, saveItem, saving, deleteItem, deleting } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: getData,
        saveApiFn: async (d: FcbBulletinApprenantTypeDetailDto) => {
          if (hasEmptyEvaluations && !!d.delibe1sess) {
            showError(t(ETLCodes.BulletinHasEmptyEvaluations));
            return;
          }

          const dataSaved = await api.bulletinApprenantTypeSave({ FcbBulletinApprenantTypeSaveDto: d });
          dispatchEvent("DELIBERATION_SAVED", dataSaved);
          return dataSaved;
        },
        onSaved: (d: FcbBulletinApprenantTypeDetailDto) => {
          if (!!d.sentToQueue) {
            refreshQueue();
            setIsQueueForcedOpen(true);
            showSuccess(t(ETLCodes.BulletinsAddedToQueue));
          }
        },
        deleteApiFn: d => {},
        onDeletedRoute: () => ``
      }),
      [api, dispatchEvent, getData, hasEmptyEvaluations, refreshQueue, setIsQueueForcedOpen, t]
    )
  );

  const [search, setSearch] = React.useState(false);

  const [d1Sess, d1Sessloading] = useReferential(
    apiDelib => apiDelib.referentialGetDeliberationTypes({ typeDeliberation: ETypeDeliberation.Bulletin1Session }),
    true
  );
  const [d2Sess, d2Sessloading] = useReferential(
    apiDelib => apiDelib.referentialGetDeliberationTypes({ typeDeliberation: ETypeDeliberation.Bulletin2Session }),
    true
  );
  const FormSchemaSess1 = React.useMemo(() => {
    return Yup.object().shape({
      iddeliberationType: Yup.number()
        .nullable()
        .test("defined", t(ETLCodes.Required), value => value !== undefined),
      dateDeliberation: Yup.date().required(t(ETLCodes.Required))
    });
  }, [t]);

  const FormSchemaSess2 = React.useMemo(() => {
    return Yup.object().shape({
      iddeliberationType2sess: Yup.number()
        .nullable()
        .test("defined", t(ETLCodes.Required), value => value !== undefined),
      dateDeliberation2sess: Yup.date().required(t(ETLCodes.Required))
    });
  }, [t]);

  const markAsWrong = React.useCallback(async () => {
    if (data) {
      setMarkingAsWrong(true);
      await api.bulletinApprenantTypeSetBulletinApprenantTypeVisible({
        FcbBulletinApprenantTypeDetailDto: { ...data, isVisibleBulletin: false }
      });
      setMarkingAsWrong(false);
      dispatchEvent("DELIBERATION_REFRESH");
    }
  }, [api, data, dispatchEvent]);

  const fetchUserCanValidate = React.useCallback(async () => {
    const res = await userApi.utilisateurGetUserValidationDirectionRight();
    return res.value;
  }, [userApi]);
  const { data: hasRightToDevalidate, isLoading: hasRightToDevalidateLoading } = useQuery(
    "user-can-validate",
    fetchUserCanValidate,
    { enabled: true, cacheTime: 0 }
  );

  const [matiereCatCotes, setMatiereCatCotes] = React.useState<IMatiereCatCote[]>([]);

  return (
    data && (
      <>
        <StyledLabel>
          {t(ETLCodes.TotalCourseType)} : {data.totalTypeCours}
        </StyledLabel>
        <Card>
          <FieldSet
            title={t(ETLCodes.Sess1)}
            buttons={[
              {
                icon: IconNames.CROSS,
                onClick: markAsWrong,
                text: t(ETLCodes.MarkAsWrong),
                intent: Intent.DANGER,
                loading: markingAsWrong
              }
            ]}
          >
            <SmallFormGenerator
              initialValues={data}
              onSubmit={async form => {
                form.isSecondeSession = false;
                await saveItem(form);
                setSearch(true);
              }}
              editMode={true}
              loading={loading}
              onCancel={() => {}}
              onDelete={deleteItem}
              saving={saving}
              deleting={deleting}
              formatDate="dd-MM-yyyy"
              minLabelWidth={200}
              validationSchema={FormSchemaSess1}
            >
              <FieldGroup columns={2} title={t(ETLCodes.BulletinSess1)}>
                <FieldGroup>
                  <FGWalterDateMaskInput
                    label={t(ETLCodes.DateAbandon)}
                    name="dateAbandon"
                    readonly
                    showPlaceholder={false}
                  />
                  <FGTextInput label={t(ETLCodes.HeuresPresences)} name="totalHeuresPresence" readonly />
                  <FGTextInput label={t(ETLCodes.HeuresAbsences)} name="totalHeuresAbsences" readonly />
                  <FGTextInput label={t(ETLCodes.HeuresJustifiees)} name="totalHeuresAbsencesJustifiees" readonly />
                  <FGCustomPanel>
                    {ctx => (
                      <FGWalterDateMaskInput
                        label={t(ETLCodes.DateDeliberation)}
                        name="dateDeliberation"
                        readonly={ctx.formik.values.validationDirection1sessBool}
                        disabled={
                          ctx.formik.values.resultatsSession1?.results?.length > 0 &&
                          ctx.formik.values.resultatsSession1?.results?.every(r => r.dispenseTotale)
                        }
                      />
                    )}
                  </FGCustomPanel>
                  <FGWalterDateMaskInput
                    label={t(ETLCodes.DateEncodage)}
                    name="modificationDate"
                    readonly
                    showPlaceholder={false}
                  />
                  <FGCustomPanel>
                    {ctx => (
                      <FGWalterCheckboxInput
                        name="validationDirection1sessBool"
                        label={t(ETLCodes.ValidationDirection)}
                        disabled={
                          !ctx.formik.values.iddeliberationType ||
                          !hasRightToDevalidate ||
                          ctx.formik.values.validationDirection2sessBool ||
                          (ctx.formik.values.resultatsSession1?.results?.length > 0 &&
                            ctx.formik.values.resultatsSession1?.results?.every(r => r.dispenseTotale))
                        }
                      />
                    )}
                  </FGCustomPanel>
                </FieldGroup>
                <FieldGroup>
                  <FGWalterDateMaskInput
                    label={t(ETLCodes.Rupture)}
                    name="dateRupture"
                    readonly
                    showPlaceholder={false}
                  />
                  <FGTextInput label={t(ETLCodes.TotalPoints)} name="totalPointsSess1" readonly />
                  <FGTextInput label={t(ETLCodes.TotalDispense)} name="totalPointsDispenseSess1" readonly />
                  <FGTextInput
                    label={t(ETLCodes.TotalApresDeliberation)}
                    name="totalPointsApresDeliberationSess1"
                    readonly
                  />
                  <FGEmpty />
                  <FGTextInput label={t(ETLCodes.EncodagePar)} name="userEncodage" readonly />
                  <FGCustomPanel>
                    {ctx => (
                      <FGWalterSelectInput
                        name="iddeliberationType"
                        items={d1Sess}
                        loading={d1Sessloading}
                        label={t(ETLCodes.DeliberationActee)}
                        requiredMark
                        readonly={ctx.formik.values.validationDirection1sessBool}
                        disabled={
                          ctx.formik.values.resultatsSession1?.results?.length > 0 &&
                          ctx.formik.values.resultatsSession1?.results?.every(r => r.dispenseTotale)
                        }
                      />
                    )}
                  </FGCustomPanel>
                  <FGWalterDateMaskInput
                    name="validationDirection1sess"
                    label={t(ETLCodes.ValidationDirectionDate)}
                    readonly
                  />
                </FieldGroup>
              </FieldGroup>
              <FieldGroup>
                <FGCustomPanel>
                  {ctx => (
                    <FGTextAreaInput
                      label={t(ETLCodes.CommentaireDeliberation)}
                      name="remarqueDelibe"
                      growVertically={true}
                      readonly={ctx.formik.values.validationDirection1sessBool}
                      disabled={
                        ctx.formik.values.resultatsSession1?.results?.length > 0 &&
                        ctx.formik.values.resultatsSession1?.results?.every(r => r.dispenseTotale)
                      }
                    />
                  )}
                </FGCustomPanel>
              </FieldGroup>
            </SmallFormGenerator>
            <Divider />
            <DeliberationBulletinDeliberationGrid
              idbulletinApprenantType={data.idbulletinApprenantType}
              execSearch={search}
              validationDirection1sess={data.validationDirection1sessBool}
              setHasEmptyEvaluations={setHasEmptyEvaluations}
              setMatiereCatCotes={setMatiereCatCotes}
            />
          </FieldSet>
        </Card>
        <br />
        {data.view2Sess && (
          <Card>
            <FieldSet title={t(ETLCodes.Sess2)}>
              <SmallFormGenerator
                initialValues={data}
                onSubmit={async form => {
                  form.isSecondeSession = true;
                  await saveItem(form);
                  setSearch(true);
                }}
                editMode={false}
                loading={loading}
                onCancel={() => {}}
                onDelete={deleteItem}
                saving={saving}
                deleting={deleting}
                formatDate="dd-MM-yyyy"
                minLabelWidth={200}
                validationSchema={FormSchemaSess2}
              >
                <FieldGroup columns={2} title={t(ETLCodes.BulletinSess2)}>
                  <FGWalterDateMaskInput
                    label={t(ETLCodes.DateAbandon)}
                    name="dateAbandon"
                    readonly
                    showPlaceholder={false}
                  />
                  <FGWalterDateMaskInput
                    label={t(ETLCodes.Rupture)}
                    name="dateRupture"
                    readonly
                    showPlaceholder={false}
                  />
                  <FGTextInput label={t(ETLCodes.HeuresPresences)} name="totalHeuresPresence" readonly />
                  <FGTextInput label={t(ETLCodes.TotalPoints)} name="totalPointsSess2" readonly />
                  <FGTextInput label={t(ETLCodes.HeuresAbsences)} name="totalHeuresAbsences" readonly />
                  <FGTextInput label={t(ETLCodes.TotalDispense)} name="totalPointsDispenseSess2" readonly />
                  <FGTextInput label={t(ETLCodes.HeuresJustifiees)} name="totalHeuresAbsencesJustifiees" readonly />
                  <FGTextInput
                    label={t(ETLCodes.TotalApresDeliberation)}
                    name="totalPointsApresDeliberationSess2"
                    readonly
                  />
                  <FGCustomPanel>
                    {ctx => (
                      <FGWalterDateMaskInput
                        label={t(ETLCodes.DateDeliberation)}
                        name="dateDeliberation2sess"
                        showPlaceholder={false}
                        readonly={ctx.formik.values.validationDirection2sessBool}
                        disabled={
                          ctx.formik.values.resultatsSession2?.results?.length > 0 &&
                          ctx.formik.values.resultatsSession2?.results?.every(r => r.dispenseTotale)
                        }
                      />
                    )}
                  </FGCustomPanel>
                  <FGEmpty />
                  <FGWalterDateMaskInput label={t(ETLCodes.DateEncodage)} name="modificationDate" readonly />
                  <FGTextInput label={t(ETLCodes.EncodagePar)} name="userEncodage" readonly />
                  <FGEmpty />
                  <FGCustomPanel>
                    {ctx => (
                      <FGWalterSelectInput
                        items={d2Sess}
                        loading={d2Sessloading}
                        label={t(ETLCodes.DeliberationActee)}
                        name="iddeliberationType2sess"
                        requiredMark
                        readonly={ctx.formik.values.validationDirection2sessBool}
                        disabled={
                          ctx.formik.values.resultatsSession2?.results?.length > 0 &&
                          ctx.formik.values.resultatsSession2?.results?.every(r => r.dispenseTotale)
                        }
                      />
                    )}
                  </FGCustomPanel>
                  <FGCustomPanel>
                    {ctx => (
                      <FGWalterCheckboxInput
                        name="validationDirection2sessBool"
                        label={t(ETLCodes.ValidationDirection)}
                        disabled={
                          !ctx.formik.values.iddeliberationType2sess ||
                          !hasRightToDevalidate ||
                          !ctx.formik.values.validationDirection1sessBool ||
                          (ctx.formik.values.resultatsSession2?.results?.length > 0 &&
                            ctx.formik.values.resultatsSession2?.results?.every(r => r.dispenseTotale))
                        }
                      />
                    )}
                  </FGCustomPanel>
                  <FGWalterDateMaskInput
                    name="validationDirection2sess"
                    label={t(ETLCodes.ValidationDirectionDate)}
                    readonly
                  />
                </FieldGroup>
                <FieldGroup>
                  <FGCustomPanel>
                    {ctx => (
                      <FGTextAreaInput
                        label={t(ETLCodes.CommentaireDeliberation)}
                        name="remarqueDelibe2sess"
                        growVertically={true}
                        readonly={ctx.formik.values.validationDirection2sessBool}
                        disabled={
                          ctx.formik.values.resultatsSession2?.results?.length > 0 &&
                          ctx.formik.values.resultatsSession2?.results?.every(r => r.dispenseTotale)
                        }
                      />
                    )}
                  </FGCustomPanel>
                </FieldGroup>
              </SmallFormGenerator>
              <Divider />
              <DeliberationBulletinDeliberation2SessGrid
                idbulletinApprenantType={data.idbulletinApprenantType}
                execSearch={search}
                validationDirection2sess={data.validationDirection2sessBool}
                matiereCatCotes={matiereCatCotes}
              />
            </FieldSet>
          </Card>
        )}
      </>
    )
  );
};
