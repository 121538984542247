import { FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { AcquisProfessionnelApi, FcbAcquisProfessionnelDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator, FGWalterDateMaskInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantAcquisProfessionnelDetailProps {}

export const ApprenantAcquisProfessionnelDetail: React.FunctionComponent<IApprenantAcquisProfessionnelDetailProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(AcquisProfessionnelApi);
  const { id, acquisProfessionnelId, state } = useParams<{
    id: string;
    acquisProfessionnelId: string;
    state: string;
  }>();
  const idApprenant = React.useMemo(() => +id, [id]);
  const idacquisProfessionnel = React.useMemo(() => +acquisProfessionnelId, [acquisProfessionnelId]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idacquisProfessionnel <= 0
        ? FcbAcquisProfessionnelDtoFromJSON({
            idAcquisProfessionnel: 0,
            idapprenant: idApprenant,
            dateDebut: new Date(),
            dateFin: new Date()
          })
        : api.acquisProfessionnelGet({ id: +idacquisProfessionnel }),
    saveApiFn: d => api.acquisProfessionnelSave({ FcbAcquisProfessionnelDto: d }),
    onSavedRoute: d => `${ERoutes.apprenant}/${idApprenant}/acquisprofessionnel/${d.idacquisProfessionnel}/${state}`,
    deleteApiFn: d => api.acquisProfessionnelDelete({ id: d.idacquisProfessionnel }),
    onDeletedRoute: d => `${ERoutes.apprenant}/${idApprenant}/acquisprofessionnel`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      entreprise: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.apprenant}/${idApprenant}/acquisprofessionnel`)}
      onDelete={deleteItem}
      showDeleteButton={+idacquisProfessionnel > 0}
      saving={saving}
      deleting={deleting}
      formatDate="dd-MM-yyyy"
      minLabelWidth={200}
    >
      <FieldGroup columns={2} fieldsetProps={{ title: t(ETLCodes.Edition), collapsable: false }}>
        <FGTextInput label={t(ETLCodes.Entreprise)} name="entreprise" maxLength={50} />
        <FGTextAreaInput label={t(ETLCodes.Adresse)} name="adresse" maxLength={100} />
        <FGTextInput label={t(ETLCodes.CodePostal)} name="codePostal" maxLength={6} />
        <FGTextInput label={t(ETLCodes.Localite)} name="localite" maxLength={100} />
        <FGWalterDateMaskInput label={t(ETLCodes.DateDebut)} name="dateDebut" />
        <FGWalterDateMaskInput label={t(ETLCodes.DateFin)} name="dateFin" />
        <FGTextAreaInput label={t(ETLCodes.Description)} name="libelle" maxLength={100} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
