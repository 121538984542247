import { FGListen, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbPaiementDtoFromJSON, PaiementApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { FGEuroMaskInput } from "../../../../../components/formGenerator/FGEuroMaskInput";
import { useGlobalData } from "../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantFinancePaiementDetailProps {}

export const ApprenantFinancePaiementDetail: React.FunctionComponent<IApprenantFinancePaiementDetailProps> = () => {
  const { t } = useTl();
  const api = useApiService(PaiementApi);
  const history = useHistory();

  const { id, paiementId, state } = useParams<{ id: string; paiementId: string; state: string }>();
  const { currentAnneeScolaire } = useGlobalData();

  const [inscriptionRequest, setInscriptionRequest] = React.useState({
    idCentre: 0,
    anneeScolaire: "",
    idApprenant: +id
  });

  const [annees, aLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);
  const [modes, mLoading] = useReferential(a => a.referentialGetModesPaiement(), true, []);
  const [centres, cLoading] = useReferential(a => a.referentialGetCentreByApprenant({ idApprenant: +id }), true, []);
  const [inscriptions, iLoading] = useReferential(
    a => a.referentialGetInscriptionsApprenant(inscriptionRequest),
    true,
    [inscriptionRequest]
  );

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +paiementId <= 0
        ? FcbPaiementDtoFromJSON({
            paiementId: 0,
            idapprenant: +id,
            anneeScolaire: currentAnneeScolaire,
            datePaiement: new Date()
          })
        : api.paiementGetPaiement({ id: +paiementId }),
    saveApiFn: d => api.paiementSavePaiement({ FcbPaiementDto: d }),
    onSavedRoute: d => `${ERoutes.apprenant}/${id}/finance`,
    deleteApiFn: d => api.paiementDeletePaiement({ id: +paiementId }),
    onDeletedRoute: () => `${ERoutes.apprenant}/${id}/finance`
  });

  React.useEffect(() => {
    if (data) {
      setInscriptionRequest({
        idCentre: data.idcentre,
        anneeScolaire: data.anneeScolaire,
        idApprenant: data.idapprenant
      });
    }
  }, [data]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      datePaiement: Yup.date().required(t(ETLCodes.Required)),
      montantPaye: Yup.number().required(t(ETLCodes.Required)),
      anneeScolaire: Yup.string().required(t(ETLCodes.Required)),
      idmode: Yup.number().required(t(ETLCodes.Required)),
      idcentre: Yup.number().required(t(ETLCodes.Required)),
      idinscription: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        showColons
        editMode={state === "edit"}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(`${ERoutes.apprenant}/${id}/finance`)}
        onDelete={deleteItem}
        showDeleteButton={+paiementId > 0}
        saving={saving}
        deleting={deleting}
        formatDate="dd/MM/yyyy"
      >
        <FGListen
          field="idcentre"
          onChanged={(value, formik) =>
            setInscriptionRequest({
              idCentre: value,
              anneeScolaire: formik.values.anneeScolaire,
              idApprenant: +id
            })
          }
        />
        <FGListen
          field="anneeScolaire"
          onChanged={(value, formik) =>
            setInscriptionRequest({
              idCentre: formik.values.idcentre,
              anneeScolaire: value,
              idApprenant: +id
            })
          }
        />
        <FieldGroup columns={2}>
          <FGWalterDateMaskInput name="datePaiement" label={t(ETLCodes.Date)} />
          <FGEuroMaskInput name="montantPaye" label={t(ETLCodes.Montant)} allowNegative={true} />
          <FGWalterSelectInput name="anneeScolaire" label={t(ETLCodes.Annee)} loading={aLoading} items={annees} />
          <FGWalterSelectInput name="idmode" label={t(ETLCodes.Mode)} loading={mLoading} items={modes} />
        </FieldGroup>
        <FieldGroup>
          <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} loading={cLoading} items={centres} />
          <FGWalterSelectInput
            name="idinscription"
            label={t(ETLCodes.Inscription)}
            loading={iLoading}
            items={inscriptions}
          />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
