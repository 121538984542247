import { IFGSelectInputProps } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { FGEditableSelectInput, FGWalterSelectInput } from ".";
import { PersonneApi, TelephoneDto, TelephoneDtoFromJSON } from "../../api";
import { useApiService, useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { nameof } from "../../utils";
import FGCopyTextInput from "./FGCopyTextInput";
import * as Yup from "yup";
import { ETLCodes } from "../../locales";

export interface IFGEditableTelSelectInputProps
  extends Omit<IFGSelectInputProps<TelephoneDto>, "valueField" | "displayField" | "items" | "loading"> {
  autoSelectIfOne?: boolean;
  idpersonne: number;
  types: string[];
}

export const FGEditableTelSelectInput: React.FunctionComponent<IFGEditableTelSelectInputProps> = ({
  idpersonne,
  types,
  ...props
}) => {
  const { t } = useTl();
  const api = useApiService(PersonneApi);

  const fetchTels = React.useCallback(async () => await api.personneGetTels({ idpersonne, request_body: types }), [
    api,
    idpersonne,
    types
  ]);
  const { data: items, isFetching, refetch } = useQuery(["telsdto-personne", idpersonne, types], fetchTels);

  const [typeTels, ttLoading] = useReferential(a => a.referentialGetTypesTelephoneByCodes({ request_body: types }));

  const onSave = React.useCallback(
    async (data: TelephoneDto) => {
      return await api.personneSaveTelephone({ TelephoneDto: data });
    },
    [api]
  );

  const onDelete = React.useCallback(
    async (data: TelephoneDto) => {
      await api.personneDeleteTelFromPersonne({ idtelephone: data.idtelephone });
    },
    [api]
  );

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      idtypeTelephone: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      numero: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <FGEditableSelectInput
      displayField={nameof<TelephoneDto>("numero")}
      valueField={nameof<TelephoneDto>("idtelephone")}
      baseData={TelephoneDtoFromJSON({ idpersonne })}
      validationSchema={validationSchema}
      refresh={async () => {
        await refetch();
      }}
      editFields={
        <>
          <FGWalterSelectInput
            name={nameof<TelephoneDto>("idtypeTelephone")}
            noLabel
            items={typeTels}
            loading={ttLoading}
            requiredMark
          />
          <FGCopyTextInput
            copyOnlyDigits={true}
            name={nameof<TelephoneDto>("numero")}
            noLabel
            maxLength={20}
            requiredMark
          />
        </>
      }
      onSave={onSave}
      items={items}
      loading={isFetching}
      {...props}
    />
  );
};

export default FGEditableTelSelectInput;
