import { Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { add, addMinutes, endOfDay, format, isAfter, parseJSON } from "date-fns";
import {
  ButtonContainer,
  DataTable,
  FGCustomInput,
  FGCustomPanel,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IDataTableColumn,
  useFGFormik,
  useGridState,
  ValidationErrorsType
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import * as Yup from "yup";

import { SuiviApprenantActionDialog, SuiviWalterDocumentEditDialog, SuiviWalterDocumentUploadDialog } from "..";
import {
  ClasseApi,
  EContentHidingMode,
  EIfapmeSide,
  FcbSuiviApprenantDetailsDto,
  MetierApi,
  SuiviApprenantFichierGridDto
} from "../../../../../api";
import {
  AddButton,
  DeleteButton,
  DownloadButton,
  EditButton,
  FGReadonlyHtmlViewer,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  LinkButton,
  SmallFormGenerator
} from "../../../../../components";
import { useAuth } from "../../../../../contexts";
import { useApiService, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const StatutContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  > span {
    font-weight: bold;
    color: ${props => props.color};
    font-size: 14pt;
  }
`;

const RadioReadonlyContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
`;

export interface IApprenantSuiviFormProps {
  data: FcbSuiviApprenantDetailsDto;
  suiviId: number;
  saveItem: (values: FcbSuiviApprenantDetailsDto) => void | Promise<void>;
  loading: boolean;
  saving: boolean;
  deleteItem?: () => void | Promise<void>;
  deleting?: boolean;
  editMode: boolean;
  onCancel?: () => void;
  validationErrors?: ValidationErrorsType;
  fieldsetTitle: string;
  deleteFichier?: (idsuiviFichier) => void;
  downloadFichier?: (idsuiviFichier) => void;
  rowLoading?: number;
  refreshData?: () => void;
  idsApprenant?: number[];
}

export const ApprenantSuiviForm: React.FunctionComponent<IApprenantSuiviFormProps> = ({
  data,
  suiviId,
  deleteItem,
  editMode,
  loading,
  saveItem,
  saving,
  onCancel,
  deleting,
  validationErrors,
  fieldsetTitle,
  deleteFichier,
  downloadFichier,
  rowLoading,
  refreshData,
  idsApprenant
}) => {
  const { t } = useTl();
  const { user } = useAuth();
  const { theme, isHope } = useTheme();
  const metierApi = useApiService(MetierApi);
  const classeApi = useApiService(ClasseApi);
  const FormSchema = React.useMemo(() => {
    let shape: any = {
      dateSuivi: Yup.date()
        .typeError(t(ETLCodes.DateInvalide))
        .required(t(ETLCodes.Required))
        .min(new Date(1900, 1, 1), t(ETLCodes.DateMinimale))
        .max(endOfDay(add(new Date(), { years: 1 })), t(ETLCodes.DateCannotBeGreaterThan1YearInFurture)),
      idcentre: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      idrealisateurSuivi: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      idtypeSuivi: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      idsousTypeSuivi: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required))
    };

    if (!idsApprenant) {
      shape = {
        ...shape,
        idapprenant: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required))
      };
    }

    return Yup.object().shape(shape);
  }, [idsApprenant, t]);

  const isCrea = React.useMemo(() => user.hopeMainLevelName === "Conseiller CREA", [user.hopeMainLevelName]);

  const formik = useFGFormik<FcbSuiviApprenantDetailsDto>({
    initialValues: data,
    onSubmit: saveItem,
    validationSchema: FormSchema
  });

  const [lieuSuivi, lieuLoading] = useReferential(a => a.referentialGetLieuSuivi(), true, []);
  const [metiers, meLoading] = useReferential(a => a.referentialGetMetier(), true, []);

  const [sousTypeSuivis, stsLoading, , rawSousTypesSuivis] = useReferential(
    a =>
      a.referentialGetSousTypeSuivis({
        idtypeSuivi: formik?.values?.idtypeSuivi,
        idsousTypeSuivi: data?.idsousTypeSuivi
      }),
    true,
    [formik?.values?.idtypeSuivi, data?.idsousTypeSuivi]
  );

  const [typeSuivis, tsLoading] = useReferential(
    a => a.referentialGetTypeSuivis({ side: EIfapmeSide.Walter, idtypeSuivi: data?.idtypeSuivi }),
    true,
    [data?.idtypeSuivi]
  );
  const [classes, classesLoading] = useReferential(
    a =>
      a.referentialGetClasseApprenantCurrentYear({
        idApprenant: formik?.values?.idapprenant,
        idCentre: formik?.values?.idcentre ?? 0
      }),
    true,
    [formik?.values?.idapprenant, formik?.values?.idcentre]
  );
  const [modes, mLoading] = useReferential(a => a.referentialGetModes(), true, []);
  const [formateurs, fLoading] = useReferential(
    a =>
      a.referentialGetFormateurByIdClasseOrIdCentre({
        idClasse: formik?.values?.idclasse ?? 0,
        idCentre: formik?.values?.idcentre ?? 0
      }),
    true,
    [formik?.values?.idclasse, formik?.values?.idcentre]
  );

  const fetchClasseInfos = React.useCallback(async () => {
    if (!formik?.values?.idclasse) return null;
    return await classeApi.classeGetClasseInfosForSuivi({ idclasse: formik?.values?.idclasse });
  }, [classeApi, formik?.values?.idclasse]);
  const { data: classeInfos } = useQuery(["suivi-classe-info", formik?.values?.idclasse], fetchClasseInfos);

  const fetchMetierDisplayName = React.useCallback(async () => {
    if (!classeInfos?.idmetier) return "";
    return await metierApi.metierGetDisplayName({ id: classeInfos?.idmetier });
  }, [classeInfos?.idmetier, metierApi]);
  const { data: metierName, isFetching: mnLoading } = useQuery(
    ["metier-display-name", classeInfos?.idmetier],
    fetchMetierDisplayName
  );

  const [centres, cLoading] = useReferential(
    a =>
      isCrea
        ? a.referentialGetCentre()
        : a.referentialGetCentreByApprenants({
            request_body: !!idsApprenant ? idsApprenant : [formik?.values?.idapprenant]
          }),
    true,
    [formik?.values?.idapprenant, idsApprenant]
  );
  const [centreUsers, cuLoading] = useReferential(
    a => a.referentialGetCentreUsers({ request_body: !!formik?.values?.idcentre ? [formik?.values?.idcentre] : [] }),
    true,
    [formik?.values?.idcentre]
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (formik?.values?.action && !formik?.initialValues?.action) {
      setDialogOpen(true);
    }
  }, [formik?.initialValues?.action, formik?.values?.action]);

  React.useEffect(() => {
    if (!formik?.values?.action && !!formik?.values?.suiviAction) {
      formik.setFieldValue("suiviAction", null);
    }
  }, [formik]);

  const statutColor = React.useMemo(
    () =>
      !!formik?.values?.suiviAction?.dateRealisation
        ? theme.sucessColor
        : isAfter(formik?.values?.suiviAction?.dateEcheance, new Date())
        ? theme.warningColor
        : theme.dangerColor,
    [
      formik?.values?.suiviAction?.dateEcheance,
      formik?.values?.suiviAction?.dateRealisation,
      theme.dangerColor,
      theme.sucessColor,
      theme.warningColor
    ]
  );

  const statutText = React.useMemo(
    () =>
      t(
        !!formik?.values?.suiviAction?.dateRealisation
          ? ETLCodes.ActionRealisee
          : isAfter(formik?.values?.suiviAction?.dateEcheance, new Date())
          ? ETLCodes.ActionNonRealisee
          : ETLCodes.DateEcheanceDepassee
      ),
    [formik?.values?.suiviAction?.dateEcheance, formik?.values?.suiviAction?.dateRealisation, t]
  );

  const actionIsRealisee = React.useMemo(() => !!formik?.values?.suiviAction?.dateRealisation, [
    formik?.values?.suiviAction?.dateRealisation
  ]);

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (data?.fichiers) {
      setData(data.fichiers);
    }
  }, [data?.fichiers, setData]);

  const [currentSuiviApprenantFichierId, setCurrentSuiviApprenantFichierId] = React.useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);

  const isCreationUser = React.useMemo(
    () => data?.creationUser === user.iduser || data?.idrealisateurSuivi === user.iduser,
    [data?.creationUser, data?.idrealisateurSuivi, user.iduser]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SuiviApprenantFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentSuiviApprenantFichierId(row.idsuiviApprenantFichier)}
              loading={rowLoading === row.idsuiviApprenantFichier}
              disabled={(!!rowLoading && rowLoading !== row.idsuiviApprenantFichier) || !(isCreationUser && !isHope)}
            />
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idsuiviApprenantFichier)}
              loading={rowLoading === row.idsuiviApprenantFichier}
              disabled={(!!rowLoading && rowLoading !== row.idsuiviApprenantFichier) || isHope}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idsuiviApprenantFichier)}
              loading={rowLoading === row.idsuiviApprenantFichier}
              disabled={(!!rowLoading && rowLoading !== row.idsuiviApprenantFichier) || !(isCreationUser && !isHope)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeSuiviFichier"
      }
    ],
    [deleteFichier, downloadFichier, isCreationUser, isHope, rowLoading, t]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setCurrentSuiviApprenantFichierId(null);
      setUploadDialogOpen(false);
      if (refresh) {
        refreshData();
      }
    },
    [refreshData]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialogOpen(true);
  }, []);

  const getROHidingMode = React.useCallback(
    (contentHidingMode: EContentHidingMode) => {
      switch (contentHidingMode) {
        case EContentHidingMode.NONE:
          return t(ETLCodes.Personne);
        case EContentHidingMode.OTHERS:
          return t(ETLCodes.Services);
        case EContentHidingMode.ALL:
          return t(ETLCodes.Tous);
      }
    },
    [t]
  );

  const contenuVisible = React.useCallback(
    ctx =>
      ctx?.formik?.values?.contentHidingMode === EContentHidingMode.NONE ||
      ctx?.formik?.values?.creationUser === user.iduser ||
      (user.ifapmeSide.includes("Walter") && ctx?.formik?.values?.contentHidingMode === EContentHidingMode.OTHERS),
    [user.iduser, user.ifapmeSide]
  );

  return (
    <SmallFormGenerator
      formik={formik}
      showColons
      editMode={editMode}
      minLabelWidth={150}
      labelAlignment="right"
      formatDate="dd/MM/yyyy"
      loading={loading}
      onCancel={onCancel}
      saving={saving}
      showDeleteButton={+suiviId > 0 && !actionIsRealisee}
      onDelete={deleteItem}
      deleting={deleting}
      editable={(data?.creationUser === user.iduser || data?.idrealisateurSuivi === user.iduser) && !data?.fromSmile}
      validationErrors={validationErrors}
    >
      {!!formik?.values?.suiviAction && (
        <StatutContainer color={statutColor}>
          <span>{statutText}</span>
        </StatutContainer>
      )}
      <FieldGroup
        columns={2}
        fieldsetProps={{
          title: fieldsetTitle
        }}
      >
        <FieldGroup>
          <FGWalterSelectInput
            name="idcentre"
            label={t(ETLCodes.Centre)}
            items={centres}
            loading={cLoading}
            readonly={actionIsRealisee}
            autoSelectIfOne
          />
          <FGWalterSelectInput
            name="idrealisateurSuivi"
            label={t(ETLCodes.RealisateurSuivi)}
            items={centreUsers}
            loading={cuLoading}
            readonly={actionIsRealisee}
          />
          <FGTextInput readonly name="encodeur" label={t(ETLCodes.Encodeur)} />
          <FGWalterDateMaskInput name="dateSuivi" label={t(ETLCodes.DateSuivi)} readonly={actionIsRealisee} />
          {!!formik?.values?.idapprenant && (
            <>
              <FGWalterSelectInput
                name="idclasse"
                label={t(ETLCodes.Classe)}
                items={classes}
                loading={classesLoading}
                readonly={actionIsRealisee}
              />
              {!!formik?.values?.idmetier ? (
                <FGWalterSelectInput
                  name="idmetier"
                  label={t(ETLCodes.Metier)}
                  items={metiers}
                  loading={meLoading}
                  readonly
                />
              ) : (
                <FGTextInput
                  name="metier"
                  label={t(ETLCodes.Metier)}
                  readonly
                  formatReadOnlyValue={() => metierName}
                  loading={mnLoading}
                />
              )}
              <FGWalterSelectInput
                name="idformateur"
                label={t(ETLCodes.FormateurConcerne)}
                loading={fLoading}
                items={formateurs}
                disabled={!formik?.values?.idclasse}
                readonly={actionIsRealisee}
              />
            </>
          )}
          <FGWalterSelectInput
            name="idtypeSuivi"
            label={t(ETLCodes.TypeSuivi)}
            items={typeSuivis}
            loading={tsLoading}
            readonly={actionIsRealisee}
          />
          <FGCustomPanel>
            {ctx => (
              <>
                <FGWalterSelectInput
                  name="idsousTypeSuivi"
                  label={t(ETLCodes.SousTypeSuivi)}
                  items={sousTypeSuivis}
                  loading={stsLoading}
                  disabled={!ctx?.formik?.values?.idtypeSuivi}
                  readonly={actionIsRealisee}
                />
                <FGTextInput
                  name="autreSousType"
                  label={t(ETLCodes.Autre)}
                  disabled={!ctx?.formik?.values?.idtypeSuivi}
                  readonly={actionIsRealisee}
                  visible={ctx =>
                    +ctx.formik?.values?.idsousTypeSuivi ===
                    +rawSousTypesSuivis?.find(sts => sts.keyValue === "AUTRE_CREA")?.idValue
                  }
                />
              </>
            )}
          </FGCustomPanel>
          <FGWalterSelectInput
            name="idmode"
            label={t(ETLCodes.Mode)}
            items={modes}
            loading={mLoading}
            readonly={actionIsRealisee}
          />
          <FGWalterSelectInput
            name="idlieuRencontre"
            label={t(ETLCodes.LieuRencontre)}
            items={lieuSuivi}
            loading={lieuLoading}
            readonly={actionIsRealisee}
          />

          <FGCustomPanel>
            {ctx => (
              <>
                <FGCustomInput label={t(ETLCodes.ContenuCachePour)}>
                  {ctx =>
                    ctx?.editMode ? (
                      <RadioGroup
                        disabled={data?.creationUser !== user.iduser && data?.idrealisateurSuivi !== user.iduser}
                        selectedValue={ctx?.formik?.values?.contentHidingMode}
                        inline
                        onChange={e => ctx.formik.setFieldValue("contentHidingMode", e.currentTarget.value)}
                      >
                        <Radio label={t(ETLCodes.Personne)} value={EContentHidingMode.NONE}></Radio>
                        <Radio label={t(ETLCodes.Services)} value={EContentHidingMode.OTHERS}></Radio>
                        <Radio label={t(ETLCodes.Tous)} value={EContentHidingMode.ALL}></Radio>
                      </RadioGroup>
                    ) : (
                      <RadioReadonlyContainer>
                        {getROHidingMode(ctx?.formik?.values?.contentHidingMode)}
                      </RadioReadonlyContainer>
                    )
                  }
                </FGCustomInput>
                <FGWalterCheckboxInput
                  label={t(ETLCodes.Action)}
                  name="action"
                  readonly={actionIsRealisee}
                  helperText={
                    ctx?.formik?.values?.action &&
                    contenuVisible(ctx) && (
                      <LinkButton
                        text={ctx?.editMode && !actionIsRealisee ? t(ETLCodes.EditAction) : t(ETLCodes.ViewAction)}
                        onClick={() => {
                          setDialogOpen(true);
                        }}
                      />
                    )
                  }
                />

                <SuiviApprenantActionDialog
                  dto={ctx?.formik?.values?.suiviAction}
                  isOpen={dialogOpen}
                  onClose={toSave => {
                    setDialogOpen(false);
                    if (toSave) {
                      ctx?.formik.setFieldValue("suiviAction", toSave);
                    } else if (!ctx?.formik?.values?.suiviAction) {
                      ctx.formik.setFieldValue("action", false);
                    }
                  }}
                  enveloppeId={0}
                  editMode={ctx?.editMode && !actionIsRealisee && !data?.fromSmile}
                  editable={!actionIsRealisee && !data?.fromSmile}
                />
              </>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx =>
            ctx.formik?.values?.fromSmile ? (
              <FGReadonlyHtmlViewer name="contenu" label={t(ETLCodes.Contenu)} />
            ) : (
              <FGTextAreaInput
                name="contenu"
                label={t(ETLCodes.Contenu)}
                growVertically
                rows={22}
                visible={contenuVisible(ctx)}
              />
            )
          }
        </FGCustomPanel>
      </FieldGroup>
      <FieldGroup
        visible={suiviId > 0}
        fieldsetProps={{
          title: t(ETLCodes.Documents),
          rightElement: (
            <AddButton
              disabled={!isCreationUser}
              onClick={e => {
                e.stopPropagation();
                onAdd();
              }}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          )
        }}
      >
        <DataTable
          dateFormat="dd/MM/yyyy HH:mm"
          formatDate={formatDate}
          tableState={tableState}
          loading={loading}
          columns={columns}
        ></DataTable>
        {uploadDialogOpen && (
          <SuiviWalterDocumentUploadDialog onClose={onClose} dialogOpen={uploadDialogOpen} idsuivi={suiviId} />
        )}
        {!!currentSuiviApprenantFichierId && (
          <SuiviWalterDocumentEditDialog onClose={onClose} idsuiviApprenantFichier={currentSuiviApprenantFichierId} />
        )}
      </FieldGroup>
    </SmallFormGenerator>
  );
};
