import { Button, Colors, Divider, Icon } from "@blueprintjs/core";
import { DataTable, IDataTableColumn, showError, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { css } from "styled-components";

import { DeliberationBulletinCaterorySumGrid, DeliberationBulletinEvaluationGrid } from ".";
import {
  BulletinApprenantTypeApi,
  BulletinDetailSearch,
  ETypeDeliberation,
  FcbResultatTypeGridDto,
  FcbResultatTypeGridDtoPaginatedResults
} from "../../../../../api";
import { TableCheckboxEditor, TableDateEditor, TableSelectEditor } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IMatiereCatCote {
  categorie: number;
  matiere: string;
  cote: number;
  total: number;
}

export interface IDeliberationBulletinDeliberationGridProps {
  idbulletinApprenantType: number;
  execSearch: boolean;
  validationDirection1sess: boolean;
  setHasEmptyEvaluations: (value: boolean) => void;
  setMatiereCatCotes: (value: IMatiereCatCote[]) => void;
}

export const DeliberationBulletinDeliberationGrid: React.FunctionComponent<IDeliberationBulletinDeliberationGridProps> = ({
  idbulletinApprenantType,
  execSearch,
  validationDirection1sess,
  setHasEmptyEvaluations,
  setMatiereCatCotes
}) => {
  const { t } = useTl();

  const api = useApiService(BulletinApprenantTypeApi);
  const [dMatiere, dMatiereloading] = useReferential(
    a => a.referentialGetDeliberationTypes({ typeDeliberation: ETypeDeliberation.Matiere }),
    true
  );
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999,
    sortKeys: { categorie: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: BulletinDetailSearch) => {
      sObj.idbulletinApprenantType = idbulletinApprenantType;
      return api.bulletinApprenantTypeBulletinDeliberationSession1Search({ BulletinDetailSearch: sObj });
    },
    [api, idbulletinApprenantType]
  );

  const { loading, search, gridResults } = useSearchApi<any, FcbResultatTypeGridDtoPaginatedResults>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  React.useEffect(() => {
    if (!!gridResults) {
      setHasEmptyEvaluations(gridResults.results.some(r => r.evaluations.totalCount === 0));
      setMatiereCatCotes(
        gridResults.results.map(
          v =>
            ({
              categorie: v.categorie,
              matiere: v.idmatiere,
              cote: +v.totalmax.split("/")[0],
              total: +v.totalmax.split("/")[1]
            } as IMatiereCatCote)
        )
      );
    }
  }, [gridResults, setHasEmptyEvaluations, setMatiereCatCotes]);

  React.useEffect(() => {
    if (execSearch) {
      search();
    }
  }, [execSearch, search]);

  const onCheckedRemediationConseillee = React.useCallback(
    async (value: boolean, id: number) => {
      try {
        await api.bulletinApprenantTypeSaveBulletinRemediationConseillee({
          idBulletinInscriptionMatiere: id,
          conseille: value
        });
      } catch (Error) {
        showError(`Unable to save`);
      }
    },
    [api]
  );

  const onCheckedRemediationObligatoire = React.useCallback(
    async (value: boolean, id: number) => {
      try {
        await api.bulletinApprenantTypeSaveBulletinRemediationObligatoire({
          idBulletinInscriptionMatiere: id,
          obligatoire: value
        });
      } catch (Error) {
        showError(`Unable to save`);
      }
    },
    [api]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "_toggle",
        render: (item: FcbResultatTypeGridDto, _, { subPanelOpened, toggleSubPanel }) => {
          if (item.evaluations.totalCount > 0) {
            return <Button icon={subPanelOpened ? "minus" : "plus"} onClick={() => toggleSubPanel()}></Button>;
          } else {
            return <div />;
          }
        }
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "codematiere"
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "matiere"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.TJ),
        fieldName: "tj"
      },
      {
        header: () => t(ETLCodes.Examen),
        fieldName: "exa"
      },
      {
        header: () => t(ETLCodes.Categorie),
        fieldName: "categorie"
      },
      {
        header: () => t(ETLCodes.Deliberation),
        fieldName: "iddeliberationType",
        customizeCellStyle: () =>
          css`
            width: 250px;
          `,
        render: (row: FcbResultatTypeGridDto) => {
          if (row.idclassematiere > 0) {
            return (
              <TableSelectEditor
                idBulletinInscriptionMatiere={row.idbulletininscriptionmatiere}
                initialValue={row.iddeliberationType}
                secondeSess={false}
                delibs={{ props: dMatiere, loading: dMatiereloading }}
                disabled={validationDirection1sess}
              />
            );
          }
        }
      },
      {
        header: () => t(ETLCodes.RCons),
        fieldName: "remediationconseil",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbResultatTypeGridDto) => {
          if (row.idclassematiere > 0) {
            return (
              <TableCheckboxEditor
                initialValue={row.remediationconseil}
                onValueChanged={value => onCheckedRemediationConseillee(value, row.idbulletininscriptionmatiere)}
                readonly={validationDirection1sess}
              />
            );
          }
        }
      },
      {
        header: () => t(ETLCodes.ROb),
        fieldName: "remediationobligatoire",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbResultatTypeGridDto) => {
          if (row.idclassematiere > 0) {
            return (
              <TableCheckboxEditor
                initialValue={row.remediationobligatoire}
                onValueChanged={value => onCheckedRemediationObligatoire(value, row.idbulletininscriptionmatiere)}
                readonly={validationDirection1sess}
              />
            );
          }
        }
      },
      {
        header: () => t(ETLCodes.DeuxiemeSession),
        fieldName: "date2sess",
        customizeCellStyle: () =>
          css`
            width: 150px;
          `,
        render: (row: FcbResultatTypeGridDto) => {
          if (row.idclassematiere > 0) {
            return (
              <TableDateEditor
                idBulletinInscriptionMatiere={row.idbulletininscriptionmatiere}
                initialValue={row.date2sess}
              />
            );
          }
        }
      },
      {
        header: () => t(ETLCodes.TotalMax),
        fieldName: "totalmax"
      },
      {
        computed: true,
        fieldName: "_danger",
        render: (item: FcbResultatTypeGridDto, _) => {
          if (item.evaluations.totalCount === 0) {
            return <Icon icon="warning-sign" color="red" />;
          } else {
            return <div />;
          }
        }
      }
    ],
    [
      dMatiere,
      dMatiereloading,
      onCheckedRemediationConseillee,
      onCheckedRemediationObligatoire,
      t,
      validationDirection1sess
    ]
  );

  const getRowStyles = React.useCallback((item: FcbResultatTypeGridDto) => {
    if (!item.totalmax) return null;
    const total = item.totalmax.split("/")[0];
    const max = item.totalmax.split("/")[1];
    const reussite = item.reussite ?? 50;

    const presenceJ =
      item.evaluations.results.length > 0 &&
      item.evaluations.results.every(e => e.typeEvaluation === "Examen" && e.presence === "J" && !e.cote);

    let newColor = reussite > (+total / +max) * 100 ? Colors.RED3 + " !important" : null;
    if (presenceJ) {
      newColor = Colors.ORANGE4 + " !important";
    }

    return css`
      & * {
        color: ${newColor};
      }
    `;
  }, []);

  return (
    <>
      {idbulletinApprenantType > 0 && (
        <>
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            customizeRowStyle={getRowStyles}
            keyFieldName="idbulletininscriptionmatiere"
            onOpenSubComponent={(item: FcbResultatTypeGridDto) => (
              <DeliberationBulletinEvaluationGrid data={item.evaluations}></DeliberationBulletinEvaluationGrid>
            )}
          />
          <Divider />
          <DeliberationBulletinCaterorySumGrid deliberationResults={tableState.data} loading={loading} />
        </>
      )}
    </>
  );
};
