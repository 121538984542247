import { Colors, Divider } from "@blueprintjs/core";
import { DataTable, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { css } from "styled-components";
import { DeliberationBulletinCaterorySumS2Grid, IMatiereCatCote } from ".";

import {
  BulletinApprenantTypeApi,
  BulletinDetailSearch,
  ETypeDeliberation,
  FcbResultatTypeBulletinSession2GridDto
} from "../../../../../api";
import { TableSelectEditor } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IDeliberationBulletinDeliberation2SessGridProps {
  idbulletinApprenantType: number;
  execSearch: boolean;
  validationDirection2sess: boolean;
  matiereCatCotes: IMatiereCatCote[];
}

export const DeliberationBulletinDeliberation2SessGrid: React.FunctionComponent<IDeliberationBulletinDeliberation2SessGridProps> = ({
  idbulletinApprenantType,
  execSearch,
  validationDirection2sess,
  matiereCatCotes
}) => {
  const { t } = useTl();

  const api = useApiService(BulletinApprenantTypeApi);

  const [dMatiere, dMatiereloading] = useReferential(
    a => a.referentialGetDeliberationTypes({ typeDeliberation: ETypeDeliberation.Matiere }),
    true
  );

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999,
    sortKeys: { categorie: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: BulletinDetailSearch) => {
      sObj.idbulletinApprenantType = idbulletinApprenantType;
      return api.bulletinApprenantTypeBulletinDeliberationSession2Search({ BulletinDetailSearch: sObj });
    },
    [api, idbulletinApprenantType]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  React.useEffect(() => {
    if (execSearch) {
      search();
    }
  }, [execSearch, search]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Code),
        fieldName: "codematiere"
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "matiere"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.Examen),
        fieldName: "exa"
      },
      {
        header: () => t(ETLCodes.Total),
        fieldName: "total"
      },
      {
        header: () => t(ETLCodes.Deliberation),
        fieldName: "idDeliberationType",
        render: (row: FcbResultatTypeBulletinSession2GridDto) => (
          <TableSelectEditor
            idBulletinInscriptionMatiere={row.idbulletinInscriptionMatiere}
            initialValue={row.idDeliberationType}
            secondeSess={true}
            delibs={{ props: dMatiere, loading: dMatiereloading }}
            disabled={validationDirection2sess}
          />
        )
      }
    ],
    [dMatiere, dMatiereloading, t, validationDirection2sess]
  );

  const getRowStyles = React.useCallback((item: FcbResultatTypeBulletinSession2GridDto) => {
    if (!item.total) return null;
    const total = item.total.split("/")[0];
    const max = item.total.split("/")[1];
    const reussite = item.reussite ?? 50;

    return css`
      & * {
        color: ${reussite > (+total / +max) * 100 ? Colors.RED3 + " !important" : null};
      }
    `;
  }, []);

  return (
    <>
      {idbulletinApprenantType > 0 && (
        <>
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            customizeRowStyle={getRowStyles}
            keyFieldName="idbulletininscriptionmatiere"
          />
          <Divider />
          <DeliberationBulletinCaterorySumS2Grid
            deliberationResults={tableState.data}
            loading={loading}
            matiereCatCotes={matiereCatCotes}
          />
        </>
      )}
    </>
  );
};
