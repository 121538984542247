import { Button, Card } from "@blueprintjs/core";
import {
  ButtonContainer,
  ButtonsBloc,
  FGCustomPanel,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IDataTableColumn,
  InlineButtonContainer,
  showError
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { css } from "styled-components";
import * as Yup from "yup";

import {
  AgrementLocalisationApi,
  AgrementLocalisationClasseApi,
  FcbPlanAgrementDtoFromJSON,
  FcbRecherchePlanAgrementClasseDto,
  PlanAgrementClasseSearch
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  CancelButton,
  EditButton,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SaveButton,
  SearchTablePage,
  SmallFormGenerator,
  TableCheckboxEditor,
  TableGlobalSelectEditor,
  TableGlobalTextEditor
} from "../../../../components";
import { useAuth } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface IAgrementClasseDetailPageProps {}

export const AgrementClasseDetailPage: React.FunctionComponent<IAgrementClasseDetailPageProps> = () => {
  const { t } = useTl();
  const { id, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();
  const api = useApiService(AgrementLocalisationApi);
  const paClasseApi = useApiService(AgrementLocalisationClasseApi);
  const { user } = useAuth();
  const canLock = React.useMemo(() => user?.idlevel === 4 || user?.idlevel === 21 || user?.idlevel === 2, [user]);

  const { data, loading, saveItem, saving, refresh } = useCrudApi({
    getApiFn: () => (+id <= 0 ? FcbPlanAgrementDtoFromJSON({ idPlan: 0 }) : api.agrementLocalisationGet({ id: +id })),
    saveApiFn: d => api.agrementLocalisationSave({ FcbPlanAgrementDto: d }),
    deleteApiFn: () => {},
    onSavedRoute: d => `${ERoutes.agrementClasse}/${d.idPlanAgrement}/detail/edit`
  });

  const readonly = React.useMemo(() => state === "view" || data?.idStatut === 3, [data?.idStatut, state]);

  const [annee, aLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, [], true);
  const [centre, cLoading] = useReferential(a => a.referentialGetCentre(), true, [], true);
  const [statut, sLoading] = useReferential(
    a => a.referentialGetStatutLocalisationAgrement({ isLocalisation: false }),
    true,
    [],
    true
  );
  const [conseillerPeda, cpLoading] = useReferential(a => a.referentialGetConseillersPedagogiquesCodes(), false, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idcentre: Yup.number().required(t(ETLCodes.Required)),
      anneeAcademique: Yup.string().required(t(ETLCodes.Required)),
      dateValidation: Yup.date()
    });
  }, [t]);

  const saveRow = React.useCallback(
    async (row: FcbRecherchePlanAgrementClasseDto) => {
      await paClasseApi.agrementLocalisationClasseSave({ FcbRecherchePlanAgrementClasseDto: row });
    },
    [paClasseApi]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRecherchePlanAgrementClasseDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.classe}/${row.idClasse}/agrement/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => "",
        fieldName: "idStatutAgrement",
        alignment: "center",
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "30px",
            verticalAlign: "middle !important"
          }),
        render: (row: FcbRecherchePlanAgrementClasseDto) => (
          <TableCheckboxEditor
            initialValue={row.idStatutAgrement}
            onValueChanged={value => saveRow({ ...row, idStatutAgrement: value })}
            readonly={readonly}
          />
        )
      },
      {
        header: () => t(ETLCodes.Conseiller),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "100px"
          }),
        fieldName: "codeConseillerAgrement",
        render: (row: FcbRecherchePlanAgrementClasseDto) => (
          <TableGlobalSelectEditor
            initialValue={row.codeConseillerAgrement}
            onValueChanged={value => saveRow({ ...row, codeConseillerAgrement: value?.toString() })}
            items={conseillerPeda}
            loading={cpLoading}
            readonly={readonly}
          ></TableGlobalSelectEditor>
        )
      },
      {
        header: () => t(ETLCodes.Classe),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "350px"
          }),
        fieldName: "nom",
        render: (row: FcbRecherchePlanAgrementClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.nom}
            onValueChanged={value => saveRow({ ...row, nom: value })}
            textEditorHeight={20}
            maxLength={50}
            readonly={readonly}
          ></TableGlobalTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "codeMetier"
      },
      {
        header: () => t(ETLCodes.Degre),
        fieldName: "idDegre"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeCours"
      },
      {
        header: () => t(ETLCodes.Spec),
        fieldName: "specificite"
      },
      {
        header: () => t(ETLCodes.Genre),
        fieldName: "genre"
      },
      {
        header: () => t(ETLCodes.Referentiel),
        fieldName: "codeReferentiel"
      },
      {
        header: () => t(ETLCodes.Financement),
        fieldName: "financement",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      },
      {
        header: () => t(ETLCodes.PPB),
        fieldName: "ppb"
      },
      {
        header: () => t(ETLCodes.Nb),
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        fieldName: "nbApprenantAgrement",
        render: (row: FcbRecherchePlanAgrementClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.nbApprenantAgrement?.toString()}
            onValueChanged={value => saveRow({ ...row, nbApprenantAgrement: +value })}
            textEditorHeight={20}
            numeric={true}
            readonly={readonly}
            maxWidth="30px"
          ></TableGlobalTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.PremierCours),
        fieldName: "dateCours"
      },
      {
        header: () => t(ETLCodes.RemarqueCentre),
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        fieldName: "remarqueCentreAgrement",
        render: (row: FcbRecherchePlanAgrementClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.remarqueCentreAgrement}
            onValueChanged={value => saveRow({ ...row, remarqueCentreAgrement: value })}
            textEditorHeight={20}
            readonly={readonly}
          ></TableGlobalTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.GestionInterne),
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        fieldName: "gestionInterneAgrement",
        render: (row: FcbRecherchePlanAgrementClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.gestionInterneAgrement}
            onValueChanged={value => saveRow({ ...row, gestionInterneAgrement: value })}
            textEditorHeight={20}
            readonly={readonly}
          ></TableGlobalTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.RemarqueConseiller),
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        fieldName: "remarqueConseillerAgrement",
        render: (row: FcbRecherchePlanAgrementClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.remarqueConseillerAgrement}
            onValueChanged={value => saveRow({ ...row, remarqueConseillerAgrement: value })}
            textEditorHeight={20}
            readonly={readonly}
          ></TableGlobalTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.RemarqueConseiller),
        fieldName: "dateLocalisation"
      },
      {
        header: () => t(ETLCodes.Coherent),
        fieldName: "coherent"
      }
    ],
    [conseillerPeda, cpLoading, history, readonly, saveRow, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.classe}/0/detail/${state}`);
  }, [history, state]);

  const [editMode, setEditMode] = React.useState<boolean>(state === "edit");

  const getCriterias = React.useCallback(
    () => paClasseApi.agrementLocalisationClasseGetSearchCriterias({ includeListsValues: true }),
    [paClasseApi]
  );

  const apiFactory = useAbortableApiServiceFactory(AgrementLocalisationClasseApi);
  const lastAbortController = React.useRef<AbortController>();

  const search = React.useCallback(
    (sObj?: PlanAgrementClasseSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.agrementLocalisationClasseBaseSearch({
        PlanAgrementClasseSearch: { idPlanAgrement: +id, ...sObj }
      });
    },
    [apiFactory, id]
  );

  const [TVLoading, setTVLoading] = React.useState(false);
  const [VLoading, setVLoading] = React.useState(false);
  const [ULoading, setULoading] = React.useState(false);
  const [LLoading, setLLoading] = React.useState(false);

  const ButtonsMemo = React.useMemo(() => {
    if (data?.idStatut === 1) {
      return (
        <Button
          text={t(ETLCodes.ReadyToValidate)}
          loading={loading || TVLoading}
          onClick={async () => {
            try {
              setTVLoading(true);
              await api.agrementLocalisationToValidatePlan({ idPlan: +id });
            } catch (Error) {
              showError(t(ETLCodes.Error));
            }
            setTVLoading(false);
            refresh();
          }}
        />
      );
    } else if (data?.idStatut === 2 && canLock) {
      return (
        <Button
          text={t(ETLCodes.Validate)}
          loading={loading || VLoading}
          onClick={async () => {
            try {
              setVLoading(true);
              await api.agrementLocalisationValidatePlan({ idPlan: +id });
            } catch (Error) {
              showError(t(ETLCodes.Error));
            }
            setVLoading(false);
            refresh();
          }}
          disabled={!data?.dateValidation}
        />
      );
    } else if (data?.idStatut === 3 && canLock && data?.bloque) {
      return (
        <Button
          text={t(ETLCodes.Unlock)}
          loading={loading || ULoading}
          onClick={async () => {
            try {
              setULoading(true);
              await api.agrementLocalisationUnlockClasse({ idPlan: +id });
            } catch (Error) {
              showError(t(ETLCodes.Error));
            }
            setULoading(false);
            refresh();
          }}
        />
      );
    } else if (data?.idStatut === 3 && canLock && !data?.bloque) {
      return (
        <Button
          text={t(ETLCodes.Lock)}
          loading={loading || LLoading}
          onClick={async () => {
            try {
              setLLoading(true);
              await api.agrementLocalisationLockClasse({ idPlan: +id });
            } catch (Error) {
              showError(t(ETLCodes.Error));
            }
            setLLoading(false);
            refresh();
          }}
        />
      );
    } else {
      return <></>;
    }
  }, [LLoading, TVLoading, ULoading, VLoading, api, canLock, data, id, loading, refresh, t]);

  const isValidated = React.useMemo(() => +data?.idStatut === +statut?.find(s => s.label === "Validé")?.value, [
    data,
    statut
  ]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <Card>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          saving={saving}
          loading={loading}
          editMode={editMode}
          onCancel={() => history.push(`${ERoutes.agrementClasse}`)}
          validationSchema={FormSchema}
          hideButtons={true}
        >
          <FieldGroup columns={3}>
            <FGWalterSelectInput
              label={t(ETLCodes.AnneeScolaire)}
              items={annee}
              loading={aLoading}
              name="anneeAcademique"
              readonly={+id > 0}
            />
            <FGWalterSelectInput
              label={t(ETLCodes.Centre)}
              items={centre}
              loading={cLoading}
              name="idCentre"
              requiredMark
              readonly={+id > 0}
            />
            <FGWalterDateMaskInput
              label={t(ETLCodes.DateValidation)}
              name="dateValidation"
              readonly={data?.idStatut !== 2}
            />
          </FieldGroup>
          <FieldGroup columns={[4, 8]}>
            <FGWalterSelectInput
              label={t(ETLCodes.Statut)}
              items={statut}
              loading={sLoading}
              name="idStatut"
              readonly
            />
            <FGTextInput label={t(ETLCodes.URL)} readonly name="url" maxLength={200} />
          </FieldGroup>
          <FieldGroup>
            <FGTextAreaInput label={t(ETLCodes.Remarque)} name="remarque" />
          </FieldGroup>
          <FieldGroup>
            <FGCustomPanel>
              {ctx => (
                <InlineButtonContainer>
                  <ButtonsBloc>
                    <CancelButton minimal={false} onClick={() => history.push(`${ERoutes.agrementClasse}`)} />
                    {ButtonsMemo}
                    {editMode ? (
                      <SaveButton
                        minimal={false}
                        loading={saving}
                        disabled={!ctx.formik.dirty}
                        onClick={() => saveItem(ctx.formik.values)}
                      />
                    ) : (
                      <EditButton minimal={false} onClick={() => setEditMode(true)}></EditButton>
                    )}
                  </ButtonsBloc>
                </InlineButtonContainer>
              )}
            </FGCustomPanel>
          </FieldGroup>
        </SmallFormGenerator>
      </Card>
      <SearchTablePage
        getCriteriasFunction={getCriterias}
        searchFunction={search}
        onAbort={onAbort}
        columns={columns}
        addFunc={isValidated ? null : onAddItem}
        breadCrumbs={[{ text: t(ETLCodes.AgrementClasses), route: ERoutes.agrementClasse }]}
        sortKeys={{ idClasse: "ASC" }}
      />
    </>
  );
};
