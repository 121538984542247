import { Colors } from "@blueprintjs/core";
import { sumBy } from "lodash";
import { DataTable, IDataTableColumn, useGridState } from "nsitools-react";
import * as React from "react";
import { css } from "styled-components";
import { IMatiereCatCote } from ".";

import { FcbResultatTypeBulletinSession2GridDto } from "../../../../../api";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDeliberationBulletinCaterorySumS2GridProps {
  deliberationResults: FcbResultatTypeBulletinSession2GridDto[];
  loading: boolean;
  matiereCatCotes: IMatiereCatCote[];
}

export const DeliberationBulletinCaterorySumS2Grid: React.FunctionComponent<IDeliberationBulletinCaterorySumS2GridProps> = ({
  deliberationResults,
  loading,
  matiereCatCotes
}) => {
  const { t } = useTl();
  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999,
    sortKeys: { categorie: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (deliberationResults) {
      var result = [];
      deliberationResults.reduce((res, value) => {
        if (!res[value.categorie]) {
          const s1 = matiereCatCotes.filter(c => c.categorie === value.categorie && c.matiere !== value.idmatiere);
          const s1SumCote = sumBy(s1, c => c.cote);
          const s1SumTotal = sumBy(s1, c => c.total);
          res[value.categorie] = {
            categorie: value.categorie,
            bulletinTotal: s1SumCote ?? 0,
            totaldenommax: s1SumTotal ?? 0
          };
          result.push(res[value.categorie]);
        }
        res[value.categorie].bulletinTotal += value.bulletinTotal;
        res[value.categorie].totaldenommax += value.totaldenommax;
        return res;
      }, {});

      setData(result);
    }
  }, [deliberationResults, matiereCatCotes, setData]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Categorie),
        fieldName: "categorie"
      },
      {
        header: () => t(ETLCodes.Total),
        fieldName: "bulletinTotal",
        render: (item: FcbResultatTypeBulletinSession2GridDto) => `${item.bulletinTotal}/${item.totaldenommax}`
      },
      {
        header: () => t(ETLCodes.TotalPercent),
        fieldName: "totalpercent",
        render: (item: FcbResultatTypeBulletinSession2GridDto) =>
          `${
            item.totaldenommax > 0
              ? ((item.bulletinTotal * 100) / item.totaldenommax).toFixed(2)
              : item.bulletinTotal.toFixed(2)
          } %`
      }
    ],
    [t]
  );

  const getRowStyles = React.useCallback((item: FcbResultatTypeBulletinSession2GridDto) => {
    const total =
      item.totaldenommax > 0
        ? ((item.bulletinTotal * 100) / item.totaldenommax).toFixed(2)
        : item.bulletinTotal.toFixed(2);
    return css`
      & * {
        color: ${+total < 50 ? Colors.RED3 + " !important" : null};
      }
    `;
  }, []);

  return (
    <DataTable
      dateFormat="dd-MM-yyyy"
      tableState={tableState}
      loading={loading}
      columns={columns}
      customizeRowStyle={getRowStyles}
    />
  );
};
