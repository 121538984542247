import { getIn } from "formik";
import { FGIBANNumberInput, FGListen, FGTextInput, IFGSelectInputProps } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";

import { FGEditableSelectInput } from ".";
import { CompteBancaireDto, CompteBancaireDtoFromJSON, FormateurApi, PersonneApi } from "../../api";
import { useApiService, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";
import * as Yup from "yup";

export interface IFGEditableCompteBancaireSelectInputProps
  extends Omit<IFGSelectInputProps<CompteBancaireDto>, "valueField" | "displayField" | "items" | "loading"> {
  autoSelectIfOne?: boolean;
  idpersonne: number;
}

export const FGEditableCompteBancaireSelectInput: React.FunctionComponent<IFGEditableCompteBancaireSelectInputProps> = ({
  idpersonne,
  ...props
}) => {
  const { t } = useTl();
  const api = useApiService(PersonneApi);

  const fetchCompteBancaires = React.useCallback(async () => await api.personneGetCompteBancaires({ idpersonne }), [
    api,
    idpersonne
  ]);
  const { data: items, isFetching, refetch } = useQuery(
    ["comptesbancairesdto-personne", idpersonne],
    fetchCompteBancaires
  );

  const formateurApi = useApiService(FormateurApi);
  const fetchBic = React.useCallback(
    async bankAccount => {
      return formateurApi.formateurGetBicFromAccount({ account: bankAccount });
    },
    [formateurApi]
  );

  const onSave = React.useCallback(
    async (data: CompteBancaireDto) => {
      return await api.personneSaveCompteBancaire({ CompteBancaireDto: data });
    },
    [api]
  );

  const onDelete = React.useCallback(
    async (data: CompteBancaireDto) => {
      await api.personneDeleteCompteBancaireFromPersonne({ idcompteBancaire: data.idcompteBancaire ?? 0 });
    },
    [api]
  );

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      iban: Yup.string()
        .nullable()
        .required(t(ETLCodes.Required)),
      bic: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <FGEditableSelectInput
      displayField={nameof<CompteBancaireDto>("iban")}
      valueField={nameof<CompteBancaireDto>("idcompteBancaire")}
      baseData={CompteBancaireDtoFromJSON({ idpersonne })}
      validationSchema={validationSchema}
      refresh={async () => {
        await refetch();
      }}
      editFields={
        <>
          <FGListen
            field={nameof<CompteBancaireDto>("iban")}
            onChanged={async (value, formik) => {
              if (!!value) {
                if (value?.startsWith("BE") && value?.length > 8) {
                  const bic = await fetchBic(value);
                  if (!!bic && bic !== getIn(formik?.values, nameof<CompteBancaireDto>("bic"))) {
                    formik?.setFieldValue(nameof<CompteBancaireDto>("bic"), bic);
                  }
                }
              } else if (!!getIn(formik?.values, nameof<CompteBancaireDto>("bic"))) {
                formik?.setFieldValue(nameof<CompteBancaireDto>("bic"), null);
              }
            }}
          />
          <FGIBANNumberInput name={nameof<CompteBancaireDto>("iban")} placeholder={t(ETLCodes.NumeroBanque)} />
          <FGTextInput name={nameof<CompteBancaireDto>("bic")} placeholder={t(ETLCodes.CodeBIC)} maxLength={15} />
        </>
      }
      onSave={onSave}
      onDelete={onDelete}
      items={items}
      loading={isFetching}
      {...props}
    />
  );
};

export default FGEditableCompteBancaireSelectInput;
